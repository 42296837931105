import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { OpenSourceCateKey } from '../../enum-types'
import { OpenSourceLib } from '../../model'

/**
 * 오픈 소스 API
 */
export class OpenSourceApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 오픈 소스 목록 조회
   */
  list = (
    params: { cate: OpenSourceCateKey } & RequestDataBase,
  ): Promise<{ openSourceList: OpenSourceLib[] }> => {
    const url = '/admin/api/open-source/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 오픈 소스 정보 조회
   */
  info = (params: { libId: number } & RequestDataBase): Promise<{ openSource: OpenSourceLib }> => {
    const { libId, ...rest } = params
    const url = `/admin/api/open-source/${libId}`
    return this.withData.postJson(url, ...splitParams(rest))
  }

  /**
   * 오픈 소스 업데이트
   */
  update = async (
    params: {
      libId: number
      cate: OpenSourceCateKey
      title: string
      ver?: string
      linkUrl: string
      license: string
      copyright?: string
    } & RequestDataBase,
  ): Promise<void> => {
    const url = '/admin/api/open-source/save-update'
    await this.helper.postJson(url, ...splitParams(params))
  }

  /**
   * 오픈 소스 신규 등록
   */
  insert = async (
    params: {
      cate: OpenSourceCateKey
      title: string
      ver?: string
      linkUrl: string
      license: string
      copyright?: string
    } & RequestDataBase,
  ): Promise<void> => {
    const url = '/admin/api/open-source/save-new'
    await this.helper.postJson(url, ...splitParams(params))
  }

  /**
   * 오픈 소스 삭제
   */
  delete = async (
    params: {
      libId: number
    } & RequestDataBase,
  ): Promise<void> => {
    const { libId, ...rest } = params
    const url = `/admin/api/open-source/delete/${libId}`
    await this.helper.postJson(url, ...splitParams(rest))
  }

  /**
   * 오픈 소스 라이브러리 버전 일괄 업데이트
   */
  updateVersions = async (
    params: {
      cate: OpenSourceCateKey
      libs: Array<{ libName: string; ver: string }>
    } & RequestDataBase,
  ): Promise<void> => {
    const url = '/admin/api/open-source/update-version-batch'
    await this.helper.postJson(url, ...splitParams(params))
  }
}
