import axios from 'axios'
import { errorToMessage } from './error-messages'

export const createAppError = (error: any) => {
  if (error.isAxiosError && error.message === 'Network Error') {
    console.log('axiosError', error.toJSON())
    return new AppError('E1_NETWORK_ERROR')
  }

  if (error instanceof AppError || error instanceof ApiCanceledError) {
    return error
  }

  if (axios.isCancel(error)) {
    return new ApiCanceledError()
  }

  if (error?.response?.status > 0) {
    return new AppError(`E1_HTTP_${error.response.status}`)
  }

  return new AppError('E1_UNKNOWN', error.message || 'unknwon error')
}

export class AppError extends Error {
  name: string
  errorCode: string
  constructor(errorCode: string, message?: string) {
    super(message)
    this.errorCode = errorCode
    this.name = 'AppError'
  }

  toString = (): string => {
    if (this.message) {
      return `${this.errorCode}: ${this.message}`
    } else {
      const msg = errorToMessage(this.errorCode)
      return msg || this.errorCode
    }
  }
}

export class ApiCanceledError extends AppError {
  constructor(message?: string) {
    super('E1_CANCELED', message)
  }
}
