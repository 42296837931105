import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { UserDataset, UserDatasetVo } from '../../model'

/**
 * 사용자 데이터셋 Api
 */
export class UserDatasetApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * UserDatasetVo를 UserDataset으로 변경
   * @param dataset
   * @returns
   */
  private convertToDataset_ = (dataset: UserDatasetVo): UserDataset => {
    const keyValueList: [string, string][] = JSON.parse(dataset.substance)
    const result: Record<string, string> = {}
    for (const keyValue of keyValueList) {
      const key = keyValue[0]
      const value = keyValue[1]
      result[key] = value
    }
    return { ...dataset, substance: result } as UserDataset
  }

  /**
   * 사용자 데이터셋 한건 조회
   */
  info = async (
    params: {
      datasetId: string
    } & RequestDataBase,
  ): Promise<{ dataset: UserDataset }> => {
    const { datasetId, ...rest } = params
    const url = `/p/api/user-dataset/info/${datasetId}`
    const response: { dataset: UserDatasetVo } = await this.withData.postJsonAndSecuredResponse(
      url,
      ...splitParams(rest),
    )

    return {
      dataset: this.convertToDataset_(response.dataset),
    }
  }

  /**
   * 사용자 데이터셋 목록 조회
   */
  list = async (params: RequestDataBase): Promise<{ datasetList: UserDataset[] }> => {
    const url = '/api/user-dataset/list'

    const response: { datasetList: UserDatasetVo[] } =
      await this.withData.postJsonAndSecuredResponse(url, ...splitParams(params))

    return {
      datasetList: response.datasetList.map(this.convertToDataset_),
    }
  }

  /**
   * 사용자 데이터셋 생성
   */
  create = (
    params: {
      datasetName: string
      substance: Array<[string, string]>
    } & RequestDataBase,
  ): Promise<{ datasetId: string; datasetName: string }> => {
    const url = '/api/user-dataset/create'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 사용자 데이터셋 업데이트
   */
  update = (
    params: {
      datasetId: string
      datasetName: string
      substance: Record<string, string>
    } & RequestDataBase,
  ): Promise<{ datasetId: string; datasetName: string }> => {
    const { datasetName, substance, datasetId, ctx } = params
    const url = `/api/user-dataset/update/${datasetId}`

    return this.withData.postJson(
      url,
      { datasetName, substance: Object.entries(substance) },
      { ctx },
    )
  }

  /**
   * 사용자 데이터셋 삭제
   */
  delete = async (
    params: {
      datasetId: string
    } & RequestDataBase,
  ): Promise<void> => {
    const { datasetId, ...rest } = params
    const url = `/api/user-dataset/delete/${datasetId}`

    await this.helper.post(url, ...splitParams(rest))
  }
}
