import {
  ApiHelper,
  ApiHelperWithData,
  PagingRequest,
  RequestDataBase,
  splitParams,
} from '../../api'
import {
  KmcisGenderKey,
  KmcisNationKey,
  KmcisPageIdKey,
  KmcisPhoneCorpKey,
  KmcisStateKey,
} from '../../enum-types'
import { KmcisRequest, PagerData } from '../../model'

/**
 * 관리자용 본인인증 요청 Api
 */
export class KmcisApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 본인인증 목록 조회
   */
  search = (
    params: {
      state?: KmcisStateKey
      phoneCorp?: KmcisPhoneCorpKey
      birthYear?: number
      gender?: KmcisGenderKey
      nation?: KmcisNationKey
      pageId?: KmcisPageIdKey
    } & PagingRequest,
  ): Promise<{ pagerData: PagerData<KmcisRequest> }> => {
    const url = '/admin/api/kmcis/search'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 본인인증 요청 정보 조회
   */
  info = (
    params: {
      reqId: string
    } & RequestDataBase,
  ): Promise<{
    kmcisRequestx: KmcisRequest
  }> => {
    const { reqId, ...rest } = params
    const url = `/admin/api/kmcis/info/${reqId}`
    return this.withData.post(url, ...splitParams(rest))
  }
}
