import { ApiHelper, ApiHelperWithData, RequestDataBase, WasUrlHelper, splitParams } from '../../api'
import { QuickDrawWordSearchSortFieldKey } from '../../enum-types'
import { QuickDrawModel, QuickDrawRank, QuickDrawWord, QuickDrawWordCount } from '../../model'

/**
 * 어드민 퀵드로우 Api
 */
export class QuickDrawApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  modelList = (params: RequestDataBase): Promise<{ modelList: QuickDrawModel[] }> => {
    const url = '/admin/api/quick-draw/model/list'
    return this.withData.post(url, ...splitParams(params))
  }

  wordCountList = (
    params: {
      word?: string
      sortField?: QuickDrawWordSearchSortFieldKey
      maxCount: number
    } & RequestDataBase,
  ): Promise<{ wordCountList: QuickDrawWordCount[] }> => {
    const url = '/admin/api/quick-draw/word/count-list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  wordList = (
    params: {
      modelId: string
      includeZeroCount: boolean
    } & RequestDataBase,
  ): Promise<{ wordList: QuickDrawWord[] }> => {
    const url = '/admin/api/quick-draw/word/list'
    return this.withData.post(url, ...splitParams(params))
  }

  imageListByModelIdAndWord = async (
    params: {
      modelId: string
      word: string
      maxCount: number
    } & RequestDataBase,
  ): Promise<{ imageUrlList: string[] }> => {
    const url = '/admin/api/quick-draw/model-word-image-list'

    const response: { imageUrlList: string[] } = await this.withData.post(
      url,
      ...splitParams(params),
    )

    response.imageUrlList = response.imageUrlList.map(WasUrlHelper.toRelative)
    return response
  }

  imageListByWord = async (
    params: {
      word: string
      maxCount: number
    } & RequestDataBase,
  ): Promise<{ imageUrlList: string[] }> => {
    const url = '/admin/api/quick-draw/word-image-list'
    const response: { imageUrlList: string[] } = await this.withData.post(
      url,
      ...splitParams(params),
    )
    response.imageUrlList = response.imageUrlList.map(WasUrlHelper.toRelative)
    return response
  }

  deleteImageFile = async (
    params: {
      fileId: string
    } & RequestDataBase,
  ): Promise<void> => {
    const url = '/admin/api/quick-draw/delete-word-image-file'
    await this.helper.post(url, ...splitParams(params))
  }

  deleteImageFileByWord = async (
    params: {
      word: string
    } & RequestDataBase,
  ): Promise<void> => {
    const url = '/admin/api/quick-draw/delete-word-image-file-by-word'
    await this.helper.post(url, ...splitParams(params))
  }

  rankList = (
    params: {
      maxCount: number
    } & RequestDataBase,
  ): Promise<{ rankList: QuickDrawRank[]; totalUserCount: number }> => {
    const url = '/admin/api/quick-draw/rank-list'
    return this.withData.post(url, ...splitParams(params))
  }

  deleteRankByUserId = async (
    params: {
      userId: string
    } & RequestDataBase,
  ): Promise<void> => {
    const url = '/admin/api/quick-draw/rank-delete'
    await this.helper.post(url, ...splitParams(params))
  }

  deleteRankAll = async (params?: RequestDataBase): Promise<void> => {
    const url = '/admin/api/quick-draw/rank-delete-all'
    await this.helper.post(url, ...splitParams(params ?? {}))
  }
}
