import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { PublishedSimulFile } from '../../model'

/**
 * PubSimul Api
 */
export class SimulPubApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  publish = async (
    params: {
      simulId: number
    } & RequestDataBase,
  ): Promise<void> => {
    const { simulId, ...rest } = params
    const url = `/admin/api/simul-pub/publish/${simulId}`

    await this.helper.post(url, ...splitParams(rest))
  }

  unpublish = async (
    params: {
      simulId: number
    } & RequestDataBase,
  ): Promise<void> => {
    const { simulId, ...rest } = params
    const url = `/admin/api/simul-pub/unpublish/${simulId}`

    await this.helper.post(url, ...splitParams(rest))
  }

  files = (
    params: {
      simulIds: number[]
    } & RequestDataBase,
  ): Promise<{ publishedSimulFileList: PublishedSimulFile[] }> => {
    const url = '/admin/api/simul-pub/files'

    return this.withData.postJson(url, ...splitParams(params))
  }

  file = (
    params: {
      simulId: number
    } & RequestDataBase,
  ): Promise<{ publishedSimulFile: PublishedSimulFile }> => {
    const { simulId, ...rest } = params
    const url = `/admin/api/simul-pub/file/${simulId}`

    return this.withData.post(url, ...splitParams(rest))
  }
}
