export type WeatherKindKey = 'TEMP' | 'RAIN_PROB' | 'HUMI' | 'SKY' | 'DAYTIME_TEMP_MAX'
export type WeatherCode = 'T3H' | 'POP' | 'REH' | 'SKY' | 'TMX'

export const WeatherKind: Record<WeatherKindKey, { title: string; code: WeatherCode }> = {
  TEMP: { title: '기온', code: 'T3H' },
  RAIN_PROB: { title: '강수확률', code: 'POP' },
  HUMI: { title: '습도', code: 'REH' },
  SKY: { title: '하늘', code: 'SKY' },
  DAYTIME_TEMP_MAX: { title: '낮최고기온', code: 'TMX' },
}
