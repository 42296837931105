import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'

/**
 * UserWithdraw Api
 * 사용자용
 */
export class UserWithdrawApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 회원탈퇴 등록
   * 사용자가 본인의 회원탈퇴를 등록한다.
   */
  withdraw = async (
    params: {
      txId: string
    } & RequestDataBase,
  ): Promise<void> => {
    const { ctx, txId } = params
    const url = '/api/user-withdraw'
    await this.helper.post(url, { txId }, { ctx })
  }
}
