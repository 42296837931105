import LoginProfileLoader from '@/auth/LoginProfileLoader'
import { LightboxImageViewEventHandler } from '@/custom-events'
import IntlMessageLoader from '@/IntlMessageLoader'
import { ApiProvider, I18nMsgEnsureHoc } from '@/provider'
import UserProfileLoader from '@/provider/UserProfileLoader'
import StoreProvider from '@/store/StoreProvider'
import theme from '@/theme'
import { CacheProvider, EmotionCache } from '@emotion/react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import * as timeago from 'timeago.js'
import ko from 'timeago.js/lib/lang/ko'
import createEmotionCache from '../src/createEmotionCache'
import '../src/index.css'
import './ckeditor.css'

timeago.register('ko', ko)

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
}

function MyApp(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props
  const { initialState } = pageProps ?? {}

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <title>Admin</title>
        <meta charSet="utf-8" />
        <meta name="google" content="notranslate" />
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        <meta name="theme-color" content="#1976D2" />
      </Head>

      <ThemeProvider theme={theme}>
        <CssBaseline />
        <StoreProvider rootStoreInitialState={initialState}>
          <ApiProvider>
            <LoginProfileLoader />
            <UserProfileLoader />
            <IntlMessageLoader />
            <I18nMsgEnsureHoc>
              <Component {...pageProps} />
            </I18nMsgEnsureHoc>
          </ApiProvider>
        </StoreProvider>
        <ToastContainer
          position="top-center"
          hideProgressBar
          autoClose={3000}
          pauseOnHover
          draggable
        />
      </ThemeProvider>
      <LightboxImageViewEventHandler />
    </CacheProvider>
  )
}

export default MyApp
