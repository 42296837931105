/**
 * 공통으로 사용할 클래스 이름
 */
export const predefinedClassNames = {
  /**
   * 튜터 관련
   */
  tut: {
    /**
     * 클릭했을 때 튜터 tts를 중지한다
     */
    stopTts: 'tut_stop_tts_on_click',
  },
}
