import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { WebProp } from '../../model'

export class WebPropApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * WebProp 신규 등록
   */
  create = (
    params: {
      propKey: string
      value: string
      lang: string // 'text' | 'json' | 'javascript' | 'xml'
      memo?: string
    } & RequestDataBase,
  ): Promise<{ webProp: WebProp }> => {
    const url = '/admin/api/web-prop/create'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * WebProp 업데이트
   */
  update = (
    params: {
      propId: number
      propKey: string
      value: string
      lang: string // 'text' | 'json' | 'javascript' | 'xml'
      memo: string
    } & RequestDataBase,
  ): Promise<{ webProp: WebProp }> => {
    const url = '/admin/api/web-prop/update'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * WebProp 전체 목록 조회
   */
  listAll = (params: RequestDataBase): Promise<{ webPropList: WebProp[] }> => {
    const url = '/admin/api/web-prop/list-all'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * WebProp 검색
   */
  search = (
    params: {
      propKey?: string
      value?: string
    } & RequestDataBase,
  ): Promise<{ webProp: WebProp }> => {
    const url = '/admin/api/web-prop/search'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * WebProp 단건 조회 by propId
   */
  info = (
    params: {
      propId: number
    } & RequestDataBase,
  ): Promise<{ webProp: WebProp }> => {
    const url = '/admin/api/web-prop/info'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * WebProp 단건 조회 by propKey
   */
  find = (
    params: {
      propKey: string
    } & RequestDataBase,
  ): Promise<{ webProp: WebProp }> => {
    const url = '/admin/api/web-prop/find'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * WebProp 삭제
   */
  delete = async (
    params: {
      propKey: string
    } & RequestDataBase,
  ): Promise<void> => {
    const url = '/admin/api/web-prop/delete'
    await this.helper.post(url, ...splitParams(params))
  }
}
