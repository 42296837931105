import { createTheme } from '@mui/material/styles'
import { palette } from './palette'
import { typography } from './typography'
import { overrides } from './overrides'

const theme = createTheme({
  palette,
  typography,
  components: {
    ...overrides,
  },
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1536,
    },
  },
})

export default theme
