import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'
import { DSKMeansCluster, DSKMeansDataset, DSKMeansModel, DSModel } from '../../model/data-science'

/**
 * Data Science KMeans Model Api
 */
export class DSModelKMeansApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * KMeans 모델 정보 조회
   * 20230418 jjfive 로그인하지 않아도 모델을 로드할 수 있도록 함
   */
  model = (
    params: {
      modelId: string
    } & RequestDataBase,
  ): Promise<{ model: DSKMeansModel }> => {
    const { modelId, ctx } = params
    const url = `/p/api/ds-model/kmeans/model/${modelId}/model.json`

    return this.withData.postJson(url, undefined, { ctx })
  }

  /**
   * KMeans 모델 목록 조회
   */
  modelList = (params: RequestDataBase): Promise<{ models: DSKMeansModel[] }> => {
    const { ctx } = params
    const url = '/api/ds-model/kmeans/models'
    return this.withData.postJson(url, undefined, { ctx })
  }

  saveModelData = (
    params: {
      modelId: string
      modelName: string
      k: number
      dataset: DSKMeansDataset
      clusters: DSKMeansCluster[]
    } & RequestDataBase,
  ): Promise<{ model: DSModel }> => {
    const url = '/api/ds-model/kmeans/save-data'
    const { modelId, modelName, k, dataset, clusters, ctx } = params

    return this.withData.postJsonAndSecuredResponse(
      url,
      { modelId, modelName, k, dataset, clusters },
      { ctx },
    )
  }
}
