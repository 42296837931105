import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { PagerData, UploadedGenFile, UserProject } from '../../model'

/**
 * User Project Api
 */
export class UserProjectApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }
  info = (
    params: {
      projectId: number
    } & RequestDataBase,
  ): Promise<{ userProject: UserProject }> => {
    const url = `/api/user-project/info/${params.projectId}`
    const { ctx } = params

    return this.withData.postJsonAndSecuredResponse(url, undefined, { ctx })
  }

  list = (
    params: {
      rowsPerPage: number
      pageNumber: number
    } & RequestDataBase,
  ): Promise<{ pagerData: PagerData<UserProject> }> => {
    const url = '/api/user-project/list'
    const { rowsPerPage, pageNumber, ctx } = params

    return this.withData.postJsonAndSecuredResponse(url, { rowsPerPage, pageNumber }, { ctx })
  }

  create = (
    params: {
      projectName: string
      genFileId: string
      thumbnailFileId: string
    } & RequestDataBase,
  ): Promise<{ userProject: UserProject }> => {
    const url = '/api/user-project/create'
    return this.withData.postJsonAndSecuredResponse(url, ...splitParams(params))
  }

  update = (
    params: {
      projectId: number
      projectName: string
      genFileId: string
      thumbnailFileId: string
    } & RequestDataBase,
  ): Promise<{ userProject: UserProject }> => {
    const { projectName, projectId, genFileId, thumbnailFileId, ctx } = params
    const url = `/api/user-project/update/${projectId}`

    return this.withData.postJsonAndSecuredResponse(
      url,
      { projectName, genFileId, thumbnailFileId },
      { ctx },
    )
  }

  /**
   * User Project 삭제
   */
  delete = async (
    params: {
      projectId: number
    } & RequestDataBase,
  ): Promise<void> => {
    const { projectId, ctx } = params
    const url = `/api/user-project/delete/${projectId}`

    await this.helper.post(url, undefined, { ctx })
  }

  /**
   * Gen 파일 업로드
   */
  uploadGenFile = (
    params: {
      file: Blob
    } & RequestDataBase,
  ): Promise<{ file: UploadedGenFile }> => {
    const { file, ctx } = params
    const url = '/api/user-project/gen-upload'

    const formData = new FormData()
    formData.append('file', file, 'file.gen')
    return this.withData.postMultipart(url, formData, { ctx })
  }

  createAutoRun = (
    params: {
      projectId: number
    } & RequestDataBase,
  ): Promise<{ url: string }> => {
    const { projectId, ctx } = params
    const url = `/api/user-project/create-auto-run/${projectId}`

    return this.withData.postJson(url, { projectId }, { ctx })
  }

  authenticateAutoRun = (
    params: {
      runId: string
    } & RequestDataBase,
  ): Promise<{ userProject: UserProject }> => {
    const { runId, ctx, header } = params
    const url = `/p/api/auth-auto-run/${runId}`

    return this.withData.getAndSecuredResponse(url, undefined, { ctx, header })
  }
}
