import { ApiHelper, ApiHelperWithData, RequestDataBase, WasUrlHelper, splitParams } from '../../api'
import { AllianceInq, PagerData } from '../../model'

/**
 * 제휴 문의 API
 */
export class AllianceInqApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 문의 목록 조회 - 페이징
   */
  list = async (
    params: {
      rowsPerPage: number
      pageNumber: number
    } & RequestDataBase,
  ): Promise<{
    pagerData: PagerData<AllianceInq>
  }> => {
    const url = '/admin/api/alliance-inq/list'
    const response: {
      pagerData: PagerData<AllianceInq>
    } = await this.withData.post(url, ...splitParams(params))

    // fix was url
    response.pagerData.elements.forEach((item) => {
      item.attachFiles?.forEach((attchFile) => {
        WasUrlHelper.replaceRelative(attchFile, 'downloadUrl')
      })
    })
    return response
  }

  /**
   * 문의 조회
   */
  info = async (
    params: {
      inqId: number
    } & RequestDataBase,
  ): Promise<{
    allianceInq: AllianceInq
  }> => {
    const { inqId, ...rest } = params
    const url = `/admin/api/alliance-inq/info/${inqId}`
    const response: { allianceInq: AllianceInq } = await this.withData.post(
      url,
      ...splitParams(rest),
    )

    // fix was
    response.allianceInq.attachFiles?.forEach((attchFile) => {
      WasUrlHelper.replaceRelative(attchFile, 'downloadUrl')
    })
    return response
  }

  /**
   * 언마스킹된 문의 조회
   */
  infoUnmasked = async (
    params: {
      inqId: number
      unmaskReason: string
    } & RequestDataBase,
  ): Promise<{
    allianceInq: AllianceInq
  }> => {
    const { inqId, ...rest } = params
    const url = `/admin/api/alliance-inq/info-unmasked/${inqId}`

    const response: {
      allianceInq: AllianceInq
    } = await this.withData.post(url, ...splitParams(rest))

    return response
  }
}
