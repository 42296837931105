import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'

/**
 * 브라우저 로그 API
 */
export class BrowserLogApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  saveLog = async (params: RequestDataBase): Promise<void> => {
    const url = '/p/api/summary/save-browser-log'
    const { ctx } = params
    await this.helper.post(url, undefined, { ctx })
  }
}
