import { ApiHelper, ApiHelperWithData, RequestDataBase, WasUrlHelper, splitParams } from '../../api'
import { BgCateKey, ObjCateKey } from '../../enum-types'
import { NextPageData, ObjectImageResource, UploadedFile } from '../../model'
import { ImageFormat, ObjectAnimationTypeKey } from '../../object-player'

/**
 * Object Image Api
 */
export class ObjectImageApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 오브젝트 이미지 조회
   */
  listByCate = async (
    params: {
      isBackground: boolean
      cate: ObjCateKey | BgCateKey
      nextKey: string | null
    } & RequestDataBase,
  ): Promise<{ pagerData: NextPageData<ObjectImageResource> }> => {
    const url = '/admin/api/object-image/list-by-cate'
    const response: {
      pagerData: NextPageData<ObjectImageResource>
    } = await this.withData.postJson(url, ...splitParams(params))

    response.pagerData.list.forEach((item) => {
      item.imageUrls = item.imageUrls.map(WasUrlHelper.toRelative)
    })

    return response
  }

  /**
   * 오브젝트 이미지 검색
   */
  search = async (
    params: {
      isBackground: boolean
      objectName: string
      nextKey: string | null
    } & RequestDataBase,
  ): Promise<{ pagerData: NextPageData<ObjectImageResource> }> => {
    const url = '/admin/api/object-image/list-by-objectName'
    const response: {
      pagerData: NextPageData<ObjectImageResource>
    } = await this.withData.postJson(url, ...splitParams(params))

    response.pagerData.list.forEach((item) => {
      item.imageUrls = item.imageUrls.map(WasUrlHelper.toRelative)
    })

    return response
  }

  /**
   * 오브젝트 이미지 생성
   */
  create = (
    params: {
      objectName: string
      isBackground: boolean
      fileIdCsv: string
      animationType: ObjectAnimationTypeKey
      cate: ObjCateKey | BgCateKey
      imageFormat: ImageFormat
      sortNumber: number
    } & RequestDataBase,
  ): Promise<{ imageId: string }> => {
    const url = '/admin/api/object-image/create'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 오브젝트 이미지 업로드
   */
  uploadObjectImage = async (
    params: {
      file: File
      thumbnailFile?: Blob
      sortNumber: number
    } & RequestDataBase,
  ): Promise<{ file: UploadedFile }> => {
    const url = '/admin/api/object-image/image-upload'
    const { ctx, file, thumbnailFile, sortNumber } = params
    const formData = new FormData()
    formData.append('sortNumber', sortNumber.toString())
    formData.append('file', file, file.name)
    if (thumbnailFile) {
      formData.append('thumbnailFile', thumbnailFile, 'thumb.png')
    }
    const response: { file: UploadedFile } = await this.withData.postMultipart(url, formData, {
      ctx,
    })
    WasUrlHelper.replaceRelative(response.file, 'downloadUrl')
    return response
  }

  /**
   * 오브젝트 쎔네일 이미지 업로드
   */
  uploadThumbnail = async (
    params: {
      fileId: string
      thumbnailFile: Blob
    } & RequestDataBase,
  ): Promise<{ file: UploadedFile }> => {
    const url = '/admin/api/object-image/replace-thumbnail'
    const { ctx, fileId, thumbnailFile } = params
    const formData = new FormData()
    formData.append('fileId', fileId)
    formData.append('thumbnailFile', thumbnailFile, 'thumb.png')
    const response: { file: UploadedFile } = await this.withData.postMultipart(url, formData, {
      ctx,
    })

    WasUrlHelper.replaceRelative(response.file, 'downloadUrl')
    return response
  }

  /**
   * 오브젝트 이미지 내용 변경
   */
  replaceImage = async (
    params: {
      fileId: string
      file: File
      thumbnailFile: Blob
    } & RequestDataBase,
  ): Promise<{ imageUrl: string }> => {
    const url = '/admin/api/object-image/replace-image-content'
    const { ctx, file, thumbnailFile, fileId } = params
    const formData = new FormData()
    formData.append('fileId', fileId)
    formData.append('file', file, file.name)
    formData.append('thumbnailFile', thumbnailFile, 'thumb.png')
    const response: { imageUrl: string } = await this.withData.postMultipart(url, formData, { ctx })
    WasUrlHelper.replaceRelative(response, 'imageUrl')
    return response
  }

  /**
   * 오브젝트 이미지 제거
   */
  delete = async (
    params: {
      imageId: string
    } & RequestDataBase,
  ): Promise<void> => {
    const { imageId, ...rest } = params
    const url = `/admin/api/object-image/delete/${imageId}`
    await this.helper.postJson(url, ...splitParams(rest))
  }

  /**
   * 오브젝트 이미지 정렬
   */
  reorder = async (
    params: {
      imageIdCsv: string
    } & RequestDataBase,
  ): Promise<void> => {
    const url = '/admin/api/object-image/reorder'
    await this.helper.post(url, ...splitParams(params))
  }

  /**
   * 오브젝트 이미지 카테고리 변경
   */
  updateObjectImageCate = async (
    params: {
      imageIds: string[]
      cate: ObjCateKey | BgCateKey
    } & RequestDataBase,
  ): Promise<void> => {
    const url = '/admin/api/object-image/update-cate'
    await this.helper.post(url, ...splitParams(params))
  }

  /**
   * 오브젝트 이미지 정보 변경
   */
  updateObjectInfo = async (
    params: {
      imageId: string
      objectName: string
      cate: ObjCateKey | BgCateKey
    } & RequestDataBase,
  ): Promise<{ objectImageResource: ObjectImageResource }> => {
    const url = '/admin/api/object-image/update-image-info'
    const response: { objectImageResource: ObjectImageResource } = await this.withData.postJson(
      url,
      ...splitParams(params),
    )

    response.objectImageResource.imageUrls = response.objectImageResource.imageUrls.map(
      WasUrlHelper.toRelative,
    )

    return response
  }

  /**
   * 오브젝트 정보 조회
   */
  info = async (
    params: {
      imageId: string
    } & RequestDataBase,
  ): Promise<{ objectImageResource: ObjectImageResource }> => {
    const { imageId, ...rest } = params
    const url = `/admin/api/object-image/info/${imageId}`
    const response: { objectImageResource: ObjectImageResource } = await this.withData.post(
      url,
      ...splitParams(rest),
    )

    response.objectImageResource.imageUrls = response.objectImageResource.imageUrls.map(
      WasUrlHelper.toRelative,
    )
    return response
  }
}
