import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import {
  DSKnnTextClassFeature,
  DSKnnTextClassifyModelJson,
  DSModel,
} from '../../model/data-science'

/**
 * Data Science KNN Text Classification Model Api
 */
export class DSModelKnnTextClassifyApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * Knn Text 모델 정보 조회
   * 20230418 jjfive 로그인하지 않아도 모델을 로드할 수 있도록 함
   */
  model = (
    params: {
      modelId: string
    } & RequestDataBase,
  ): Promise<{ model: DSKnnTextClassifyModelJson }> => {
    const { modelId, ...rest } = params
    const url = `/p/api/ds-model/knn-text-classify/model/${modelId}/model.json`
    return this.withData.postJson(url, ...splitParams(rest))
  }

  saveModelData = (
    params: {
      modelId: string
      modelName: string
      classFeatures: DSKnnTextClassFeature[]
    } & RequestDataBase,
  ): Promise<{ model: DSModel }> => {
    const url = '/api/ds-model/knn-text-classify/save-data'
    return this.withData.postJsonAndSecuredResponse(url, ...splitParams(params))
  }
}
