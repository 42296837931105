export const EventTypes = {
  login: {
    openDialog: 'login.open-dialog',
  },
  profile: {
    openViewDialog: 'profile.open-view-dialog',
  },

  maker: {
    // 사용자에 의해 오브젝트가 변경됨 이벤트
    objectChangedFromUser: 'maker.object-changed-from-user',

    kmeansModelChanged: 'maker.kmeans-model-changed',
    dataScienceModelChanged: 'maker.data-science-model-changed',
    userDatasetChanged: 'maker.user-data-set-changed',
    userExApiChanged: 'maker.user-ex-api-changed',
  },
  block: {
    exportBlockAsImage: 'block.export-block-as-image',
    exportWorkspaceAsImage: 'block.export-workspace-as-image',
    duplicateBlocksWithChildren: 'block.duplicate-blocks-with-children',
  },
}

export const dispatch = (eventName: string, eventDetail?: any) => {
  const detail = eventDetail ? { detail: eventDetail } : undefined
  window.dispatchEvent(new CustomEvent(eventName, detail))
}
