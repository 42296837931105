import { AxiosResponse } from 'axios'
import { ApiHelper, ErrorResponseInterceptor, ResponseInterceptor } from '../api'
import { AppError } from '../errors'
import log from '../log'
import { AnalSentimentApi } from './ai-user/anal-sentiment-api'
import { CropHintDetectApi } from './ai-user/crop-hint-detect-api'
import { HandwriteDetectApi } from './ai-user/handwrite-detect-api'
import { LandmarkDetectApi } from './ai-user/landmark-detect-api'
import { LogoDetectApi } from './ai-user/logo-detect-api'
import { ObjectDetectApi } from './ai-user/object-detect-api'
import { SafeSearchApi } from './ai-user/safe-search-api'
import { TextDetectApi } from './ai-user/text-detect-api'
import { TranslateApi } from './ai-user/translate-api'
import { TTSApi } from './ai-user/tts-api'

/**
 * WAS와 통신하는 AI API
 * @deprecated AI요청서버와 통신하는 AiApi를 사용하세요.
 */
export class AiUserApi {
  helper: ApiHelper
  analSentiment: AnalSentimentApi
  cropHintDetect: CropHintDetectApi
  landmarkDetect: LandmarkDetectApi
  logoDetect: LogoDetectApi
  objectDetect: ObjectDetectApi
  safeSearch: SafeSearchApi
  translate: TranslateApi
  tts: TTSApi
  handwriteDetect: HandwriteDetectApi
  textDetect: TextDetectApi

  constructor(
    public apiBaseURL: string,
    createApiHeader: () => Record<string, string>,
    responseInterceptor: ResponseInterceptor,
    errorResponseInterceptor: ErrorResponseInterceptor,
    debug = false,
  ) {
    log.debug('create Api for ', apiBaseURL)

    // create helper
    this.helper = new ApiHelper(
      apiBaseURL,
      createApiHeader,
      responseInterceptor,
      errorResponseInterceptor,
      debug,
    )

    // api list
    this.analSentiment = new AnalSentimentApi(this.helper)
    this.cropHintDetect = new CropHintDetectApi(this.helper)
    this.landmarkDetect = new LandmarkDetectApi(this.helper)
    this.logoDetect = new LogoDetectApi(this.helper)
    this.objectDetect = new ObjectDetectApi(this.helper)
    this.safeSearch = new SafeSearchApi(this.helper)
    this.translate = new TranslateApi(this.helper)
    this.tts = new TTSApi(this.helper)
    this.handwriteDetect = new HandwriteDetectApi(this.helper)
    this.textDetect = new TextDetectApi(this.helper)
  }

  static create = (apiBaseURL: string): AiUserApi => {
    const createApiHeader = () => ({})
    const responseInterceptor = (response: AxiosResponse) => response
    const errorResponseInterceptor = (error: AppError) => {}
    return new AiUserApi(apiBaseURL, createApiHeader, responseInterceptor, errorResponseInterceptor)
  }
}
