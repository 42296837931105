import { MuiSelect } from './MuiSelect'
import { MuiTable } from './MuiTable'
import { MuiTableCell } from './MuiTableCell'
import { MuiTextField } from './MuiTextField'
import { ThemeOptions } from '@mui/material'

export const overrides: ThemeOptions['components'] = {
  MuiTable,
  MuiTableCell,
  MuiTextField,
  MuiSelect,
}
