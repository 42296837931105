export interface GenericEnum<NAME, VALUE> {
  readonly name: NAME
  readonly ordinal: number
  readonly value: VALUE
}

/**
 * enum 정의, 더 좋은 방법이 없을까?
 *
 * @param definition
 * @returns
 * @example
 * type LocaleData = {
 *     locale: string
 *     desc: string
 * }
 *
 * type LangKeys = 'KO' | 'EN'
 * const Lang = createEnum<LangKeys, LocaleData>({
 *     KO: { locale: 'ko-KR', desc: '한국어' },
 *     EN: { locale: 'en-US', desc: '한국어' },
 * })
 *
 * Object.values(Lang).forEach((it) => {
 *     // KO, 0, 'ko-KR', '한국어'
 *     // EN, 1, 'en-US', '영어'
 *     console.log(it.name, it.ordinal, it.value.locale, it.value.desc)
 * })
 *
 * Object.keys(Lang).forEach((key) => {
 *     console.log(key) // KO, EN
 * })
 *
 * // 키 목록 나열
 * Object.keys(Lang) // keys as string
 *
 * // 자바스크립트에서 타입 체킹을 하려면
 * Object.values(Lang).map(it => it.name) // keys as LangKeys
 *
 */
export function createGenericEnum<NAME extends string, VALUE>(
  definition: Record<NAME, VALUE>,
): Record<NAME, GenericEnum<NAME, VALUE>> {
  const result = Object.keys(definition)
    .filter((key) => Object.prototype.hasOwnProperty.call(definition, key))
    .map((key, i) => ({ name: key as NAME, ordinal: i, value: definition[key as NAME] }))
    .reduce((acc, cur) => {
      acc[cur.name] = cur
      return acc
    }, {} as Record<NAME, GenericEnum<NAME, VALUE>>)

  return Object.freeze(result) as Record<NAME, GenericEnum<NAME, VALUE>>
}

/**
 * enum 정의, value가 string인 Enum 생성
 */
export function createStringEnum<NAME extends string>(
  definition: Record<NAME, string>,
): Record<NAME, GenericEnum<NAME, string>> {
  return createGenericEnum(definition)
}

/**
 * enum 정의, value가 number인 Enum 생성
 */
export function createNumberEnum<NAME extends string>(
  definition: Record<NAME, number>,
): Record<NAME, GenericEnum<NAME, number>> {
  return createGenericEnum(definition)
}
