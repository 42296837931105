import { ApiHelper, ApiHelperWithData, RequestDataBase, WasUrlHelper, splitParams } from '../../api'
import { EduResource, UploadedFile } from '../../model'

/**
 * EduResource Admin Api
 */
export class EduResourceApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 교육자료 목록 조회
   */
  list = async (
    params: {
      cateId?: string
      title?: string
    } & RequestDataBase,
  ): Promise<{ eduResourceList: EduResource[] }> => {
    const url = '/admin/api/edu-resource/list'
    const response: { eduResourceList: EduResource[] } = await this.withData.postJson(
      url,
      ...splitParams(params),
    )
    response.eduResourceList.forEach((item) => {
      WasUrlHelper.replaceRelative(item, 'imageUrl', 'pdfUrl')
    })
    return response
  }

  /**
   * 교육자료 정보 조회
   */
  info = async (
    params: {
      resId: string
    } & RequestDataBase,
  ): Promise<{ eduResource: EduResource }> => {
    const { resId, ...rest } = params
    const url = `/admin/api/edu-resource/info/${resId}`
    const response: { eduResource: EduResource } = await this.withData.postJson(
      url,
      ...splitParams(rest),
    )
    WasUrlHelper.replaceRelative(response.eduResource, 'imageUrl', 'pdfUrl')
    return response
  }

  /**
   * 카테고리 목록 조회
   */
  cateList = (params: RequestDataBase): Promise<{ cateList: string[] }> => {
    const url = '/admin/api/edu-resource/cate-list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 이미지 업로드
   * 서버에서 이미지를 저장 후 파일ID를 할당하고 그 정보를 응답한다.
   *
   * 교육자료를 등록할때 이 파일ID를 함께 전달하면 교육자료와 파일이 매핑된다.
   * 매핑되지 않은 파일ID는 1시간후에 자동 삭제된다.
   */
  uploadImage = async (
    params: {
      fileId: string
      file: File
    } & RequestDataBase,
  ): Promise<{ file: UploadedFile }> => {
    const url = '/admin/api/edu-resource/image-upload'
    const { ctx, file } = params
    const formData = new FormData()
    formData.append('file', file, file.name)
    const response: { file: UploadedFile } = await this.withData.postMultipart(url, formData, {
      ctx,
    })
    WasUrlHelper.replaceRelative(response.file, 'downloadUrl')
    return response
  }

  /**
   * PDF 업로드
   * 서버에서 PDF를 저장 후 파일ID를 할당하고 그 정보를 응답한다.
   *
   * 교육자료를 등록할때 이 파일ID를 함께 전달하면 교육자료와 파일이 매핑된다.
   * 매핑되지 않은 파일ID는 1시간후에 자동 삭제된다.
   */
  uploadPdf = async (
    params: {
      fileId: string
      file: File
    } & RequestDataBase,
  ): Promise<{ file: UploadedFile }> => {
    const url = '/admin/api/edu-resource/pdf-upload'
    const { ctx, file } = params
    const formData = new FormData()
    formData.append('file', file, file.name)

    const response: { file: UploadedFile } = await this.withData.postMultipart(url, formData, {
      ctx,
    })
    WasUrlHelper.replaceRelative(response.file, 'downloadUrl')
    return response
  }

  /**
   * 교육자료 신규 등록
   */
  create = async (
    params: {
      title: string
      cateId: string
      imageFileId: string
      pdfFileId: string
    } & RequestDataBase,
  ): Promise<{ eduResource: EduResource }> => {
    const url = '/admin/api/edu-resource/create'

    const response: { eduResource: EduResource } = await this.withData.postJson(
      url,
      ...splitParams(params),
    )
    WasUrlHelper.replaceRelative(response.eduResource, 'imageUrl', 'pdfUrl')
    return response
  }

  /**
   * 교육자료 업데이트
   */
  update = async (
    params: {
      resId: string
      cateId: string
      title: string

      /**
       * 이미지 파일ID, 변경하는 경우에만 값을 전달한다
       */
      imageFileId?: string

      /**
       * PDF 파일ID, 변경하는 경우에만 값을 전달한다
       */
      pdfFileId?: string
    } & RequestDataBase,
  ): Promise<{ eduResource: EduResource }> => {
    const { resId, ...rest } = params
    const url = `/admin/api/edu-resource/update/${resId}`
    const response: { eduResource: EduResource } = await this.withData.postJson(
      url,
      ...splitParams(rest),
    )
    WasUrlHelper.replaceRelative(response.eduResource, 'imageUrl', 'pdfUrl')
    return response
  }

  /**
   * 교육자료 삭제
   */
  delete = async (
    params: {
      resId: string
    } & RequestDataBase,
  ): Promise<void> => {
    const { resId, ...rest } = params
    const url = `/admin/api/edu-resource/delete/${resId}`
    await this.helper.postJson(url, ...splitParams(rest))
  }
}
