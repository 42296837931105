import { ApiHelper, ApiHelperWithData, RequestDataBase, WasUrlHelper, splitParams } from '../../api'
import { SoundCateKey } from '../../enum-types'
import { MakerSoundResource, NextPageData, UploadedFile } from '../../model'

/**
 * MakerSound admin Api
 */
export class MakerSoundApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 사운드 조회
   */
  listByCate = async (
    params: {
      cate: SoundCateKey
      nextKey: string | null
    } & RequestDataBase,
  ): Promise<{ pagerData: NextPageData<MakerSoundResource> }> => {
    const url = '/admin/api/maker-sound/list-by-cate'

    const response: { pagerData: NextPageData<MakerSoundResource> } = await this.withData.postJson(
      url,
      ...splitParams(params),
    )

    response.pagerData.list.forEach((item) => {
      WasUrlHelper.replaceRelative(item, 'soundUrl')
    })

    return response
  }

  /**
   * 사운드 검색
   */
  search = async (
    params: {
      title: string
      nextKey: string | null
    } & RequestDataBase,
  ): Promise<{ pagerData: NextPageData<MakerSoundResource> }> => {
    const url = '/admin/api/maker-sound/list-by-title'
    const response: { pagerData: NextPageData<MakerSoundResource> } = await this.withData.postJson(
      url,
      ...splitParams(params),
    )

    response.pagerData.list.forEach((item) => {
      WasUrlHelper.replaceRelative(item, 'soundUrl')
    })
    return response
  }

  /**
   * 사운드 신규 등록
   */
  create = async (
    params: {
      title: string
      fileId: string
      cate: SoundCateKey
      durationMs: number
    } & RequestDataBase,
  ): Promise<{
    makerSoundResource: MakerSoundResource
  }> => {
    const url = '/admin/api/maker-sound/create'
    const response: {
      makerSoundResource: MakerSoundResource
    } = await this.withData.postJson(url, ...splitParams(params))

    WasUrlHelper.replaceRelative(response.makerSoundResource, 'soundUrl')
    return response
  }

  /**
   * 사운드 정보 수정
   */
  update = async (
    params: {
      resId: string
      title: string
      fileId: string
      cate: SoundCateKey
      durationMs: number
    } & RequestDataBase,
  ): Promise<{
    makerSoundResource: MakerSoundResource
  }> => {
    const url = '/admin/api/maker-sound/update'
    const response: {
      makerSoundResource: MakerSoundResource
    } = await this.withData.postJson(url, ...splitParams(params))

    WasUrlHelper.replaceRelative(response.makerSoundResource, 'soundUrl')
    return response
  }

  /**
   * 사운드 파일(mp3) 업로드
   */
  uploadSoundFile = async (
    params: {
      file: File
      durationMs: number
    } & RequestDataBase,
  ): Promise<{ file: UploadedFile }> => {
    const url = '/admin/api/maker-sound/sound-upload'
    const { ctx, file, durationMs } = params
    const formData = new FormData()
    formData.append('durationMs', durationMs.toString())
    formData.append('file', file, file.name)
    const response: { file: UploadedFile } = await this.withData.postMultipart(url, formData, {
      ctx,
    })

    WasUrlHelper.replaceRelative(response.file, 'downloadUrl')
    return response
  }

  /**
   * 사운드 리소스 제거
   */
  delete = async (
    params: {
      resIdCsv: string
    } & RequestDataBase,
  ): Promise<void> => {
    const url = '/admin/api/maker-sound/delete'
    await this.helper.post(url, ...splitParams(params))
  }

  /**
   * 사운드 리소스 정렬
   */
  reorder = async (
    params: {
      resIdCsv: string
    } & RequestDataBase,
  ): Promise<void> => {
    const url = '/admin/api/maker-sound/reorder'
    await this.helper.post(url, ...splitParams(params))
  }

  /**
   * 사운드 정보 조회
   */
  info = async (
    params: {
      resId: string
    } & RequestDataBase,
  ): Promise<{
    makerSoundResource: MakerSoundResource
  }> => {
    const url = '/admin/api/maker-sound/info '
    const response: {
      makerSoundResource: MakerSoundResource
    } = await this.withData.post(url, ...splitParams(params))

    WasUrlHelper.replaceRelative(response.makerSoundResource, 'soundUrl')

    return response
  }

  /**
   * 사운드 상세 정보 조회
   * 파일 정보도 조회한다.
   * 사운드 정보 수정할때 필요
   */
  infoDetails = async (
    params: {
      resId: string
    } & RequestDataBase,
  ): Promise<{
    makerSoundResource: MakerSoundResource
    file: UploadedFile
  }> => {
    const url = '/admin/api/maker-sound/info-details'

    const response: {
      makerSoundResource: MakerSoundResource
      file: UploadedFile
    } = await this.withData.post(url, ...splitParams(params))

    WasUrlHelper.replaceRelative(response.makerSoundResource, 'soundUrl')
    WasUrlHelper.replaceRelative(response.file, 'downloadUrl')

    return response
  }
}
