import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'

/**
 * TextClassification Api
 */
export class TextClassificationApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * @deprecated WAS로 API 변경
   */
  features = (
    params: { text: string[] } & RequestDataBase,
  ): Promise<{ features: Array<number[]> }> => {
    const url = '/p/ai-api/v1/text-feature'
    const { ctx, text } = params

    return this.withData.postJson(url, { text }, { ctx })
  }
}
