import ProfileViewDialog, { ProfileViewDialogProps } from '@/dialogs/ProfileViewDialog'
import { ProfileDialogOpenEvent } from '@/lib/custom-events/ProfileDialogOpenEvent'
import { useCallback, useEffect, useState } from 'react'

type DialogId = 'ProfileViewDialog'

export default function UserProfileLoader() {
  const [profileDialogProps, setProfileDialogProps] = useState<ProfileViewDialogProps>()
  const [dialogId, setDialogId] = useState<DialogId>()

  const closeDialog = useCallback(() => {
    setDialogId(undefined)
    setProfileDialogProps(undefined)
  }, [])

  // 프로필 다이얼로그
  const openUserProfileDialog = useCallback(
    (accountId: number) => {
      setDialogId('ProfileViewDialog')
      setProfileDialogProps({
        accountId,
        open: true,
        onClose: closeDialog,
      })
    },
    [closeDialog],
  )

  useEffect(() => {
    const s1 = ProfileDialogOpenEvent.observe().subscribe((payload) => {
      openUserProfileDialog(payload.accountId)
    })

    return () => {
      s1.unsubscribe()
    }
  }, [openUserProfileDialog])

  if (dialogId === 'ProfileViewDialog' && profileDialogProps) {
    return <ProfileViewDialog {...profileDialogProps} />
  }
  return null
}
