import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { Guide } from '../../model'

export class GuideApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * Guide 단건 조회 by guide
   */
  info = (
    params: {
      guideId: number
    } & RequestDataBase,
  ): Promise<{ guide: Guide }> => {
    const url = '/p/api/guide/info'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * Guide 단건 조회 by guideKey
   */
  infoByGuideKey = (
    params: {
      guideKey: string
    } & RequestDataBase,
  ): Promise<{ guide: Guide }> => {
    const url = '/admin/api/guide/info-by-key'
    return this.withData.post(url, ...splitParams(params))
  }
}
