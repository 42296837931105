import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'
import { FaceDetectionResult } from '../../makerkit'
import { BoundingPoly, LandmarkDetectionResult, ObjectDetectionResult } from '../../model'

/**
 * Vision Api
 */
export class VisionApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  faceDetect = ({
    ctx,
    imageDataURL,
  }: { imageDataURL: string } & RequestDataBase): Promise<{ faces: FaceDetectionResult[] }> => {
    const url = '/p/ai-api/v1/vision/facedetect'

    return this.withData.postJson(url, { imageDataURL }, { ctx })
  }

  textDetect = ({
    ctx,
    imageDataURL,
  }: { imageDataURL: string } & RequestDataBase): Promise<{ texts: string[] }> => {
    const url = '/p/ai-api/v1/vision/text-detect'

    return this.withData.postJson(url, { imageDataURL }, { ctx })
  }

  cropHintDetect = ({
    ctx,
    imageDataURL,
  }: { imageDataURL: string } & RequestDataBase): Promise<{ boundingPoly: BoundingPoly }> => {
    const url = '/p/ai-api/v1/vision/crop-hint-detect'

    return this.withData.postJson(url, { imageDataURL }, { ctx })
  }

  handwriteDetect = (
    params: {
      imageDataURL: string
    } & RequestDataBase,
  ): Promise<{ texts: string[] }> => {
    const url = '/p/ai-api/v1/vision/handwrite-detect'
    const { ctx, imageDataURL } = params

    return this.withData.postJson(url, { imageDataURL }, { ctx })
  }

  landmarkDetect = (
    params: {
      imageDataURL: string
    } & RequestDataBase,
  ): Promise<{ results: LandmarkDetectionResult[] }> => {
    const url = '/p/ai-api/v1/vision/landmark-detect'
    const { ctx, imageDataURL } = params

    return this.withData.postJson(url, { imageDataURL }, { ctx })
  }

  safesearchDetect = (
    params: {
      imageDataURL: string
    } & RequestDataBase,
  ): Promise<{ result: string }> => {
    const url = '/p/ai-api/v1/vision/safesearch-detect'
    const { ctx, imageDataURL } = params

    return this.withData.postJson(url, { imageDataURL }, { ctx })
  }

  logoDetect = (
    params: {
      imageDataURL: string
    } & RequestDataBase,
  ): Promise<{ logoDescriptions: string[] }> => {
    const url = '/p/ai-api/v1/vision/logo-detect'
    const { ctx, imageDataURL } = params

    return this.withData.postJson(url, { imageDataURL }, { ctx })
  }

  objectDetect = (
    params: {
      imageDataURL: string
    } & RequestDataBase,
  ): Promise<{ objectDetectionResults: ObjectDetectionResult[] }> => {
    const url = '/p/ai-api/v1/vision/object-detect'
    const { ctx, imageDataURL } = params

    return this.withData.postJson(url, { imageDataURL }, { ctx })
  }
}
