import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'
/**
 * Codingpack Api
 */
export class CodingpackApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  log = async (
    params: {
      deviceId: string
      model: string
    } & RequestDataBase,
  ): Promise<void> => {
    const { ctx, deviceId, model } = params
    const url = '/p/api/codingpack/log'

    await this.helper.post(url, { deviceId, model }, { ctx })
  }
}
