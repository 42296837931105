import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'
import { Company, SimpleSimul, Simul } from '../../model'

/**
 * Simul Api
 */
export class SimulApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 시뮬레이터 생성 요청 - 회사
   */
  companyCreateRequest = (
    params: {
      simulName: string
      companyId: string
      blockPrefix: string
      cateColor: string
      cateIconFileId: string
      cateIconActiveFileId: string
      brandIconFileId: string
    } & RequestDataBase,
  ): Promise<{ simul: Simul; company?: Company }> => {
    const url = '/api/simul/company-create-request'
    const {
      simulName,
      companyId,
      blockPrefix,
      cateColor,
      cateIconFileId,
      cateIconActiveFileId,
      brandIconFileId,
      ctx,
    } = params

    return this.withData.postJsonAndSecuredResponse(
      url,
      {
        simulName,
        companyId,
        blockPrefix,
        cateColor,
        cateIconFileId,
        cateIconActiveFileId,
        brandIconFileId,
      },
      { ctx },
    )
  }

  /**
   * 시뮬레이터 생성 요청 - 개인
   */
  personalCreateRequest = (
    params: {
      simulName: string
      cateColor: string
      cateIconFileId: string
      cateIconActiveFileId: string
      brandIconFileId: string
    } & RequestDataBase,
  ): Promise<{ simul: Simul }> => {
    const url = '/api/simul/personal-create-request'
    const { simulName, cateColor, cateIconFileId, cateIconActiveFileId, brandIconFileId, ctx } =
      params

    return this.withData.postJsonAndSecuredResponse(
      url,
      {
        simulName,
        cateColor,
        cateIconFileId,
        cateIconActiveFileId,
        brandIconFileId,
      },
      { ctx },
    )
  }

  /**
   * 시뮬레이터 정보 조회
   * 권한있는 사용자가 시뮬레이터 정보를 조회한다.
   * deleted 상태는 조회되지 않는다.
   * 개인시뮬은 본인것만 조회, 회사시뮬은 스태프만 조회 가능
   */
  info = (
    params: {
      simulId: number
    } & RequestDataBase,
  ): Promise<{ simul: Simul }> => {
    const { simulId, ctx } = params
    const url = `/api/simul/info/${simulId}`

    return this.withData.postAndSecuredResponse(url, undefined, { ctx })
  }

  validateBlockPrefix = async (
    params: {
      blockPrefix: string
    } & RequestDataBase,
  ): Promise<void> => {
    const { blockPrefix, ctx } = params
    const url = `/api/simul/validate-block-prefix/${blockPrefix}`

    // E1_BLOCK_PREFIX_INVALID
    // E1_BLOCK_PREFIX_DUP

    await this.helper.post(url, undefined, { ctx })
  }

  hide = (
    params: {
      simulId: number
    } & RequestDataBase,
  ): Promise<{ simul: Simul }> => {
    const { simulId, ctx } = params
    const url = `/api/simul/hide/${simulId}`

    return this.withData.postAndSecuredResponse(url, undefined, { ctx })
  }

  show = (
    params: {
      simulId: number
    } & RequestDataBase,
  ): Promise<{ simul: Simul }> => {
    const { simulId, ctx } = params
    const url = `/api/simul/show/${simulId}`

    return this.withData.postAndSecuredResponse(url, undefined, { ctx })
  }

  deleteMark = async (
    params: {
      simulId: number
    } & RequestDataBase,
  ): Promise<void> => {
    const { simulId, ctx } = params
    const url = `/api/simul/delete-mark/${simulId}`

    await this.helper.post(url, undefined, { ctx })
  }

  /**
   * 시뮬레이터 목록 조회 - 본인이 권한있는 시뮬레이터 목록 조회
   */
  list = (
    params: RequestDataBase,
  ): Promise<{
    companyList: Company[]
    personalSimulList: Simul[]
    companySimulList: Simul[]
  }> => {
    const { ctx } = params
    const url = '/api/simul/list'

    return this.withData.postAndSecuredResponse(url, undefined, { ctx })
  }

  /**
   * 시뮬레이터 Simple 목록 조회 - 본인이 권한있는 시뮬레이터 목록 조회
   */
  simpleList = (
    params: RequestDataBase,
  ): Promise<{
    companyList: Company[]
    personalSimulList: SimpleSimul[]
    companySimulList: SimpleSimul[]
  }> => {
    const { ctx } = params
    const url = '/api/simul/simple-list'

    return this.withData.postAndSecuredResponse(url, undefined, { ctx })
  }

  /**
   * 시뮬레이터 아이콘 업데이트
   */
  updateCateIcon = (
    params: {
      simulId: number
      isActive: boolean
      fileId: string
    } & RequestDataBase,
  ): Promise<{ simul: Simul }> => {
    const { simulId, isActive, fileId, ctx } = params
    const url = `/api/simul/update-cate-icon/${simulId}/${isActive ? 1 : 0}`

    return this.withData.postAndSecuredResponse(url, { fileId }, { ctx })
  }

  /**
   * 시뮬레이터 브랜드 아이콘 업데이트
   */
  updateBrandIcon = (
    params: {
      simulId: number
      fileId: string
    } & RequestDataBase,
  ): Promise<{ simul: Simul }> => {
    const { simulId, fileId, ctx } = params
    const url = `/api/simul/update-brand-icon/${simulId}`

    return this.withData.postAndSecuredResponse(url, { fileId }, { ctx })
  }

  updateMeta = (
    params: {
      simulId: number
      simulName: string
      substance?: string
      intro?: string
      cateColor: string
      cateIconFileId?: string
      cateIconActiveFileId?: string
      brandIconFileId?: string
    } & RequestDataBase,
  ): Promise<{ simul: Simul }> => {
    const {
      simulId,
      simulName,
      substance,
      intro,
      cateColor,
      cateIconFileId,
      cateIconActiveFileId,
      brandIconFileId,
      ctx,
    } = params
    const url = `/api/simul/update-meta/${simulId}`

    return this.withData.postJsonAndSecuredResponse(
      url,
      {
        simulName,
        substance,
        intro,
        cateColor,
        cateIconFileId,
        cateIconActiveFileId,
        brandIconFileId,
      },
      { ctx },
    )
  }
}
