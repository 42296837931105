import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'

/**
 * Ginside Api
 */
export class GinsideApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  // AI 요청 서버로
  //   startSession = (params: RequestDataBase): Promise<{ ginsideSession: GinsideSession }> => {
  //     const url = '/api/ginside/start-session'
  //     const { ctx } = params
  //
  //     return this.withData.postJson(url, undefined, { ctx })
  //   }

  keepAlive = async (params: { sessionId: string } & RequestDataBase): Promise<void> => {
    //     const { sessionId } = params
    //     const url = `/api/ginside/keep-alive-session/${sessionId}`
    //     const { ctx } = params
    //
    //     await this.helper.post(url, undefined, { ctx })
  }
}
