import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'

/**
 * SafeSearch Api
 */
export class SafeSearchApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  detectSafeSearch = (
    params: {
      imageDataURL: string
    } & RequestDataBase,
  ): Promise<{ result: string }> => {
    const url = '/p/ai-api/v1/vision/safe-search/detect'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
