import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const cfg = {
  localStorageKeys: {
    token: publicRuntimeConfig.LOCAL_STORAGE_TOKEN_NAME,
    isAuthenticated: publicRuntimeConfig.LOCAL_STORAGE_IS_AUTHENTICATED,
  },
  httpHeaderKeys: {
    token: publicRuntimeConfig.HTTP_TOKEN_HEADER,
    tokenClear: publicRuntimeConfig.HTTP_TOKEN_CLEAR_HEADER,
  },
  apiBaseURL: publicRuntimeConfig.API_BASE_URL,
  routerBaseURL: publicRuntimeConfig.ROUTER_BASE_URL,
  debug: Boolean(publicRuntimeConfig.DEBUG),
}

// console.log('config=', cfg)
export default cfg
