import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'
import { txCipher } from '../../lib/PayloadCipher'
import { QuickDrawRank, UploadedFile } from '../../model'

/**
 * 퀵드로우 API
 */
export class QuickDrawApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  saveQuizResult = (
    params: {
      txId: string
      quizTime: number
    } & RequestDataBase,
  ): Promise<{ rank: QuickDrawRank }> => {
    const url = '/api/quick-draw/save-quiz-result'
    const { ctx, ...restParams } = params
    const encryptedBody = txCipher.encrypt(restParams.txId, restParams)
    return this.withData.postJsonAndSecuredResponse(url, encryptedBody, { ctx })
  }

  imageList = (
    params: {
      word: string
      maxCount: number
    } & RequestDataBase,
  ): Promise<{ imageUrlList: string[] }> => {
    const url = '/p/api/quick-draw/image-list'
    const { ctx, ...restParams } = params

    return this.withData.postAndSecuredResponse(
      url,
      {
        ...txCipher.createClientTx(),
        ...restParams,
      },
      { ctx },
    )
  }

  rankList = (
    params: {
      maxCount: number
    } & RequestDataBase,
  ): Promise<{ rankList: QuickDrawRank[]; myRank?: QuickDrawRank; totalUserCount: number }> => {
    const url = '/p/api/quick-draw/rank-list'
    const { ctx, ...restParams } = params

    return this.withData.postAndSecuredResponse(
      url,
      {
        ...txCipher.createClientTx(),
        ...restParams,
      },
      { ctx },
    )
  }

  uploadUserDrawing = (
    params: {
      modelId: string
      word: string
      file: File
    } & RequestDataBase,
  ): Promise<{ file: UploadedFile }> => {
    const { ctx, modelId, word, file } = params
    const url = '/p/api/quick-draw/image-upload'
    const formData = new FormData()
    formData.append('file', file, file.name)
    formData.append('word', word)
    formData.append('modelId', modelId)
    const clientTx = txCipher.createClientTx()
    Object.entries(clientTx).forEach(([key, value]) => {
      formData.append(key, value.toString())
    })
    return this.withData.postMultipartAndSecuredResponse(url, formData, { ctx })
  }

  saveUserDrawingFail = async (
    params: {
      modelId: string
      word: string
    } & RequestDataBase,
  ): Promise<void> => {
    const { ctx, ...restParams } = params
    const url = '/p/api/quick-draw/user-drawing/fail'
    await this.helper.post(
      url,
      {
        ...txCipher.createClientTx(),
        ...restParams,
      },
      { ctx },
    )
  }
}
