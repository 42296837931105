import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import {
  DSLogisticMultiRegressModelParams,
  DSLogisticRegressData,
  DSLogisticRegressModelJson,
  DSModel,
} from '../../model/data-science'

/**
 * Data Science Logistic Regress Model Api
 */
export class DSModelLogisticRegressApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  saveModelData = (
    params: {
      modelId: string
      modelName: string
      modelParams: DSLogisticMultiRegressModelParams
      columnNames: string[]
      columnKeys: string[]
      featureKeys: string[]
      classColumnKey: string
      rowCount: number
      rows: string[][]
    } & RequestDataBase,
  ): Promise<{ model: DSModel }> => {
    const url = '/api/ds-model/logistic-regress/save-data'
    return this.withData.postJsonAndSecuredResponse(url, ...splitParams(params))
  }

  /**
   * Logistic Regress 모델 정보 조회
   * 20230418 jjfive 로그인하지 않아도 모델을 로드할 수 있도록 함
   */
  model = (
    params: {
      modelId: string
    } & RequestDataBase,
  ): Promise<{ model: DSLogisticRegressModelJson }> => {
    const { modelId, ...rest } = params
    const url = `/p/api/ds-model/logistic-regress/model/${modelId}/model.json`

    return this.withData.postJson(url, ...splitParams(rest))
  }

  /**
   * Logistic Regress 데이터 조회
   * 20230418 jjfive 로그인하지 않아도 모델을 로드할 수 있도록 함
   */
  loadData = (
    params: {
      modelId: string
    } & RequestDataBase,
  ): Promise<{ model: DSLogisticRegressModelJson; data: DSLogisticRegressData }> => {
    const { modelId, ...rest } = params
    const url = `/p/api/ds-model/logistic-regress/data/${modelId}`

    return this.withData.postJson(url, ...splitParams(rest))
  }
}
