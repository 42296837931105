import {
  ApiHelper,
  ApiHelperWithData,
  PagingRequest,
  RequestDataBase,
  splitParams,
} from '../../api'
import { PagerData, UserCreateRequest } from '../../model'

/**
 * UserCreateRequest API
 * 사용자 생성 요청 API
 */
export class UserCreateRequestApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 요청 목록 조회
   */
  list = (params: PagingRequest): Promise<{ pagerData: PagerData<UserCreateRequest> }> => {
    const url = '/admin/api/user-create-request/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 사용자ID 생성 요청
   */
  save = (
    params: {
      startNumString: string
      idCount: number
      pw: string
      prefix: string
      suffix?: string
      memo?: string
    } & RequestDataBase,
  ): Promise<{ successCount: number; dupUserIds: string[] }> => {
    const url = '/admin/api/user-create-request/save'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
