import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'
import { BgCateKey, ObjCateKey } from '../../enum-types'
import { NextPageData, ObjectImageResource } from '../../model'

/**
 * Object Image Api
 */
export class ObjectImageApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  listByCate = (
    params: {
      isBackground: boolean
      cate: ObjCateKey | BgCateKey
      nextKey: string | null
    } & RequestDataBase,
  ): Promise<{ pagerData: NextPageData<ObjectImageResource> }> => {
    const url = '/p/api/object-image/list-by-cate'
    const { isBackground, cate, nextKey, ctx } = params

    return this.withData.postJson(url, { isBackground, cate, nextKey }, { ctx })
  }

  search = (
    params: {
      isBackground: boolean
      objectName: string
      nextKey: string | null
    } & RequestDataBase,
  ): Promise<{ pagerData: NextPageData<ObjectImageResource> }> => {
    const url = '/p/api/object-image/list-by-objectName'
    const { isBackground, objectName, nextKey, ctx } = params

    return this.withData.postJson(url, { isBackground, objectName, nextKey }, { ctx })
  }
}
