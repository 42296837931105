import { OneTimeActionKey, SecQuestionTypeKey, StudentGradeKey } from '../../enum-types'
import { txCipher } from '../../lib/PayloadCipher'
import { OneTimeTx, UserAccount } from '../../model'
import { HTTP_TOKEN_HEADER, RequestDataBase, ApiHelper, ApiHelperWithData } from '../../api'
/**
 * 인증 관련 API
 */
export class AuthApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  // /**
  //  * 로그인
  //  * 미사용
  //  * @deprecated
  //  */
  // signIn = (
  //     params: {
  //         userId: string
  //         password: string
  //         captchaSid: string
  //         captchaAnswer: string
  //         referPartnerId?: string
  //     } & RequestDataBase,
  // ): Promise<{ userProfile: UserAccount }> => {
  //     const url = '/p/api/auth/login'
  //     const header = { [HTTP_TOKEN_HEADER]: '' }
  //     const { userId, password, captchaSid, captchaAnswer, referPartnerId, ctx } = params
  //     return this.withData.post(url, { userId, password, captchaSid, captchaAnswer, referPartnerId }, { header, ctx })
  // }

  // /**
  //  * 캡차 세션 시작
  //  * 미사용
  //  * @deprecated
  //  */
  // captchaNew = (params: RequestDataBase): Promise<{ sid: string; downloadUrl: string }> => {
  //     const url = '/p/api/user/captcha/new'
  //     const { ctx } = params
  //     return this.withData.post(url, undefined, { ctx })
  // }

  // /**
  //  * 로그인
  //  * 미사용
  //  * @deprecated
  //  */
  // signInWithGoogleReCaptcha = (
  //     params: {
  //         userId: string
  //         password: string
  //         txId: string
  //         referPartnerId?: string
  //     } & RequestDataBase,
  // ): Promise<{ userProfile: UserAccount }> => {
  //     const url = '/p/api/auth/login'
  //     const header = { [HTTP_TOKEN_HEADER]: '' }
  //     const { userId, password, txId, referPartnerId, ctx } = params
  //     return this.withData.post(url, { userId, password, txId, referPartnerId }, { header, ctx })
  // }

  /**
   * 로그인
   */
  signInWithGoogleReCaptcha = (
    params: {
      userId: string
      password: string
      txId: string
      referPartnerId?: string
    } & RequestDataBase,
  ): Promise<{ userProfile: UserAccount }> => {
    const url = '/p/api/auth/login'
    const header = { [HTTP_TOKEN_HEADER]: '' }
    const { userId, password, txId, referPartnerId, ctx } = params
    const encryptedBody = txCipher.encrypt(txId, {
      userId,
      password,
      txId,
      referPartnerId,
    })
    // return this.withData.post(url, { userId, password, txId, referPartnerId }, { header, ctx })
    return this.withData.postJsonAndSecuredResponse(url, encryptedBody, { header, ctx })
  }

  /**
   * 회원가입
   */
  joinByUserId = (
    params: {
      txId: string
      referUserId?: string
      referPartnerId?: string
      userId: string
      clauseIds: number[]
      studentGrade: StudentGradeKey
      password: string
      secAnswer1: string
      secAnswer2: string
      secAnswer3: string
      secQuestion1: SecQuestionTypeKey
      secQuestion2: SecQuestionTypeKey
      secQuestion3: SecQuestionTypeKey
    } & RequestDataBase,
  ): Promise<{ userProfile: UserAccount }> => {
    const url = '/p/api/auth/join'
    const header = { [HTTP_TOKEN_HEADER]: '' }
    const { ctx, ...restParams } = params
    const encryptedBody = txCipher.encrypt(restParams.txId, restParams)
    return this.withData.postJsonAndSecuredResponse(url, encryptedBody, { header, ctx })
  }

  /**
   * TX 시작
   */
  txStart = (
    params: {
      action: OneTimeActionKey
    } & RequestDataBase,
  ): Promise<{ tx: OneTimeTx }> => {
    const url = '/api/one-time-tx/begin'
    const { ctx, action } = params
    return this.withData.post(url, { action }, { ctx })
  }

  /**
   * 익명 TX 시작
   */
  txStartAnon = (
    params: {
      action: OneTimeActionKey
    } & RequestDataBase,
  ): Promise<{ tx: OneTimeTx }> => {
    const url = '/p/api/one-time-tx/begin'
    const { ctx, action } = params
    return this.withData.post(url, { action }, { ctx })
  }

  /**
   * TX 검증
   */
  verify = async (
    params: {
      txId: string
      action: OneTimeActionKey
      captchaToken: string
    } & RequestDataBase,
  ): Promise<void> => {
    const url = '/api/one-time-tx/verify'
    const { ctx, txId, action, captchaToken } = params
    await this.helper.post(url, { txId, action, captchaToken }, { ctx })
  }

  /**
   * 익명 TX 검증
   */
  verifyAnon = async (
    params: {
      txId: string
      action: OneTimeActionKey
      captchaToken: string
    } & RequestDataBase,
  ): Promise<void> => {
    const url = '/p/api/one-time-tx/verify'
    const { ctx, txId, action, captchaToken } = params
    await this.helper.post(url, { txId, action, captchaToken }, { ctx })
  }

  checkCaptcha = (params: { captchaToken: string } & RequestDataBase): Promise<boolean> => {
    const { ctx, captchaToken } = params
    const url = '/api/google-captcha/check'

    return this.withData.post(url, { captchaToken }, { ctx })
  }

  checkCaptchaAnon = async (
    params: { captchaToken: string } & RequestDataBase,
  ): Promise<boolean> => {
    const { ctx, captchaToken } = params
    const url = '/p/api/google-captcha/check'

    return this.withData.post(url, { captchaToken }, { ctx })
  }

  /**
   * 로그아웃
   */
  signOut = async (params: RequestDataBase): Promise<void> => {
    const url = '/api/auth/logout'
    const { ctx } = params
    await this.helper.post(url, undefined, { ctx })
  }
}
