import {
  ApiHelper,
  ApiHelperWithData,
  HTTP_TOKEN_HEADER,
  RequestDataBase,
  splitParams,
} from '../../api'
import { txCipher } from '../../lib/PayloadCipher'
import { AdminAccount, AdminTx } from '../../model'

/**
 * 관리자 인증 API
 */
export class AuthApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  txStart = async (): Promise<{
    tx: AdminTx
  }> => {
    const url = '/p/admin/api/tx/start'
    const { txId: clientTxId, timestamp, signature } = txCipher.createClientTx()
    return await this.withData.postAndSecuredResponse(
      url,
      { clientTxId, timestamp, signature },
      undefined,
    )
  }

  /**
   * 로그인
   */
  signIn = async (
    params: {
      email: string
      password: string
    } & RequestDataBase,
  ): Promise<{ profile: AdminAccount; pwMustReset: boolean }> => {
    const url = '/p/admin/api/authenticate'
    const header = { [HTTP_TOKEN_HEADER]: '' }
    const { email, password, ctx } = params
    const { tx } = await this.txStart()
    const txId = tx.txId
    const encryptedBody = txCipher.encrypt(txId, {
      txId,
      email,
      password,
    })
    return this.withData.postJsonAndSecuredResponse(url, encryptedBody, { header, ctx })
  }

  /**
   * 로그아웃
   */
  signOut = async (params: RequestDataBase): Promise<void> => {
    const url = '/admin/api/logout'
    await this.helper.post(url, ...splitParams(params))
  }
}
