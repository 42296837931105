import { mainLayoutConstants } from '@/layout-constants'
import { PaletteOptions } from '@mui/material/styles'
import colors from './theme-colors'

const CONTENT_BG_COLOR = mainLayoutConstants.content.bgColor

const textColors = {
  t0: colors.colorBlackText0,
  t1: colors.colorBlackText1,
  t2: colors.colorBlackText2,
  t3: colors.colorBlackText3,
  t4: colors.colorBlackText4,
  t5: colors.colorBlackText5,
  t6: colors.colorBlackText6,
  t7: colors.colorBlackText7,
  t8: colors.colorBlackText8,
  t9: colors.colorBlackText9,
  t10: colors.colorBlackText10,
  t11: colors.colorBlackText11,
  t12: colors.colorBlackText12,
}

const dividers = {
  divider0: colors.colorDarkDivider0,
  divider1: colors.colorDarkDivider1,
  divider2: colors.colorDarkDivider2,
}

export const palette: PaletteOptions = {
  common: {
    black: '#000',
    white: '#FFF',
    neutral: '#E4E7EB',
    muted: '#9EA0A4',
  },

  primary: {
    contrastText: '#fff',
    light: '#6f78ff',
    main: '#4859EE',
    dark: '#0025a7',
  },
  secondary: {
    contrastText: '#FFF',
    light: '#ff6333',
    main: '#ff3d00',
    dark: '#b22a00',
  },

  neutral: {
    contrastText: '#FFF',
    light: '#969696',
    main: '#646464',
    dark: '#242424',
  },
  // success: {
  //     contrastText: '#FFF',
  //     light: '#BDEAAD',
  //     main: '#4CAF50',
  //     dark: '#217C40',
  // },
  // info: {
  //     contrastText: '#FFF',
  //     light: '#C9EAFF',
  //     main: '#0091EA',
  //     dark: '#00639F',
  // },
  // warning: {
  //     contrastText: '#FFF',
  //     light: '#FFDE9D',
  //     main: '#F9A825',
  //     dark: '#BA5900',
  // },
  // danger: {
  //     contrastText: '#FFF',
  //     light: '#FFC8C8',
  //     main: '#D32F2F',
  //     dark: '#990808',
  // },
  text: {
    primary: '#161616',
    secondary: '#616161',
    disabled: '#969696',
    ...textColors,
  },
  background: {
    default: CONTENT_BG_COLOR,
    dark: '#EEEEEE',
    paper: '#FFF',
  },

  divider: '#DDDDDD',
  ...dividers,
}
