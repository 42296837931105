import { ApiHelper, ApiHelperWithData, splitParams } from '../../api'
import { AppLog, PagerData } from '../../model'
import { AppLogListRequest } from './payload/app-log-payload'

/**
 * App log Api
 */
export class AppLogApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * App 로그 목록 조회 - 페이징
   */
  list = (params: AppLogListRequest): Promise<{ appLogPagerData: PagerData<AppLog> }> => {
    const url = '/admin/api/app-log/list'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
