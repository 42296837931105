import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'
import { SimulButton } from '../../model'

/**
 * SimulButton Api
 */
export class SimulButtonApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 버튼 생성
   */
  create = (
    params: {
      simulId: number
      title: string
      callbackKey: string
    } & RequestDataBase,
  ): Promise<{ button: SimulButton }> => {
    const url = '/api/simul-button/create'
    const { simulId, title, callbackKey, ctx } = params

    return this.withData.postJson(url, { simulId, title, callbackKey }, { ctx })
  }

  /**
   * 버튼 수정
   */
  update = (
    params: {
      fid: string
      simulId: number
      title: string
      callbackKey: string
    } & RequestDataBase,
  ): Promise<{ button: SimulButton }> => {
    const { fid, simulId, title, callbackKey, ctx } = params
    const url = `/api/simul-button/update/${fid}`

    return this.withData.postJson(url, { simulId, title, callbackKey }, { ctx })
  }

  /**
   * 버튼 삭제
   */
  delete = async (
    params: {
      fid: string
    } & RequestDataBase,
  ): Promise<void> => {
    const { fid, ctx } = params
    const url = `/api/simul-button/delete/${fid}`

    await this.helper.post(url, undefined, { ctx })
  }

  /**
   * 버튼 목록 조회
   */
  list = (
    params: {
      simulId: number
    } & RequestDataBase,
  ): Promise<{ buttonList: SimulButton[] }> => {
    const { simulId, ctx } = params
    const url = '/api/simul-button/list'

    return this.withData.post(url, { simulId }, { ctx })
  }
}
