import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'
import { BlockDefinitionTypeKey } from '../../enum-types'
import { Company, Simul, SimulUnitBlock } from '../../model'

/**
 * SimulUnitBlock Api
 */
export class SimulUnitBlockApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 단위블록 생성
   */
  create = (
    params: {
      simulId: number
      imageFileId: string
      blockType: string
      workspaceXml: string
      definition: string
      definitionType: BlockDefinitionTypeKey
      generatorJs?: string
      generatorPython?: string
    } & RequestDataBase,
  ): Promise<{ unitBlock: SimulUnitBlock }> => {
    const url = '/api/simul-unit-block/create'
    const {
      simulId,
      imageFileId,
      blockType,
      workspaceXml,
      definition,
      definitionType,
      generatorJs,
      generatorPython,
      ctx,
    } = params

    // E1_SIMUL_INVALID_BLOCK_TYPE
    // E1_SIMUL_BLOCK_PREFIX_MISMATCH

    return this.withData.postJson(
      url,
      {
        simulId,
        imageFileId,
        blockType,
        workspaceXml,
        definition,
        definitionType,
        generatorJs,
        generatorPython,
      },
      { ctx },
    )
  }

  /**
   * 단위블록 수정
   */
  update = (
    params: {
      fid: string
      simulId: number
      imageFileId: string
      blockType: string
      workspaceXml: string
      definition: string
      definitionType: BlockDefinitionTypeKey
      generatorJs?: string
      generatorPython?: string
    } & RequestDataBase,
  ): Promise<{ unitBlock: SimulUnitBlock }> => {
    const {
      fid,
      simulId,
      imageFileId,
      blockType,
      workspaceXml,
      definition,
      definitionType,
      generatorJs,
      generatorPython,
      ctx,
    } = params
    const url = `/api/simul-unit-block/update/${fid}`

    // E1_SIMUL_INVALID_BLOCK_TYPE
    // E1_SIMUL_BLOCK_PREFIX_MISMATCH

    return this.withData.postJson(
      url,
      {
        simulId,
        imageFileId,
        blockType,
        workspaceXml,
        definition,
        definitionType,
        generatorJs,
        generatorPython,
      },
      { ctx },
    )
  }

  /**
   * 단위블록 삭제
   */
  delete = async (
    params: {
      fid: string
    } & RequestDataBase,
  ): Promise<void> => {
    const { fid, ctx } = params
    const url = `/api/simul-unit-block/delete/${fid}`
    // E1_NO_SUCH_SIMUL_UNIT_BLOCK
    await this.helper.post(url, undefined, { ctx })
  }

  /**
   * 단위블록 목록 조회
   */
  list = (
    params: {
      simulId: number
    } & RequestDataBase,
  ): Promise<{
    simul: Simul
    company?: Company
    unitBlockList: SimulUnitBlock[]
  }> => {
    const { simulId, ctx } = params
    const url = '/api/simul-unit-block/list'

    return this.withData.postAndSecuredResponse(url, { simulId }, { ctx })
  }

  info = (
    params: {
      fid: string
    } & RequestDataBase,
  ): Promise<{ unitBlock: SimulUnitBlock }> => {
    const { fid, ctx } = params
    const url = `/api/simul-unit-block/info/${fid}`

    return this.withData.post(url, undefined, { ctx })
  }

  findDupBlockTypes = (
    params: {
      simulId: number
    } & RequestDataBase,
  ): Promise<{
    unitBlockTotalCount: number
    blockTypeList: string[]
  }> => {
    const { simulId, ctx } = params
    const url = '/api/simul-unit-block/find-dup'

    return this.withData.post(url, { simulId }, { ctx })
  }
}
