import { ApiHelper, ApiHelperWithData, RequestDataBase, WasUrlHelper, splitParams } from '../../api'
import { HwDriver, HwDriverFile, UploadedFile } from '../../model'
import { HwArchKey, HwOsKey } from './../../enum-types'

/**
 * 하드웨어 드라이버 API
 */
export class HwDriverApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 하드웨어 드라이버 생성
   */
  create = (
    params: {
      driverName: string
      driverShortName?: string
      manufacturer?: string
      manufacturerSite?: string
      substance?: string
    } & RequestDataBase,
  ): Promise<{ hwDriver: HwDriver }> => {
    const url = '/admin/api/hw-driver/create'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 하드웨어 드라이버 수정
   */
  update = (
    params: {
      driverId: number
      driverShortName?: string
      manufacturer?: string
      manufacturerSite?: string
      substance?: string
    } & RequestDataBase,
  ): Promise<{ hwDriver: HwDriver }> => {
    const url = '/admin/api/hw-driver/update'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 하드웨어 드라이버 삭제
   * 드라이버의 모든 파일이 삭제된다
   */
  delete = async (
    params: {
      driverId: number
    } & RequestDataBase,
  ): Promise<void> => {
    const url = '/admin/api/hw-driver/delete'
    await this.helper.post(url, ...splitParams(params))
  }

  /**
   * 드라이버 목록 조회
   */
  driverList = (params: RequestDataBase): Promise<{ hwDriverList: HwDriver[] }> => {
    const url = '/admin/api/hw-driver/list'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 드라이버 정보 조회
   */
  info = async (
    params: { driverId: number } & RequestDataBase,
  ): Promise<{
    hwDriver: HwDriver
    hwDriverFileList: HwDriverFile[]
  }> => {
    const url = '/admin/api/hw-driver/info'
    const response: {
      hwDriver: HwDriver
      hwDriverFileList: HwDriverFile[]
    } = await this.withData.post(url, ...splitParams(params))

    response.hwDriverFileList.forEach((item) => {
      WasUrlHelper.replaceRelative(item, 'downloadUrl')
    })
    return response
  }

  /**
   * 드라이버 파일 업로드
   */
  uploadTempDriverFile = async (
    params: {
      file: File
    } & RequestDataBase,
  ): Promise<{ file: UploadedFile }> => {
    const { file, ctx } = params
    const url = '/admin/api/hw-driver/upload'
    const formData = new FormData()
    formData.append('file', file, file.name)
    const response: { file: UploadedFile } = await this.withData.postMultipart(url, formData, {
      ctx,
    })
    WasUrlHelper.replaceRelative(response.file, 'downloadUrl')
    return response
  }

  /**
   * 드라이버 파일 신규 등록
   */
  createDriverFile = async (
    params: {
      driverId: number
      fileId: string
      os: HwOsKey
      arch: HwArchKey[]
      driverSite?: string
      substance?: string
    } & RequestDataBase,
  ): Promise<{
    hwDriver: HwDriver
    hwDriverFileList: HwDriverFile[]
  }> => {
    const url = '/admin/api/hw-driver/create-file'
    const response: {
      hwDriver: HwDriver
      hwDriverFileList: HwDriverFile[]
    } = await this.withData.postJson(url, ...splitParams(params))

    response.hwDriverFileList.forEach((item) => {
      WasUrlHelper.replaceRelative(item, 'downloadUrl')
    })

    return response
  }

  /**
   * 드라이버 파일 업데이트
   * 신규 파일로 변경할 수는 없음
   * 신규 파일로 변경하려면, 삭제하고 재등록하세요
   */
  updateDriverFile = async (
    params: {
      driverId: number
      fileId: string
      os: HwOsKey
      arch: HwArchKey[]
      driverSite?: string
      substance?: string
    } & RequestDataBase,
  ): Promise<{
    hwDriver: HwDriver
    hwDriverFileList: HwDriverFile[]
  }> => {
    const url = '/admin/api/hw-driver/update-file'

    const response: {
      hwDriver: HwDriver
      hwDriverFileList: HwDriverFile[]
    } = await this.withData.postJson(url, ...splitParams(params))

    response.hwDriverFileList.forEach((item) => {
      WasUrlHelper.replaceRelative(item, 'downloadUrl')
    })
    return response
  }

  /**
   * 드라이버 파일 삭제
   */
  deleteDriverFile = async (
    params: {
      fileId: string
    } & RequestDataBase,
  ): Promise<void> => {
    const url = '/admin/api/hw-driver/delete-file'
    await this.helper.post(url, ...splitParams(params))
  }
}
