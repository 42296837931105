import { UserGradeTypeKey } from './enum-types'

export function scoreToUserGrade(score: number): UserGradeTypeKey {
  if (score < 2000) {
    return 'AI_STARTER'
  } else if (score < 10000) {
    return 'AI_SPECIALIST'
  } else if (score < 50000) {
    return 'AI_EXPERT'
  } else if (score < 70000) {
    return 'AI_MASTER'
  }

  return 'AI_GRAND_MASTER'
}
