import log from '@/log'
import { ApiRequestContext } from '@aimk/domain'
import { observer } from 'mobx-react'
import { useCallback, useEffect, useState } from 'react'
import { useDebounce } from 'react-use'
import { useApi } from './provider'
import useStore from './store/useStore'

function IntlMessageLoader() {
  const api = useApi()
  const intlStore = useStore().intlStore
  const [refreshToken, setRefreshToken] = useState(0)

  useDebounce(
    () => {
      log.info('intl store: received refresh request:', intlStore.refreshLoadToken)
      setRefreshToken(intlStore.refreshLoadToken)
    },
    200,
    [intlStore.refreshLoadToken],
  )

  const reloadData = useCallback(
    async (ctx: ApiRequestContext) => {
      log.info('intl store: sync all messages from server')
      try {
        const { allMessages } = await api.i18n.allMessagesPerSection({ ctx })
        if (ctx.canceled) return
        intlStore.setAllMessages(allMessages)
      } catch (e) {
        log.debug('intl message load fail', e)
      }
    },
    [api.i18n, intlStore],
  )

  useEffect(() => {
    const ctx: ApiRequestContext = { canceled: false }
    reloadData(ctx)
    return () => {
      ctx.canceled = true
      ctx.cancel?.()
    }
  }, [refreshToken, reloadData])

  return null
}

export default observer(IntlMessageLoader)
