import { ApiHelper, ApiHelperWithData, RequestDataBase, WasUrlHelper, splitParams } from '../../api'
import { EduToy, UploadedFile } from '../../model'

/**
 * 교구 API
 */
export class EduToyApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 교구 정보 신규 등록
   */
  create = (
    params: {
      toyId: string
      toyName: string
      toyShortName?: string
      newToyImageFileId?: string
      driverId?: number
      driverName?: string
      brief?: string
      substance?: string
    } & RequestDataBase,
  ): Promise<{ eduToy: EduToy }> => {
    const url = '/admin/api/edu-toy/create'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 교구 정보 업데이트
   */
  update = (
    params: {
      toyId: string
      toyName: string
      toyShortName?: string

      /**
       * 이미지를 변경한 경우에만 파일ID 전달
       */
      newToyImageFileId?: string
      driverId?: number
      driverName?: string
      brief?: string
      substance?: string
    } & RequestDataBase,
  ): Promise<{ eduToy: EduToy }> => {
    const url = '/admin/api/edu-toy/update'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 교구 정보 삭제
   */
  delete = async (
    params: {
      toyId: string
    } & RequestDataBase,
  ): Promise<void> => {
    const url = '/admin/api/edu-toy/delete'
    await this.helper.post(url, ...splitParams(params))
  }

  /**
   * 교구 목록 조회
   */
  list = (params: RequestDataBase): Promise<{ eduToyList: EduToy[] }> => {
    const url = '/admin/api/edu-toy/list'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 교구 정보 조회
   */
  info = (
    params: { toyId: string } & RequestDataBase,
  ): Promise<{
    eduToy: EduToy
  }> => {
    const url = '/admin/api/edu-toy/info'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 교구 이미지 업로드 - 임시저장
   */
  uploadImage = async (
    params: {
      file: File
    } & RequestDataBase,
  ): Promise<{ file: UploadedFile }> => {
    const url = '/admin/api/edu-toy/image-upload'
    const { ctx, file } = params
    const formData = new FormData()
    formData.append('file', file, file.name)
    const response: { file: UploadedFile } = await this.withData.postMultipart(url, formData, {
      ctx,
    })
    WasUrlHelper.replaceRelative(response.file, 'downloadUrl')
    return response
  }
}
