import { action, observable, makeObservable } from 'mobx'

/**
 * UI 상태를 보관하는 mobx 스토어
 */
export default class UiStore {
  constructor() {
    makeObservable(this)
  }

  /**
   * 사이드바 열림 상태
   */
  @observable isOpen = false

  /**
   * 사이드바 열림 상태 변경
   * @param isOpen 열림 상태
   */
  @action setOpen = (isOpen: boolean) => {
    this.isOpen = isOpen
  }
}
