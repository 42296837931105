import { ApiHelper, ApiHelperWithData } from '../../api'
import {
  BbsAttachFile,
  BbsPost,
  BbsPostDataCount,
  BbsPostSimple,
  BbsReply,
  BbsSearchOption,
  PagerData,
} from '../../model'
import { BbsRequest } from '../payload/bbs-payload'

/**
 * 게시판 공통 API
 */
export class BbsApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 포스트 작성
   */
  createPost = (
    params: {
      title: string
      substance: string
      fileIds: string[]
      txId: string
    } & BbsRequest,
  ): Promise<{ post: BbsPost }> => {
    const { ctx, bbsId, ...rest } = params
    const url = `/api/bbs/${bbsId}/post/write`
    return this.withData.postAndSecuredResponse(url, rest, { ctx })
  }

  /**
   * 포스트 수정
   */
  updatePost = (
    params: {
      tid: number
      title: string
      substance: string
      fileIds: string[]
      txId: string
    } & BbsRequest,
  ): Promise<{ post: BbsPost }> => {
    const { ctx, bbsId, ...rest } = params
    const url = `/api/bbs/${bbsId}/post/update`
    return this.withData.postAndSecuredResponse(url, rest, { ctx })
  }

  /**
   * 포스트 목록 조회
   */
  postList = (
    params: BbsSearchOption & BbsRequest,
  ): Promise<{ pagerData: PagerData<BbsPostSimple> }> => {
    const url = `/p/api/bbs/${params.bbsId}/post/list`
    const { ctx, sortType, rowsPerPage, pageNumber, queryField, query, mineOnly } = params

    return this.withData.postJsonAndSecuredResponse(
      url,
      {
        sortType,
        pageNumber,
        rowsPerPage,
        queryField,
        query,
        mineOnly,
      },
      { ctx },
    )
  }

  /**
   * 포스트 상세 조회
   */
  postInfo = (params: { tid: number } & BbsRequest): Promise<{ post: BbsPost }> => {
    const { ctx, bbsId, tid } = params
    const url = `/p/api/bbs/${bbsId}/post/info/${tid}`
    return this.withData.postAndSecuredResponse(url, undefined, { ctx })
  }

  /**
   * 댓글 작성
   */
  createReply = (
    params: {
      postId: number
      substance: string
      txId: string
    } & BbsRequest,
  ): Promise<{ reply: BbsReply; dataCount: BbsPostDataCount }> => {
    const { ctx, postId, bbsId, ...rest } = params
    const url = `/api/bbs/${bbsId}/reply/write/${postId}`
    return this.withData.postAndSecuredResponse(url, rest, { ctx })
  }

  /**
   * 대댓글 작성
   */
  createGreatReply = (
    params: {
      parentReplyId: number
      substance: string
      txId: string
    } & BbsRequest,
  ): Promise<{ reply: BbsReply; dataCount: BbsPostDataCount }> => {
    const { ctx, bbsId, parentReplyId, ...rest } = params
    const url = `/api/bbs/${bbsId}/great-reply/write/${parentReplyId}`
    return this.withData.postAndSecuredResponse(url, rest, { ctx })
  }

  /**
   * 댓글/대댓글 수정
   */
  updateReply = (
    params: {
      replyId: number
      substance: string
      txId: string
    } & BbsRequest,
  ): Promise<{ reply: BbsReply; dataCount: BbsPostDataCount }> => {
    const { ctx, bbsId, replyId, ...rest } = params
    const url = `/api/bbs/${bbsId}/reply/update/${replyId}`
    return this.withData.postAndSecuredResponse(url, rest, { ctx })
  }

  /**
   * 댓글 목록 조회
   */
  replyList = (
    params: { postId: number; rowsPerPage: number; pageNumber: number } & BbsRequest,
  ): Promise<{ pagerData: PagerData<BbsReply> }> => {
    const { ctx, bbsId, postId, rowsPerPage, pageNumber } = params
    const url = `/p/api/bbs/${bbsId}/reply/list/${postId}/${pageNumber}`

    return this.withData.postAndSecuredResponse(url, { rowsPerPage }, { ctx })
  }

  /**
   * 포스트, 댓글, 대댓글 삭제
   */
  delete = (params: { tid: number } & BbsRequest): Promise<{ dataCount: BbsPostDataCount }> => {
    const { ctx, bbsId, tid } = params
    const url = `/api/bbs/${bbsId}/article/delete/${tid}`
    return this.withData.post(url, undefined, { ctx })
  }

  /**
   * 좋아요 추가
   * 게시물의 좋아요 건수를 응답
   */
  favorAdd = (
    params: {
      articleId: number
    } & BbsRequest,
  ): Promise<{ count: number }> => {
    const { ctx, articleId } = params
    const url = `/api/bbs/${params.bbsId}/favor/add/${articleId}`
    return this.withData.post(url, undefined, { ctx })
  }

  /**
   * 좋아요 취소
   * 게시물의 좋아요 건수를 응답
   */
  favorRemove = (
    params: {
      articleId: number
    } & BbsRequest,
  ): Promise<{ count: number }> => {
    const { ctx, articleId } = params
    const url = `/api/bbs/${params.bbsId}/favor/remove/${articleId}`
    return this.withData.post(url, undefined, { ctx })
  }

  /**
   * 첨부파일 업로드
   */
  uploadAttachFile = (
    params: {
      file: File
    } & BbsRequest,
  ): Promise<{ file: BbsAttachFile }> => {
    const url = `/api/bbs/${params.bbsId}/upload`
    const { ctx, file } = params
    const formData = new FormData()
    formData.append('file', file, file.name) // formData.append('file', file)
    console.log('uploading file:', { name: file.name, size: file.size, type: file.type })

    return this.withData.postMultipart(url, formData, { ctx })
  }
}
