import { GuideCateKey } from '../../enum-types'
import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { Guide, GuideSimple, PagerData, UploadedFile } from '../../model'

export class GuideApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * Guide 신규 등록
   */
  create = (
    params: {
      title: string
      guideKey: string
      cate: GuideCateKey
      substance: string
      attachFileIds: string[]
    } & RequestDataBase,
  ): Promise<{ guide: Guide }> => {
    const url = '/admin/api/guide/create'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * Guide 업데이트
   */
  update = (
    params: {
      guideId: number
      title: string
      guideKey: string
      cate: GuideCateKey
      substance: string
      attachFileIds: string[]
    } & RequestDataBase,
  ): Promise<{ guide: Guide }> => {
    const url = '/admin/api/guide/update'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * Guide 검색
   */
  search = (
    params: {
      pageNumber: number
      rowsPerPage: number
      cate?: GuideCateKey
      keyword?: string
    } & RequestDataBase,
  ): Promise<{ pagerData: PagerData<GuideSimple> }> => {
    const url = '/admin/api/guide/search'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * Guide 단건 조회 by guide
   */
  info = (
    params: {
      guideId: number
    } & RequestDataBase,
  ): Promise<{ guide: Guide }> => {
    const url = '/admin/api/guide/info'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * Guide 단건 조회 by guideKey
   */
  infoByGuideKey = (
    params: {
      guideKey: string
    } & RequestDataBase,
  ): Promise<{ guide: Guide }> => {
    const url = '/admin/api/guide/info-by-key'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * Guide 정렬
   */
  sort = async (
    params: {
      guideIds: number[]
    } & RequestDataBase,
  ): Promise<void> => {
    const { guideIds, ...rest } = params
    const url = '/admin/api/guide/update-sort'
    await this.helper.post(url, { guideIds: guideIds.join(',') }, rest)
  }

  /**
   * Guide 삭제
   */
  delete = async (
    params: {
      guideId: number
    } & RequestDataBase,
  ): Promise<void> => {
    const url = '/admin/api/guide/delete'
    await this.helper.post(url, ...splitParams(params))
  }

  /**
   * 첨부파일 업로드
   */
  uploadAttachFile = (
    params: {
      file: File
    } & RequestDataBase,
  ): Promise<{ file: UploadedFile }> => {
    const url = '/admin/api/guide/upload-attach-file'
    const { ctx, file } = params
    const formData = new FormData()
    formData.append('file', file, file.name) // formData.append('file', file)
    console.log('uploading file:', { name: file.name, size: file.size, type: file.type })
    return this.withData.postMultipart(url, formData, { ctx })
  }
}
