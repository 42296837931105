import { ApiHelper, ErrorResponseInterceptor, ResponseInterceptor } from '../api'
import log from '../log'
import { AllianceInqApi } from './user/alliance-inq'
import { AuthApi } from './user/auth-api'
import { BbsApi } from './user/bbs-api'
import { BlockApi } from './user/block-api'
import { BlockEduApi } from './user/block-edu-api'
import { BlockExamApi } from './user/block-exam-api'
import { BrowserLogApi } from './user/browser-log-api'
import { ChangeHistoryApi } from './user/change-history-api'
import { ClauseApi } from './user/clause-api'
import { CodingpackApi } from './user/codingpack-api'
import { CompanyCodingApi } from './user/company-coding-api'
import { DSModelKMeansApi } from './user/ds-model-kmeans-api'
import { DSModelKnnImageClassifyApi } from './user/ds-model-knn-image-classify-api'
import { DSModelKnnTextClassifyApi } from './user/ds-model-knn-text-classify-api'
import { DSModelLogisticRegressApi } from './user/ds-model-logistic-regress-api'
import { DSModelMultiRegressApi } from './user/ds-model-multi-regress-api'
import { DSModelSimpleRegressApi } from './user/ds-model-simple-regress-api'
import { DSSampleGridApi } from './user/ds-sample-grid-api'
import { DSSampleTextApi } from './user/ds-sample-text-api'
import { DSUserGridApi } from './user/ds-user-grid-api'
import { DSUserModelApi } from './user/ds-user-model-api'
import { DSUserTextApi } from './user/ds-user-text-api'
import { DSWordCloudApi } from './user/ds-word-cloud-api'
import { EduResourceApi } from './user/edu-resource-api'
import { EventApi } from './user/event-api'
import { FaqApi } from './user/faq-api'
import { GalleryApi } from './user/gallery-api'
import { GanApi } from './user/gan-api'
import { GinsideApi } from './user/ginside-api'
import { GuideApi } from './user/guide-api'
import { HwDriverApi } from './user/hw-driver-api'
import { I18nApi } from './user/i18n-api'
import { KmcisApi } from './user/kmcis-api'
import { LessonApi } from './user/lesson-api'
import { LessonForStudentApi } from './user/lesson-for-student-api'
import { LessonProjectApi } from './user/lesson-project-api'
import { MakerSoundApi } from './user/maker-sound-api'
import { ObjectImageApi } from './user/object-image-api'
import { PartnerAccessApi } from './user/partner-access-api'
import { PdsApi } from './user/pds-api'
import { ProfileApi } from './user/profile-api'
import { PwApi } from './user/pw-api'
import { QuickDrawApi } from './user/quick-draw-api'
import { SimulApi } from './user/simul-api'
import { SimulButtonApi } from './user/simul-button-api'
import { SimulCompositeBlockApi } from './user/simul-composite-block-api'
import { SimulFileUploadApi } from './user/simul-file-upload-api'
import { SimulFlyoutItemApi } from './user/simul-flyout-item-api'
import { SimulLabelApi } from './user/simul-label-api'
import { SimulPlaygroundApi } from './user/simul-playground-api'
import { SimulPubApi } from './user/simul-pub-api'
import { SimulUnitBlockApi } from './user/simul-unit-block-api'
import { SsoApi } from './user/sso-api'
import { TextClassificationApi } from './user/text-classification-api'
import { UserDatasetApi } from './user/user-dataset-api'
import { UserExApiApi } from './user/user-ex-api-api'
import { UserProjectApi } from './user/user-project-api'
import { UserWithdrawApi } from './user/user-withdraw-api'
import { WebPropApi } from './user/web-prop-api'

export class UserMainApi {
  helper: ApiHelper
  auth: AuthApi
  profile: ProfileApi
  pw: PwApi
  bbs: BbsApi
  allianceInq: AllianceInqApi
  faq: FaqApi
  i18n: I18nApi
  block: BlockApi
  clause: ClauseApi
  dsSampleGrid: DSSampleGridApi
  dsSampleText: DSSampleTextApi
  dsUserGrid: DSUserGridApi
  dsUserText: DSUserTextApi
  dsMultiRegress: DSModelMultiRegressApi
  dsLogisticRegress: DSModelLogisticRegressApi
  dsSimpleRegress: DSModelSimpleRegressApi
  dsUserModel: DSUserModelApi
  dsWordCloud: DSWordCloudApi
  dsKnnTextClassify: DSModelKnnTextClassifyApi
  dsKnnImageClassify: DSModelKnnImageClassifyApi
  dsKMeans: DSModelKMeansApi
  userDataset: UserDatasetApi
  userExApi: UserExApiApi
  blockExam: BlockExamApi
  blockEdu: BlockEduApi
  objectImage: ObjectImageApi
  makerSound: MakerSoundApi
  userProject: UserProjectApi
  gallery: GalleryApi
  ginside: GinsideApi
  changeHistory: ChangeHistoryApi
  companyCoding: CompanyCodingApi
  eduResource: EduResourceApi
  partnerAccess: PartnerAccessApi
  browserLog: BrowserLogApi
  codingpack: CodingpackApi
  simul: SimulApi
  simulButton: SimulButtonApi
  simulCompositeBlock: SimulCompositeBlockApi
  simulFileUpload: SimulFileUploadApi
  simulLabel: SimulLabelApi
  simulPub: SimulPubApi
  simulUnitBlock: SimulUnitBlockApi
  simulFlyoutItem: SimulFlyoutItemApi
  simulPlayground: SimulPlaygroundApi
  lesson: LessonApi
  lessonForStudent: LessonForStudentApi
  lessonProject: LessonProjectApi
  pds: PdsApi
  textClassification: TextClassificationApi
  gan: GanApi
  userWithdraw: UserWithdrawApi
  event: EventApi
  quickDraw: QuickDrawApi
  kmcis: KmcisApi
  hwDriver: HwDriverApi
  sso: SsoApi
  webProp: WebPropApi
  guide: GuideApi

  constructor(
    public apiBaseURL: string,
    createApiHeader: () => Record<string, string>,
    responseInterceptor: ResponseInterceptor,
    errorResponseInterceptor: ErrorResponseInterceptor,
    debug = false,
  ) {
    log.debug('create Api for ', apiBaseURL)

    // create helper
    this.helper = new ApiHelper(
      apiBaseURL,
      createApiHeader,
      responseInterceptor,
      errorResponseInterceptor,
      debug,
    )

    // api list
    this.auth = new AuthApi(this.helper)
    this.profile = new ProfileApi(this.helper)
    this.pw = new PwApi(this.helper)
    this.bbs = new BbsApi(this.helper)
    this.allianceInq = new AllianceInqApi(this.helper)
    this.faq = new FaqApi(this.helper)
    this.i18n = new I18nApi(this.helper)
    this.block = new BlockApi(this.helper)
    this.clause = new ClauseApi(this.helper)
    this.dsSampleGrid = new DSSampleGridApi(this.helper)
    this.dsSampleText = new DSSampleTextApi(this.helper)
    this.dsUserGrid = new DSUserGridApi(this.helper)
    this.dsUserText = new DSUserTextApi(this.helper)
    this.dsMultiRegress = new DSModelMultiRegressApi(this.helper)
    this.dsLogisticRegress = new DSModelLogisticRegressApi(this.helper)
    this.dsSimpleRegress = new DSModelSimpleRegressApi(this.helper)
    this.dsUserModel = new DSUserModelApi(this.helper)
    this.dsWordCloud = new DSWordCloudApi(this.helper)
    this.dsKnnTextClassify = new DSModelKnnTextClassifyApi(this.helper)
    this.dsKnnImageClassify = new DSModelKnnImageClassifyApi(this.helper)
    this.dsKMeans = new DSModelKMeansApi(this.helper)
    this.userDataset = new UserDatasetApi(this.helper)
    this.userExApi = new UserExApiApi(this.helper)
    this.blockExam = new BlockExamApi(this.helper)
    this.blockEdu = new BlockEduApi(this.helper)
    this.objectImage = new ObjectImageApi(this.helper)
    this.makerSound = new MakerSoundApi(this.helper)
    this.userProject = new UserProjectApi(this.helper)
    this.gallery = new GalleryApi(this.helper)
    this.ginside = new GinsideApi(this.helper)
    this.changeHistory = new ChangeHistoryApi(this.helper)
    this.companyCoding = new CompanyCodingApi(this.helper)
    this.eduResource = new EduResourceApi(this.helper)
    this.partnerAccess = new PartnerAccessApi(this.helper)
    this.browserLog = new BrowserLogApi(this.helper)
    this.codingpack = new CodingpackApi(this.helper)

    this.simul = new SimulApi(this.helper)
    this.simulButton = new SimulButtonApi(this.helper)
    this.simulCompositeBlock = new SimulCompositeBlockApi(this.helper)
    this.simulFileUpload = new SimulFileUploadApi(this.helper)
    this.simulLabel = new SimulLabelApi(this.helper)
    this.simulPub = new SimulPubApi(this.helper)
    this.simulUnitBlock = new SimulUnitBlockApi(this.helper)
    this.simulFlyoutItem = new SimulFlyoutItemApi(this.helper)
    this.simulPlayground = new SimulPlaygroundApi(this.helper)
    this.lesson = new LessonApi(this.helper)
    this.lessonForStudent = new LessonForStudentApi(this.helper)
    this.lessonProject = new LessonProjectApi(this.helper)
    this.pds = new PdsApi(this.helper)
    this.textClassification = new TextClassificationApi(this.helper)
    this.gan = new GanApi(this.helper)
    this.userWithdraw = new UserWithdrawApi(this.helper)
    this.event = new EventApi(this.helper)
    this.quickDraw = new QuickDrawApi(this.helper)
    this.kmcis = new KmcisApi(this.helper)
    this.hwDriver = new HwDriverApi(this.helper)
    this.sso = new SsoApi(this.helper)
    this.webProp = new WebPropApi(this.helper)
    this.guide = new GuideApi(this.helper)
  }
}
