import UserGradeIcon from '@/components/UserGradeIcon'
import handleError from '@/lib/handle-error'
import { routerUrlOf } from '@/lib/urls'
import { useApi } from '@/provider'
import {
  ApiRequestContext,
  scoreToUserGrade,
  StudentGrade,
  UserAccountForAdmin,
  UserGradeType,
} from '@aimk/domain'
import CloseIcon from '@mui/icons-material/Close'
import EditIcon from '@mui/icons-material/Edit'
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import { rootSx } from './style'

export type ProfileViewDialogProps = {
  accountId: number
  open: boolean
  onClose: () => void
}

const MAX_INPUT_VALUE = 40

function ProfileViewDialog(props: ProfileViewDialogProps) {
  const { accountId, open, onClose } = props
  const [loading, setLoading] = useState(false)

  const [saving, setSaving] = useState(false)
  const [profile, setProfile] = useState<UserAccountForAdmin>()
  const [editInput, setEditInput] = useState<string | undefined>()
  const [editing, setEditing] = useState(false)

  const api = useApi()

  const _onClickEdit = () => {
    setEditing(true)
    setEditInput(profile?.mood)
  }

  const _onChangeInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target
    if (value.length > MAX_INPUT_VALUE) {
      return
    }
    setEditInput(value)
  }
  const reload = useCallback(
    async (ctx: ApiRequestContext, accountId: number) => {
      setLoading(true)
      try {
        const { userInfo } = await api.user.userInfo({ ctx, accountId })
        if (ctx.canceled) return
        setProfile(userInfo)
      } catch (err) {
        handleError(err)
      } finally {
        setLoading(false)
      }
    },
    [api],
  )

  useEffect(() => {
    const ctx: ApiRequestContext = { canceled: false }
    reload(ctx, accountId)
    return () => {
      ctx.canceled = true
      ctx.cancel?.()
    }
  }, [reload, accountId])

  const doSave = useCallback(async (_mood: string, _accountId: number) => {
    // setSaving(true)
    // try {
    //     const { userProfile } = await api.profile.updateMoodByAdmin({ mood, accountId })
    //     setProfile(userProfile)
    //     setEditing(false)
    // } catch (err: any) {
    //     handleError(err)
    // } finally {
    //     setSaving(false)
    // }
  }, [])

  const _onClickSave = () => {
    doSave(editInput ?? '', accountId)
  }

  const userScore = profile?.score ?? 0
  const userGradeName = UserGradeType[scoreToUserGrade(userScore)] ?? 'AI 스타터'
  const profileImageUrl =
    profile?.profileImageUrl ?? routerUrlOf('/images/user/user_profile_default_1.svg')
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="ProfileViewDialog-root"
      sx={rootSx}
      aria-labelledby="profile-view-dialog-title"
      aria-describedby="profile-view-dialog-description"
    >
      <Box className="ProfileViewDialog-dialogTitle">
        <Box display="flex" alignItems="center">
          <UserGradeIcon score={userScore} size="large" />
          <Box ml={1} mt={1}>
            <Typography variant="h6">{userGradeName}</Typography>
            {userScore > 0 && (
              <span className="ProfileViewDialog-score">{userScore.toLocaleString()} P</span>
            )}
          </Box>
        </Box>

        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent dividers>
        {profile && (
          <div className="ProfileViewDialog-dialogContent">
            {!loading && (
              <>
                <Typography variant="h5" className="ProfileViewDialog-profileTitle">
                  {profile?.userId ?? '이름없음'}
                </Typography>
                <div className="ProfileViewDialog-profileImage">
                  <img src={profileImageUrl} alt="" />
                </div>
                {profile.studentGrade !== 'NORMAL' && (
                  <Typography variant="caption" className="ProfileViewDialog-studentGrade">
                    {StudentGrade[profile.studentGrade]}
                  </Typography>
                )}
              </>
            )}

            {!editing && (
              <Typography
                variant={profile.mood ? 'body2' : 'caption'}
                className="ProfileViewDialog-mood"
              >
                {profile.mood || '소개글이 없습니다.'}
              </Typography>
            )}

            {editing && (
              <Box>
                <Box mt={2} display="flex" alignItems="center" width="100%" px={1}>
                  <TextField
                    fullWidth
                    style={{ flexGrow: 1 }}
                    value={editInput ?? ''}
                    disabled={saving}
                    size="small"
                    onChange={(e) => _onChangeInput(e)}
                  />

                  <IconButton onClick={() => setEditing(false)} size="small">
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>
                <Button
                  onClick={_onClickSave}
                  variant="outlined"
                  style={{ marginTop: 8 }}
                  color="primary"
                  size="small"
                >
                  저장
                </Button>
              </Box>
            )}

            {!editing && (
              <IconButton onClick={_onClickEdit} size="small">
                <EditIcon fontSize="small" />
              </IconButton>
            )}
          </div>
        )}
        {(loading || saving) && (
          <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: 30 }}>
            <LinearProgress color="secondary" />
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}
export default ProfileViewDialog
