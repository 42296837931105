import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'

/**
 * TextClassification Api
 */
export class TextClassificationApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  features = (
    params: { text: string[] } & RequestDataBase,
  ): Promise<{ features: Array<number[]> }> => {
    const url = '/p/api/ai-text-features'
    const { ctx, text } = params

    return this.withData.postJson(url, { text }, { ctx })
  }
}
