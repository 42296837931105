import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { BardAskResult } from '../../model'

/**
 * Bard Api
 */
export class BardApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  ask = (
    params: {
      message: string
    } & RequestDataBase,
  ): Promise<{ result: BardAskResult }> => {
    // TODO 임시로 오랩 서버 호출
    // const url = '/api/bard-proxy/ask'
    const url = 'https://aimk.jjfive.net/aimk-ai-api/api/bard-proxy/ask'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
