import { GanStyleTransferTypeKey } from '../../ai-train-types'
import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'

/**
 * Gan Api
 */
export class GanApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  styleTransfer = (
    params: {
      imageDataURL: string
      style: GanStyleTransferTypeKey
    } & RequestDataBase,
  ): Promise<{ imageDataURL: string }> => {
    const url = '/p/api/ai-style-transfer'
    const { ctx, imageDataURL, style } = params

    return this.withData.postJson(url, { imageDataURL, style }, { ctx })
  }

  selfie2anime = (
    params: {
      imageDataURL: string
    } & RequestDataBase,
  ): Promise<{ imageDataURL: string }> => {
    const url = '/p/api/ai-selfie2anime'
    const { ctx, imageDataURL } = params

    return this.withData.postJson(url, { imageDataURL }, { ctx })
  }
}
