import { LOGOUT_PAGE } from '@/constants'
import log from '@/log'
import { ApiCanceledError, errorToMessage, isLoginError, isNetworkError } from '@aimk/domain'
import { toast, ToastOptions } from 'react-toastify'
import { routerPush } from './urls'

/**
 * 에러를 사용자에게 표시할 메시지로 만들어서 리턴
 *
 * @param { Error | Object } err 에러 객체
 * @param { boolean } ignoreCanceled E1_CANCELED 에러는 무시할지 여부
 * @returns { string | undefined } 에러 메시지
 */
export const errorMessage = (err: any, ignoreCanceled = true) => {
  if (err instanceof ApiCanceledError || err?.errorCode === 'E1_CANCELED') {
    return ignoreCanceled ? undefined : '요청이 취소되었습니다'
  }

  if (isLoginError(err)) {
    return '로그인이 필요합니다'
  }

  if (isNetworkError(err)) {
    return '네트워크 오류가 발생했습니다'
  }

  const errorCode = err.errorCode

  if (errorCode && typeof errorCode === 'string') {
    if (errorCode === 'E1_HTTP_404') {
      return '해당 주소를 찾을 수가 없습니다'
    }
    if (errorCode.startsWith('E1_HTTP_5')) {
      return '서버 오류가 발생했습니다'
    }

    return errorToMessage(errorCode)
  }

  const msg = err.errorMessage ?? err.message
  return msg ?? 'unknown error'
}

const defaultToastOptions: ToastOptions = {
  autoClose: 3500,
  position: toast.POSITION.TOP_CENTER,
}

/**
 * 에러 처리 함수
 *
 * @param { Error | Object } err
 */
const handleError = (err: any) => {
  log.info('ERROR=', { ...err })

  if (err instanceof ApiCanceledError || err?.errorCode === 'E1_CANCELED') {
    return
  }

  if (isLoginError(err)) {
    toast.warn('로그인이 필요합니다', defaultToastOptions)
    routerPush(LOGOUT_PAGE)
    return
  }

  if (isNetworkError(err)) {
    toast.warn('네트워크 오류가 발생했습니다', defaultToastOptions)
    return
  }

  if (err?.errorCode === 'E1_HTTP_404') {
    toast.warn('해당 주소를 찾을 수가 없습니다', defaultToastOptions)
    return
  }

  if (err.errorCode) {
    toast.warn(errorToMessage(err.errorCode), defaultToastOptions)
    return
  }

  const msg = err.errorMessage ?? err.message
  toast.warn(msg ?? 'unknown error', defaultToastOptions)
}

export default handleError
