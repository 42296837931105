import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'
import { ExApiResponseTypeKey } from '../../enum-types'
import { UserExApi } from '../../model'

/**
 * 사용자 외부 API Api
 */
export class UserExApiApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 사용자 외부 API 한건 조회
   */
  info = (
    params: {
      apiId: string
    } & RequestDataBase,
  ): Promise<{ exApi: UserExApi }> => {
    const { apiId, ctx } = params
    const url = `/p/api/user-ex-api/info/${apiId}`

    return this.withData.postJsonAndSecuredResponse(url, undefined, { ctx })
  }

  /**
   * 사용자 외부 API 목록 조회
   */
  list = (params: RequestDataBase): Promise<{ exApiList: UserExApi[] }> => {
    const { ctx } = params
    const url = '/api/user-ex-api/list'

    return this.withData.postJsonAndSecuredResponse(url, undefined, { ctx })
  }

  /**
   * 사용자 외부 API 생성
   */
  create = (
    params: {
      apiName: string
      url: string
      responseType: ExApiResponseTypeKey
    } & RequestDataBase,
  ): Promise<{ exApi: UserExApi }> => {
    const { apiName, url, responseType, ctx } = params
    const _url = '/api/user-ex-api/create'

    return this.withData.postJsonAndSecuredResponse(_url, { apiName, url, responseType }, { ctx })
  }

  /**
   * 사용자 외부 API 업데이트
   */
  update = (
    params: {
      apiId: string
      apiName: string
      url: string
      responseType: ExApiResponseTypeKey
    } & RequestDataBase,
  ): Promise<{ exApi: UserExApi }> => {
    const { apiId, apiName, url, responseType, ctx } = params
    const _url = `/api/user-ex-api/update/${apiId}`

    return this.withData.postJsonAndSecuredResponse(_url, { apiName, url, responseType }, { ctx })
  }

  /**
   * 사용자 외부 API 삭제
   */
  delete = async (
    params: {
      apiId: string
    } & RequestDataBase,
  ): Promise<void> => {
    const { ctx, apiId } = params
    const url = `/api/user-ex-api/delete/${apiId}`

    await this.helper.post(url, undefined, { ctx })
  }
}
