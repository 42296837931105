import { GanStyleTransferTypeKey } from '../../ai-train-types'
import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'

/**
 * Gan Api
 */
export class GanApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * @deprecated WAS로 API 변경
   */
  styleTransfer = (
    params: {
      imageDataURL: string
      style: GanStyleTransferTypeKey
    } & RequestDataBase,
  ): Promise<{ imageDataURL: string }> => {
    const url = '/p/ai-api/v1/gan/style-transfer'
    const { ctx, imageDataURL, style } = params

    return this.withData.postJson(url, { imageDataURL, style }, { ctx })
  }

  /**
   * @deprecated WAS로 API 변경
   */
  selfie2anime = (
    params: {
      imageDataURL: string
    } & RequestDataBase,
  ): Promise<{ imageDataURL: string }> => {
    const url = '/p/ai-api/v1/gan/selfie2anime'
    const { ctx, imageDataURL } = params

    return this.withData.postJson(url, { imageDataURL }, { ctx })
  }
}
