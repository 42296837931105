import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'
import { UploadedFile } from '../../model'

/**
 * PubSimul Api
 */
export class SimulFileUploadApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  uploadTempItemImage = (
    params: {
      file: File
    } & RequestDataBase,
  ): Promise<{ file: UploadedFile }> => {
    const { file, ctx } = params
    const url = '/api/simul-upload/item-image'
    const formData = new FormData()
    formData.append('file', file, file.name)
    return this.withData.postMultipart(url, formData, { ctx })
  }

  uploadTempBrandIcon = (
    params: {
      file: File
    } & RequestDataBase,
  ): Promise<{ file: UploadedFile }> => {
    const { file, ctx } = params
    const url = '/api/simul-upload/brand-icon'

    const formData = new FormData()
    formData.append('file', file, file.name)
    return this.withData.postMultipart(url, formData, { ctx })
  }

  uploadTempCateIcon = (
    params: {
      file: File
    } & RequestDataBase,
  ): Promise<{ file: UploadedFile }> => {
    const { file, ctx } = params
    const url = '/api/simul-upload/cate-icon'

    const formData = new FormData()
    formData.append('file', file, file.name)
    return this.withData.postMultipart(url, formData, { ctx })
  }
}
