import {
  ApiHelper,
  ApiHelperWithData,
  PagingRequest,
  RequestDataBase,
  WasUrlHelper,
  splitParams,
} from '../../api'
import { AimkEventFieldKindKey, AimkEventFilePropKey } from '../../enum-types'
import {
  AimkEventApplicantForAdmin,
  AimkEventApplyField,
  AimkEventDaily,
  AimkEventForAdmin,
  AimkEventSimpleForAdmin,
  Clause,
  PagerData,
  UploadedFile,
} from '../../model'

/**
 * 관리자용 Event Api
 */
export class EventApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 이벤트 신규 등록
   */
  create = async (
    params: RequestDataBase,
  ): Promise<{
    event: AimkEventSimpleForAdmin
  }> => {
    const url = '/admin/api/event/create'
    const response: {
      event: AimkEventSimpleForAdmin
    } = await this.withData.post(url, ...splitParams(params))

    WasUrlHelper.replaceRelative(response.event, 'applicantsUrl')
    return response
  }

  /**
   * 이벤트 기본 정보 업데이트
   */
  updateBasicInfo = async (
    params: {
      eventId: number
      title: string
      slogan: string
      guideTitle: string
      guideDesc: string
      prizeTitle: string
      prizeDesc: string
      notice: string
      primaryColor: string
      primaryDarkColor: string
      primaryLightColor: string
      textPrimaryColor: string
      textSecondaryColor: string
      iconFileId?: string
      topBanner1FileId?: string
      topBanner2FileId?: string
      guideImageFileId?: string
      prizeImageFileId?: string
    } & RequestDataBase,
  ): Promise<{
    event: AimkEventForAdmin
    applyFields: AimkEventApplyField[]
  }> => {
    const { eventId, ...rest } = params
    const url = `/admin/api/event/info/update/${eventId}`
    const response: {
      event: AimkEventForAdmin
      applyFields: AimkEventApplyField[]
    } = await this.withData.postJson(url, ...splitParams(rest))

    WasUrlHelper.replaceRelative(response.event, 'applicantsUrl')
    return response
  }

  /**
   * 이벤트 약관 업데이트
   */
  updateClauses = async (
    params: {
      eventId: number
      clauseIds: number[]
    } & RequestDataBase,
  ): Promise<{
    event: AimkEventForAdmin
    applyFields: AimkEventApplyField[]
    clauseList: Clause[]
  }> => {
    const { eventId, clauseIds, ...rest } = params
    const url = `/admin/api/event/clause/update/${eventId}`

    const response: {
      event: AimkEventForAdmin
      applyFields: AimkEventApplyField[]
      clauseList: Clause[]
    } = await this.withData.post(url, ...splitParams({ ...rest, clauseIds: clauseIds.join(',') }))

    WasUrlHelper.replaceRelative(response.event, 'applicantsUrl')
    return response
  }

  /**
   * 이벤트 시간 업데이트
   */
  updateTimestamps = async (
    params: {
      eventId: number
      publishTimestamp: number
      applyStartTimestamp: number
      applyFinishTimestamp: number
      winnerAnnounceTimestamp: number
      applyEditFinishTimestamp: number
      unpublishTimestamp: number
      privacyDueTimestamp: number
    } & RequestDataBase,
  ): Promise<{
    event: AimkEventForAdmin
    applyFields: AimkEventApplyField[]
  }> => {
    const { eventId, ...rest } = params
    const url = `/admin/api/event/timestamp/update/${eventId}`

    const response: {
      event: AimkEventForAdmin
      applyFields: AimkEventApplyField[]
    } = await this.withData.postJson(url, ...splitParams(rest))
    WasUrlHelper.replaceRelative(response.event, 'applicantsUrl')
    return response
  }

  /**
   * 이벤트 응모 양식 저장
   * 신규 또는 업데이트
   */
  updateApplyFields = async (
    params: {
      eventId: number
      applyFormHelp?: string
      fields: Array<{
        fieldId: number
        fieldKind: AimkEventFieldKindKey
        inputLabel: string
        title?: string
        substance?: string
        maxLength: number
        required: boolean
      }>
    } & RequestDataBase,
  ): Promise<{
    event: AimkEventForAdmin
    applyFields: AimkEventApplyField[]
  }> => {
    const { eventId, ...rest } = params
    const url = `/admin/api/event/apply-field/update/${eventId}`
    const response: {
      event: AimkEventForAdmin
      applyFields: AimkEventApplyField[]
    } = await this.withData.postJson(url, ...splitParams(rest))

    WasUrlHelper.replaceRelative(response.event, 'applicantsUrl')
    return response
  }

  /**
   * 이벤트 전체 응모자 삭제
   */
  deleteAllApplicants = async (
    params: {
      eventId: number
    } & RequestDataBase,
  ): Promise<{
    event: AimkEventForAdmin
    applyFields: AimkEventApplyField[]
  }> => {
    const url = '/admin/api/event/applicant/delete-all'

    const response: {
      event: AimkEventForAdmin
      applyFields: AimkEventApplyField[]
    } = await this.withData.post(url, ...splitParams(params))

    WasUrlHelper.replaceRelative(response.event, 'applicantsUrl')

    return response
  }

  /**
   * 이벤트 특정 응모자 삭제
   */
  deleteApplicant = async (
    params: {
      applicantId: number
    } & RequestDataBase,
  ): Promise<void> => {
    const { applicantId, ...rest } = params
    const url = `/admin/api/event/applicant/delete/${applicantId}`

    await this.helper.post(url, ...splitParams(rest))
  }

  /**
   * 이벤트 활성화
   */
  enable = async (
    params: {
      eventId: number
    } & RequestDataBase,
  ): Promise<{
    event: AimkEventForAdmin
    applyFields: AimkEventApplyField[]
  }> => {
    const { eventId, ...rest } = params
    const url = `/admin/api/event/state/enable/${eventId}`
    const response: {
      event: AimkEventForAdmin
      applyFields: AimkEventApplyField[]
    } = await this.withData.post(url, ...splitParams(rest))

    WasUrlHelper.replaceRelative(response.event, 'applicantsUrl')
    return response
  }

  /**
   * 이벤트 비활성화
   */
  disable = async (
    params: {
      eventId: number
    } & RequestDataBase,
  ): Promise<{
    event: AimkEventForAdmin
    applyFields: AimkEventApplyField[]
  }> => {
    const { eventId, ...rest } = params
    const url = `/admin/api/event/state/disable/${eventId}`
    const response: {
      event: AimkEventForAdmin
      applyFields: AimkEventApplyField[]
    } = await this.withData.post(url, ...splitParams(rest))

    WasUrlHelper.replaceRelative(response.event, 'applicantsUrl')
    return response
  }

  /**
   * 이벤트 삭제
   */
  delete = async (
    params: {
      eventId: number
    } & RequestDataBase,
  ): Promise<void> => {
    const { eventId, ...rest } = params
    const url = `/admin/api/event/delete/${eventId}`
    await this.helper.post(url, ...splitParams(rest))
  }

  /**
   * 이벤트 결과 등록
   */
  saveResult = async (
    params: {
      eventId: number
      eventResult?: string
    } & RequestDataBase,
  ): Promise<{
    event: AimkEventForAdmin
    applyFields: AimkEventApplyField[]
  }> => {
    const { eventId, ...rest } = params
    const url = `/admin/api/event/result/save/${eventId}`
    const response: {
      event: AimkEventForAdmin
      applyFields: AimkEventApplyField[]
    } = await this.withData.post(url, ...splitParams(rest))

    WasUrlHelper.replaceRelative(response.event, 'applicantsUrl')

    return response
  }

  uploadTempFile = async (
    params: {
      fileName: string
      file: Blob
      prop: AimkEventFilePropKey
    } & RequestDataBase,
  ): Promise<{ file: UploadedFile }> => {
    const { file, fileName, prop, ctx } = params
    const url = '/admin/api/event/upload'
    const formData = new FormData()
    formData.append('file', file, fileName)
    formData.append('prop', prop)
    const response: { file: UploadedFile } = await this.withData.postMultipart(url, formData, {
      ctx,
    })

    WasUrlHelper.replaceRelative(response.file, 'downloadUrl')
    return response
  }

  /**
   * 이벤트 목록 조회
   */
  list = async (
    params: {
      withDisabled: boolean
      accountId?: number
      onlyForAccount?: boolean
    } & PagingRequest,
  ): Promise<{ pagerData: PagerData<AimkEventSimpleForAdmin> }> => {
    const { ctx, ...rest } = params
    const url = '/admin/api/event/list'

    const response: { pagerData: PagerData<AimkEventSimpleForAdmin> } =
      await this.withData.postJson(url, ...splitParams(rest))

    response.pagerData.elements?.forEach((item) => {
      WasUrlHelper.replaceRelative(item, 'applicantsUrl')
    })
    return response
  }

  /**
   * 이벤트 정보 조회
   */
  info = async (
    params: {
      eventId: number
      accountId?: number
    } & RequestDataBase,
  ): Promise<{
    event: AimkEventForAdmin
    applyFields: AimkEventApplyField[]
    clauseList: Clause[]
  }> => {
    const { eventId, ...rest } = params
    const url = `/admin/api/event/info/${eventId}`
    const response: {
      event: AimkEventForAdmin
      applyFields: AimkEventApplyField[]
      clauseList: Clause[]
    } = await this.withData.post(url, ...splitParams(rest))

    WasUrlHelper.replaceRelative(response.event, 'applicantsUrl')
    return response
  }

  /**
   * 이벤트 응모자 조회
   */
  applicantList = (
    params: {
      eventId: number
      userId?: string
    } & PagingRequest,
  ): Promise<{
    pagerData: PagerData<AimkEventApplicantForAdmin>
    applyFields: AimkEventApplyField[]
  }> => {
    const url = '/admin/api/event/applicant/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 이벤트 일간 통계
   */
  dailyList = (
    params: {
      eventId: number
    } & RequestDataBase,
  ): Promise<{
    dailyList: AimkEventDaily[]
  }> => {
    const { eventId, ...rest } = params
    const url = `/admin/api/event/daily/list/${eventId}`
    return this.withData.post(url, ...splitParams(rest))
  }

  /**
   * 이벤트 응모 파일 생성
   */
  forceGenerateApplFile = async (
    params: {
      eventId: number
    } & RequestDataBase,
  ): Promise<void> => {
    const url = '/admin/api/event/appl-file-gen-force'
    await this.helper.post(url, ...splitParams(params))
  }

  /**
   * 이벤트 응모 파일 다운로드를 위한 임시 링크 생성
   * 응모파일에는 개인정보가 있으므로,
   * 임시 링크를 생성하려면, unmaskReason을 입력해야 한다.
   */
  createApplicantsFileLink = (
    params: {
      eventId: number
      unmaskReason: string
    } & RequestDataBase,
  ): Promise<{ downloadUrl: string }> => {
    const url = '/admin/api/event/create-applicants-file-link'
    return this.withData.post(url, ...splitParams(params))
  }
}
