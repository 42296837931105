import {
  ApiHelper,
  ApiHelperWithData,
  PagingRequest,
  RequestDataBase,
  splitParams,
} from '../../api'
import { PagerData, UserWithdraw } from '../../model'

/**
 * UserWithdraw Api
 */
export class UserWithdrawApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 회원탈퇴 등록
   */
  withdraw = (params: { accountId: number; reason: string } & RequestDataBase): Promise<void> => {
    const { accountId, ...rest } = params
    const url = `/admin/api/user-withdraw/${accountId}`
    return this.withData.postJson(url, ...splitParams(rest))
  }

  /**
   * 탈퇴 회원 목록
   */
  list = (
    params: { userId?: string } & PagingRequest,
  ): Promise<{ pagerData: PagerData<UserWithdraw> }> => {
    const url = '/admin/api/user-withdraw/list-all'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
