import hmacSHA256 from 'crypto-js/hmac-sha256'
import AES from 'crypto-js/aes'
import md5 from 'crypto-js/md5'
import utf8enc from 'crypto-js/enc-utf8'

const secret = '826D96C90030DD58429D2751AC1BDBBC'

// dummy txId
function nextDummyTxId() {
  return Math.random().toString(36).substring(2) + Date.now()
}

const createSignature = (txId: string, timestamp: number): string => {
  const signature = hmacSHA256(`${txId},${timestamp}`, secret).toString()
  return signature
}

const enc = (plaintext: string): string => {
  const key = utf8enc.parse(secret)
  const iv = utf8enc.parse(secret.substring(0, 16))
  const encrypted = AES.encrypt(plaintext, key, {
    iv,
  })
  return encrypted.toString().toString()
}

const dec = (encoded: string): string => {
  const key = utf8enc.parse(secret)
  const iv = utf8enc.parse(secret.substring(0, 16))
  return AES.decrypt(encoded, key, { iv }).toString(utf8enc)
}

export type PayloadType = {
  signature: string
  timestamp: number
  txId: string
  data: string
}

class TxCipher {
  constructor() {}

  decrypt = (data: string): string => {
    return dec(data)
  }

  encrypt = (txId: string, payloadObject: any): PayloadType => {
    const timestamp = Date.now()
    const encryptedData = enc(
      JSON.stringify({
        ...payloadObject,
        __timestamp: timestamp,
      }),
    )
    const signature = createSignature(txId, timestamp)
    return {
      signature,
      timestamp,
      txId,
      data: encryptedData,
    }
  }

  createClientTx = (): { txId: string; timestamp: number; signature: string } => {
    const timestamp = Date.now()
    const txId = md5(Math.random().toString()).toString()
    const signature = createSignature(txId, timestamp)
    return {
      txId,
      timestamp,
      signature,
    }
  }

  /**
   * 랜덤 txId를 생성하여, 암호화
   * @param payloadObject
   * @returns
   */
  encryptObject = (payloadObject: any): PayloadType => {
    return this.encrypt(nextDummyTxId(), payloadObject)
  }

  createTxId = (): string => nextDummyTxId()

  validate = <T>(params: {
    signature: string //
    txId: string
    timestamp: number
    securedBody: string
  }): { success: boolean; body?: T } => {
    const { signature, txId, timestamp, securedBody } = params
    const sig = createSignature(txId, timestamp)
    if (sig !== signature) {
      return { success: false }
    }
    const diff = Math.abs(Date.now() - timestamp)
    if (diff > 5 * 60 * 1000) {
      return { success: false }
    }
    const { timestamp: t1, body } = JSON.parse(this.decrypt(securedBody)) as {
      timestamp: number
      body: any
    }
    if (t1 !== timestamp) {
      return { success: false }
    }
    return { success: true, body: body as T }
  }

  generateClientTxId = (): string => {
    return nextDummyTxId()
  }
}

export const txCipher = new TxCipher()
