export const TOP_BAR_HEIGHT = 64

export const mainLayoutConstants = {
  /**
   * 상단 Appbar 높이
   * 이건 하드코딩해도 되나 모르겠다
   */
  appbarHeight: 64,

  sidemenu: {
    /**
     * 왼쪽 메뉴 너비
     */
    width: 270,

    /**
     * 왼쪽 메뉴 배경색
     */
    bgColor: '#2C2E35',
    bgColorBack: 'rgba(0,0,0,0.2)',
    bgColorHoverBack: 'rgba(0,0,0,0.1)',
    fgColor: '#eee',
    fgColorActive: '#FDCC90',
    iconColor: '#626B8A',
    iconColorActive: '#FCAA44',
    iconWidth: 32,

    /**
     * 왼쪽 구분선 색
     */
    dividerColor: 'rgba(255,255,255,0.2)',

    /**
     * 왼쪽메뉴 글자 크기
     */
    fontSize: '0.85rem',
  },

  content: {
    /**
     * 레이아웃 컨텐트 배경색
     */
    bgColor: '#fafafa',

    /**
     * 레이아웃 컨텐트 아래쪽 패딩
     */
    defaultPaddingBottom: 80,
  },
}
