import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'
import { TtsGenderKey, TtsLangKey, TtsSpeedKey, TtsToneKey } from '../../block'

/**
 * TTS Api
 */
export class TtsApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  tts = (
    params: {
      text: string
      lang: TtsLangKey
      speed: TtsSpeedKey
      gender: TtsGenderKey
      tone: TtsToneKey
    } & RequestDataBase,
  ): Promise<{ audioUrl: string }> => {
    const url = '/p/ai-api/v1/tts/request'
    const { ctx, lang, speed, gender, tone, text } = params

    return this.withData.postJson(url, { lang, speed, gender, tone, text }, { ctx })
  }
}
