import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'

/**
 * Extern api call Api
 */
export class ExternApiCallApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  httpGet = (
    params: {
      url: string
    } & RequestDataBase,
  ): Promise<{
    result: string
  }> => {
    // TODO 임시로 오랩 서버 호출
    // const url = '/p/ai-api/v1/extern-api-call'
    const url = 'https://aimk.jjfive.net/aimk-ai-api/p/ai-api/v1/extern-api-call'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
