import {
  ApiHelper,
  ApiHelperWithData,
  ApiRequestContext,
  RequestDataBase,
  splitParams,
} from '../../api'
import { BlockEduLevelKey } from '../../enum-types'
import { BlkCategoryStyle, BlockEdu, UploadedFile } from '../../model'

/**
 * 학습 블록 Api
 */
export class BlockEduApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * BlockEdu 신규 저장
   */
  create = (
    params: {
      eduKey?: string
      eduLevel: BlockEduLevelKey
      cateId: string
      title: string
      intro: string
      substance?: string
      blockImageFileId: string
      blockXml: string
    } & RequestDataBase,
  ): Promise<{ blockEdu: BlockEdu }> => {
    const url = '/api/block-edu/create'

    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * BlockEdu 업데이트 저장
   */
  update = (
    params: {
      eduId: string
      eduKey?: string
      cateId: string
      eduLevel: BlockEduLevelKey
      title: string
      intro: string
      substance?: string
    } & RequestDataBase,
  ): Promise<{ blockEdu: BlockEdu }> => {
    const { eduId, ...rest } = params
    const url = `/api/block-edu/update/${eduId}`

    return this.withData.postJson(url, ...splitParams(rest))
  }

  /**
   * 사용안함
   * BlockEdu 제목 업데이트 저장
   */
  updateTitle = (
    params: {
      eduId: string
      title: string
    } & RequestDataBase,
  ): Promise<{ blockEdu: BlockEdu }> => {
    const { ctx, eduId, title } = params
    const url = `/api/block-edu/update-title/${eduId}`

    return this.withData.postJson(url, { title }, { ctx })
  }

  /**
   * 사용안함
   * BlockEdu 메모 업데이트 저장
   */
  updateMemo = (
    params: {
      eduId: string
      memo: string
    } & RequestDataBase,
  ): Promise<{ blockEdu: BlockEdu }> => {
    const { ctx, eduId, memo } = params
    const url = `/api/block-edu/update-memo/${eduId}`

    return this.withData.postJson(url, { memo }, { ctx })
  }

  /**
   * BlockEdu 카테고리 목록 조회
   * BlockEdu 카테고리는 블록 툴박스 아이템이다
   */
  cateList = (params: RequestDataBase): Promise<{ cateList: BlkCategoryStyle[] }> => {
    const url = '/p/api/block-edu/cate-list'
    const { ctx } = params

    return this.withData.postJson(url, undefined, { ctx })
  }

  /**
   * BlockEdu 목록 조회
   * 전체 조회시 빈 값 전달
   */
  list = (
    params: {
      eduLevel?: BlockEduLevelKey
      cateId?: string
    } & RequestDataBase,
  ): Promise<{
    blockEduList: BlockEdu[] // BlockEdu 목록 조회
  }> => {
    const url = '/p/api/block-edu/list'
    const { ctx, eduLevel, cateId } = params

    return this.withData.postJson(
      url,
      {
        eduLevel,
        cateId,
      },
      { ctx },
    )
  }

  info = (
    params: {
      ctx: ApiRequestContext
      eduId: string
    } & RequestDataBase,
  ): Promise<{
    blockEdu: BlockEdu
  }> => {
    const { eduId, ...rest } = params
    const url = `/p/api/block-edu/info/${eduId}`

    return this.withData.postJson(url, ...splitParams(rest))
  }

  infoByEduKey = (
    params: {
      ctx: ApiRequestContext
      eduKey: string
    } & RequestDataBase,
  ): Promise<{
    blockEdu: BlockEdu
  }> => {
    const url = '/p/api/block-edu/info-by-edu-key'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * BlockEdu 이미지 파일 업로드
   */
  uploadBlockImageFile = (
    params: {
      file: File
    } & RequestDataBase,
  ): Promise<{ file: UploadedFile }> => {
    const url = '/api/block-edu/image-upload'
    const { ctx, file } = params
    const formData = new FormData()
    formData.append('file', file, file.name) // formData.append('file', file)
    console.log('uploading file:', { name: file.name, size: file.size, type: file.type })

    return this.withData.postMultipart(url, formData, { ctx })
  }

  /**
   * BlockEdu 삭제
   */
  delete = async (
    params: {
      eduId: string
    } & RequestDataBase,
  ): Promise<void> => {
    const { ctx, eduId } = params
    const url = `/api/block-edu/delete/${eduId}`

    await this.helper.post(url, undefined, { ctx })
    return
  }
}
