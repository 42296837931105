import {
  ApiHelper,
  ApiHelperWithData,
  PagingRequest,
  RequestDataBase,
  splitParams,
} from '../../api'
import { UserKickReasonKey } from '../../enum-types'
import { PagerData, UserKick } from '../../model'

/**
 * UserKick Api
 */
export class UserKickApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 사용자 차단 추가
   */
  add = async (
    params: {
      accountId: number
      kickReason: UserKickReasonKey
      memo?: string
      kickMinute: number
    } & RequestDataBase,
  ): Promise<void> => {
    const url = '/admin/api/user-kick/add'
    await this.helper.postJson(url, ...splitParams(params))
  }

  /**
   * 사용자 차단 해제
   */
  cancel = async (params: { accountId: number } & RequestDataBase): Promise<void> => {
    const url = '/admin/api/user-kick/cancel'
    await this.helper.post(url, ...splitParams(params))
  }

  /**
   * 사용자 차단 삭제
   */
  delete = async (params: { kickId: number } & RequestDataBase): Promise<void> => {
    const { kickId, ...rest } = params
    const url = `/admin/api/user-kick/delete/${kickId}`
    await this.helper.post(url, ...splitParams(rest))
  }

  /**
   * 사용자 차단 이력 조회
   */
  list = (params: { accountId: number } & RequestDataBase): Promise<{ kickList: UserKick[] }> => {
    const url = '/admin/api/user-kick/list'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 전체 사용자 차단 이력 조회 - 페이징
   */
  listAll = (
    params: { userId?: string } & PagingRequest,
  ): Promise<{ pagerData: PagerData<UserKick> }> => {
    const url = '/admin/api/user-kick/list-all'

    return this.withData.postJson(url, ...splitParams(params))
  }
}
