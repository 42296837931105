import { I18nSectionTypeKey, I18nLocaleKey } from '@aimk/domain'
import { action, computed, makeObservable, observable } from 'mobx'
import { IntlLocaleKey } from '../types'

/**
 * 로케일 메시지를 보관하는 mobx 스토어
 */
const DEFAULT_LOCALE = 'ko'

/**
 * Intl store
 */
export default class IntlStore {
  constructor() {
    makeObservable(this)
  }

  /**
   * 메시지 리프레시 토큰
   */
  @observable refreshLoadToken = 0

  /**
   * 로케일 별 모든 메시지
   */
  @observable allMessages: Record<string, Record<I18nSectionTypeKey, Record<string, string>>> = {}

  // /**
  //  * 현재 로케일의 메시지 Map
  //  */
  // @observable currentMessages: Record<string, string> = {}

  /**
   * 블록리 현재 로케일의 메시지 Map
   */
  @observable currentMessagesBlockly: Record<string, string> = {}

  /**
   * 현재 로케일
   */
  @observable currentLocale: IntlLocaleKey = DEFAULT_LOCALE

  @observable lastLoadedTime = 0

  /**
   * 블록리 카테고리 이름 가져오기
   * @param cateId 블록 카테고리 ID
   * @returns 카테고리 이름
   */
  getBlkCateName = (cateId: string): string | undefined => {
    return this.currentMessagesBlockly[cateId.toUpperCase()]
  }

  /**
   * 로케일 변경
   */
  @action setLocale = async (locale: IntlLocaleKey) => {
    let currentMessages: Record<I18nSectionTypeKey, Record<string, string>> = this.allMessages[
      locale
    ]
    if (!currentMessages) {
      locale = DEFAULT_LOCALE
      currentMessages = this.allMessages[DEFAULT_LOCALE]
    }
    if (locale === 'ko') {
      // blockly에서 잘못된 부분 고친다
      // currentMessages['BLOCKLY']["TEXT_REPLACE_MESSAGE0"] = "%3에서 %2을(를) %1(으)로 바꾸기"
      currentMessages['BLOCKLY']['TEXT_REPLACE_MESSAGE0'] = '%3에서 %1을(를) %2(으)로 바꾸기'
      currentMessages['BLOCKLY']['LISTS_LENGTH_TITLE'] = '%1의 항목 수'
    }
    this.currentLocale = locale
    this.currentMessagesBlockly = currentMessages['BLOCKLY']

    this.lastLoadedTime = Date.now()
  }

  @computed get hasLoaded(): boolean {
    return this.lastLoadedTime > 0
  }

  @action setAllMessages = (
    allMessages: Record<I18nLocaleKey, Record<I18nSectionTypeKey, Record<string, string>>>,
  ) => {
    this.allMessages = allMessages
    this.setLocale(this.currentLocale)
  }

  /**
   * 전체 메시지 다시 로드 요청
   * IntlMessageLoader 컴포넌트에서 새로 고침한다
   */
  @action requestRefreshLoad = () => {
    this.refreshLoadToken = Date.now()
  }
}
