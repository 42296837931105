const cities: Record<string, string> = {
  '서울,서울시,서울특별시': 'SEOUL',
  '부산,부산시,부산광역시': 'BUSAN',
  '대구,대구시,대구광역시': 'DAEGU',
  '인천,인천시,인천광역시': 'INCHEON',
  '광주,광주시,광주광역시': 'KWANGJU',
  '대전,대전시,대전광역시': 'DAEJEON',
  '울산,울산시,울산광역시': 'ULSAN',
  '경기,경기도': 'GYEONGGI',
  '강원,강원도': 'GANGWON',
  '충북,충청북도': 'CHUNGBUK',
  '충남,충청남도': 'CHUNGNAM',
  '전북,전라북도': 'JEOLLABUK',
  '전남,전라남도': 'JEOLLANAM',
  '경북,경상북도': 'GYEONGBUK',
  '경남,경상남도': 'GYEONGNAM',
  '제주,제주도': 'JEJU',
  '세종,세종시': 'SEJONG',
}

export const KoreaCities = Object.entries(cities).reduce((acc, cur) => {
  const [cityNames, cityKey] = cur
  cityNames.split(',').forEach((cityName) => {
    acc[cityName] = cityKey
  })
  return acc
}, {} as Record<string, string>)

export type KoreaMajorCityKey =
  | 'SEOUL'
  | 'BUSAN'
  | 'DAEGU'
  | 'INCHEON'
  | 'KWANGJU'
  | 'DAEJEON'
  | 'ULSAN'
  | 'GYEONGGI'
  | 'GANGWON'
  | 'CHUNGBUK'
  | 'CHUNGNAM'
  | 'JEOLLABUK'
  | 'JEOLLANAM'
  | 'GYEONGBUK'
  | 'GYEONGNAM'
  | 'JEJU'
  | 'SEJONG'

export const KoreaMajorCity: Record<
  KoreaMajorCityKey,
  { title: string; lat: number; lng: number }
> = {
  SEOUL: { title: '서울', lat: 60, lng: 127 },
  BUSAN: { title: '부산', lat: 98, lng: 76 },
  DAEGU: { title: '대구', lat: 89, lng: 90 },
  INCHEON: { title: '인천', lat: 55, lng: 124 },
  KWANGJU: { title: '광주', lat: 58, lng: 74 },
  DAEJEON: { title: '대전', lat: 67, lng: 100 },
  ULSAN: { title: '울산', lat: 102, lng: 84 },
  GYEONGGI: { title: '경기', lat: 60, lng: 120 },
  GANGWON: { title: '강원', lat: 73, lng: 134 },
  CHUNGBUK: { title: '충북', lat: 69, lng: 107 },
  CHUNGNAM: { title: '충남', lat: 68, lng: 100 },
  JEOLLABUK: { title: '전북', lat: 63, lng: 89 },
  JEOLLANAM: { title: '전남', lat: 51, lng: 67 },
  GYEONGBUK: { title: '경북', lat: 89, lng: 91 },
  GYEONGNAM: { title: '경남', lat: 91, lng: 77 },
  JEJU: { title: '제주', lat: 52, lng: 38 },
  SEJONG: { title: '세종', lat: 66, lng: 103 },
}

export const findKoreaCityKeyByName = (cityName: string): KoreaMajorCityKey | undefined => {
  cityName = cityName.replace(/\s+/, '')
  const cityKey = KoreaCities[cityName]
  if (cityKey) {
    return cityKey as KoreaMajorCityKey
  } else {
    return undefined
  }
}

export const normalizeKoreaCityName = (cityName: string): string | undefined => {
  cityName = cityName.replace(/\s+/, '')
  const cityKey = KoreaCities[cityName]
  if (cityKey in KoreaMajorCity) {
    return KoreaMajorCity[cityKey as KoreaMajorCityKey].title
  } else {
    return undefined
  }
}
