import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { AdminAccount } from '../../model'

export class AdminAccountApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 관리자 목록 조회
   */
  list = (params: RequestDataBase): Promise<{ adminAccountList: AdminAccount[] }> => {
    const url = '/admin/api/admin/account-list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 관리자 정보 조회
   */
  info = (params: { adminId: number } & RequestDataBase): Promise<{ profile: AdminAccount }> => {
    const { adminId, ...rest } = params
    const url = `/admin/api/admin-account/info/${adminId}`
    return this.withData.postJson(url, ...splitParams(rest))
  }

  /**
   * 관리자가 본인의 비밀번호를 변경해야 하는지 체크한다
   */
  checkPwMustReset = (params: RequestDataBase): Promise<{ pwMustReset: boolean }> => {
    const url = '/admin/api/admin-account/pw-must-reset'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 관리자 신규등록
   */
  saveNewAccount = (
    params: { email: string; adminName: string; password: string } & RequestDataBase,
  ) => {
    const url = '/admin/api/master/save-new-account'
    return this.helper.postJson(url, ...splitParams(params))
  }

  /**
   * 다른 관리자 비번 변경
   */
  changeOtherPassword = (params: { email: string; password: string } & RequestDataBase) => {
    const url = '/admin/api/master/change-other-password'
    return this.helper.postJson(url, ...splitParams(params))
  }

  /**
   * 관리자 본인 비번 변경
   */
  adminChangeMyPassword = (params: { password: string } & RequestDataBase) => {
    const { password, ctx } = params
    const url = '/admin/api/admin-account/change-my-password'
    return this.helper.postJson(url, ...splitParams(params))
  }

  /**
   * 관리자 삭제
   */
  deleteAccount = (params: { email: string } & RequestDataBase) => {
    const url = '/admin/api/master/delete-account'
    return this.helper.postJson(url, ...splitParams(params))
  }

  /**
   * 관리자 권한 변경
   */
  saveRoles = (params: { email: string; roles: string[] } & RequestDataBase) => {
    const url = '/admin/api/role/update'
    return this.helper.postJson(url, ...splitParams(params))
  }
}
