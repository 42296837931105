import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { SentimentAnalLangKey } from '../../makerkit'

/**
 * AnalSentiment Api
 */
export class AnalSentimentApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  sentimentAnalyze = (
    params: {
      content: string
      lang: SentimentAnalLangKey
    } & RequestDataBase,
  ): Promise<{ resultCode: string; resultData: string }> => {
    const url = '/p/ai-api/v1/language/sentiment/analyze'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
