import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { BrowserOsCount } from '../../model/statistics'

/**
 * 어드민 UserAgent Api
 */
export class UserAgentApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * Browser 및 OS 조회
   */
  browserOsList = (
    params: {
      monthCount: number
    } & RequestDataBase,
  ): Promise<{ browserOsCountList: BrowserOsCount[] }> => {
    const url = '/admin/api/user-agent/browser-os-list'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
