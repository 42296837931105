import { ApiHelper, ApiHelperWithData, RequestDataBase, WasUrlHelper, splitParams } from '../../api'
import { DSSampleGridData, PagerData } from '../../model'

/**
 * Data Science Sample Grid Api
 */
export class DSSampleGridApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * Sample Grid 목록 조회 : 페이징
   */
  list = async (
    params: {
      rowsPerPage: number
      pageNumber: number
    } & RequestDataBase,
  ): Promise<{ pagerData: PagerData<DSSampleGridData> }> => {
    const url = '/admin/api/ds-data/sample-grid-list'
    const response: { pagerData: PagerData<DSSampleGridData> } = await this.withData.postJson(
      url,
      ...splitParams(params),
    )
    response.pagerData.elements.forEach((item) => {
      WasUrlHelper.replaceRelative(item, 'dataFileUrl')
    })
    return response
  }

  /**
   * Sample Grid 정보 조회
   */
  info = async (
    params: {
      dataId: number
    } & RequestDataBase,
  ): Promise<{ sampleGridData: DSSampleGridData }> => {
    const { dataId, ...rest } = params
    const url = `/admin/api/ds-data/sample-grid-data/${dataId}`

    const response: { sampleGridData: DSSampleGridData } = await this.withData.post(
      url,
      ...splitParams(rest),
    )
    WasUrlHelper.replaceRelative(response.sampleGridData, 'dataFileUrl')
    return response
  }

  /**
   * Sample Grid 생성
   */
  create = async (
    params: {
      title: string
      subtitle: string
      substance?: string
      dataProvider?: string
      fileId: string
      columnNames: string[]
      tags?: string
    } & RequestDataBase,
  ): Promise<{ sampleGridData: DSSampleGridData }> => {
    const url = '/admin/api/ds-data/sample-grid-create'
    const response: { sampleGridData: DSSampleGridData } = await this.withData.postJson(
      url,
      ...splitParams(params),
    )
    WasUrlHelper.replaceRelative(response.sampleGridData, 'dataFileUrl')
    return response
  }

  /**
   * Sample Grid 한건 업데이트
   */
  update = async (
    params: {
      dataId: number
      title: string
      subtitle: string
      substance?: string
      dataProvider?: string
      fileId: string
      columnNames: string[]
      tags?: string
    } & RequestDataBase,
  ): Promise<{ sampleGridData: DSSampleGridData }> => {
    const { dataId, ...rest } = params
    const url = `/admin/api/ds-data/sample-grid-update/${dataId}`
    const response: { sampleGridData: DSSampleGridData } = await this.withData.postJson(
      url,
      ...splitParams(rest),
    )
    WasUrlHelper.replaceRelative(response.sampleGridData, 'dataFileUrl')
    return response
  }

  /**
   * Sample Grid 삭제
   */
  delete = async (
    params: {
      dataId: number
    } & RequestDataBase,
  ): Promise<void> => {
    const { dataId, ...rest } = params
    const url = `/admin/api/ds-data/sample-grid-delete/${dataId}`

    await this.helper.post(url, ...splitParams(rest))
  }

  /**
   * csv 텍스트로 Sample Grid 파일 생성
   */
  createFileFromCsv = (
    params: {
      csv: string
    } & RequestDataBase,
  ): Promise<{
    fileId: string
    rowCount: number
    columnCount: number
    columnNames: string[]
    rows: string[][]
  }> => {
    const url = '/admin/api/ds-data/sample-grid-create-file/csv-text'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * csv url로 Sample Grid 파일 생성
   */
  createFileFromUrl = (
    params: {
      csvUrl: string
    } & RequestDataBase,
  ): Promise<{
    fileId: string
    rowCount: number
    columnCount: number
    columnNames: string[]
    rows: string[][]
  }> => {
    const url = '/admin/api/ds-data/sample-grid-create-file/csv-url'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
