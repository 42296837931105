import {
  ApiHelper,
  ApiHelperWithData,
  PagingRequest,
  RequestDataBase,
  WasUrlHelper,
  splitParams,
} from '../../api'
import { SimulStateKey } from '../../enum-types'
import { PagerData, Simul } from '../../model'

/**
 * Simul Api
 */
export class SimulApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 시뮬레이터 정보 조회
   */
  info = async (
    params: {
      simulId: number
    } & RequestDataBase,
  ): Promise<{ simul: Simul }> => {
    const { simulId, ...rest } = params
    const url = `/admin/api/simul/info/${simulId}`
    const response: { simul: Simul } = await this.withData.post(url, ...splitParams(rest))
    WasUrlHelper.replaceRelative(response.simul, 'brandIconUrl', 'cateIconActiveUrl', 'cateIconUrl')
    return response
  }

  hide = async (
    params: {
      simulId: number
    } & RequestDataBase,
  ): Promise<{ simul: Simul }> => {
    const { simulId, ...rest } = params
    const url = `/admin/api/simul/hide/${simulId}`

    const response: { simul: Simul } = await this.withData.post(url, ...splitParams(rest))
    WasUrlHelper.replaceRelative(response.simul, 'brandIconUrl', 'cateIconActiveUrl', 'cateIconUrl')
    return response
  }

  show = async (
    params: {
      simulId: number
    } & RequestDataBase,
  ): Promise<{ simul: Simul }> => {
    const { simulId, ...rest } = params
    const url = `/admin/api/simul/show/${simulId}`

    const response: { simul: Simul } = await this.withData.post(url, ...splitParams(rest))
    WasUrlHelper.replaceRelative(response.simul, 'brandIconUrl', 'cateIconActiveUrl', 'cateIconUrl')
    return response
  }

  approve = async (
    params: {
      simulId: number
    } & RequestDataBase,
  ): Promise<{ simul: Simul }> => {
    const { simulId, ...rest } = params
    const url = `/admin/api/simul/approve/${simulId}`

    const response: { simul: Simul } = await this.withData.post(url, ...splitParams(rest))
    WasUrlHelper.replaceRelative(response.simul, 'brandIconUrl', 'cateIconActiveUrl', 'cateIconUrl')
    return response
  }

  deleteMark = async (
    params: {
      simulId: number
    } & RequestDataBase,
  ): Promise<void> => {
    const { simulId, ...rest } = params
    const url = `/admin/api/simul/delete-mark/${simulId}`

    await this.helper.post(url, ...splitParams(rest))
  }

  purge = async (
    params: {
      simulId: number
    } & RequestDataBase,
  ): Promise<void> => {
    const { simulId, ...rest } = params
    const url = `/admin/api/simul/purge/${simulId}`

    await this.helper.post(url, ...splitParams(rest))
  }

  /**
   * 시뮬레이터 목록 조회 - 본인이 권한있는 시뮬레이터 목록 조회
   */
  list = async (
    params: {
      companyName?: string
      userId?: string
      simulName?: string
      blockPrefix?: string
      states?: SimulStateKey[]
      withDeleted: boolean
      publishedOnly: boolean
      ownerType?: 'PERSONAL' | 'COMPANY'
    } & PagingRequest,
  ): Promise<{
    pagerData: PagerData<Simul>
  }> => {
    const url = '/admin/api/simul/list'

    const response: {
      pagerData: PagerData<Simul>
    } = await this.withData.postJson(url, ...splitParams(params))

    response.pagerData.elements?.forEach((item) => {
      WasUrlHelper.replaceRelative(item, 'brandIconUrl', 'cateIconActiveUrl', 'cateIconUrl')
    })
    return response
  }

  /**
   * 시뮬레이터 아이콘 업데이트
   */
  updateCateIcon = async (
    params: {
      simulId: number
      isActive: boolean
      fileId: string
    } & RequestDataBase,
  ): Promise<{ simul: Simul }> => {
    const { simulId, isActive, ...rest } = params
    const url = `/admin/api/simul/update-cate-icon/${simulId}/${isActive ? 1 : 0}`

    const response: { simul: Simul } = await this.withData.post(url, ...splitParams(rest))
    WasUrlHelper.replaceRelative(response.simul, 'brandIconUrl', 'cateIconActiveUrl', 'cateIconUrl')
    return response
  }

  /**
   * 시뮬레이터 브랜드 아이콘 업데이트
   */
  updateBrandIcon = async (
    params: {
      simulId: number
      fileId: string
    } & RequestDataBase,
  ): Promise<{ simul: Simul }> => {
    const { simulId, ...rest } = params
    const url = `/admin/api/simul/update-brand-icon/${simulId}`

    const response: { simul: Simul } = await this.withData.post(url, ...splitParams(rest))
    WasUrlHelper.replaceRelative(response.simul, 'brandIconUrl', 'cateIconActiveUrl', 'cateIconUrl')
    return response
  }

  updateMeta = async (
    params: {
      simulId: number
      simulName: string
      substance?: string
      intro?: string
      cateColor: string
      cateIconFileId?: string
      cateIconActiveFileId?: string
      brandIconFileId?: string
    } & RequestDataBase,
  ): Promise<{ simul: Simul }> => {
    const { simulId, ...rest } = params
    const url = `/admin/api/simul/update-meta/${simulId}`

    const response: { simul: Simul } = await this.withData.postJson(url, ...splitParams(rest))
    WasUrlHelper.replaceRelative(response.simul, 'brandIconUrl', 'cateIconActiveUrl', 'cateIconUrl')
    return response
  }

  /**
   * 시뮬레이터 회사에 복제
   */
  cloneToCompany = async (
    params: {
      srcSimulId: number
      companyId: string
      blockPrefix: string
    } & RequestDataBase,
  ): Promise<{ simul: Simul }> => {
    const url = '/admin/api/simul/clone-to-company'

    const response: { simul: Simul } = await this.withData.post(url, ...splitParams(params))
    WasUrlHelper.replaceRelative(response.simul, 'brandIconUrl', 'cateIconActiveUrl', 'cateIconUrl')
    return response
  }
}
