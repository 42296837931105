// 로그인 페이지
export const LOGIN_PAGE = '/sign-in'

// 로그아웃 페이지
export const LOGOUT_PAGE = '/logout'

// 프로필 기본 이미지
export const DEFAULT_PROFILE_IMAGE_1 = '/images/user/user_profile_default_1.svg'
export const DEFAULT_PROFILE_IMAGE_2 = '/images/user/user_profile_default_2.svg'
export const DEFAULT_PROFILE_IMAGE_3 = '/images/user/user_profile_default_3.svg'
export const DEFAULT_PROFILE_IMAGE_4 = '/images/user/user_profile_default_4.svg'
export const DEFAULT_PROFILE_IMAGE_5 = '/images/user/user_profile_default_5.svg'
export const DEFAULT_PROFILE_IMAGE_6 = '/images/user/user_profile_default_6.svg'
export const DEFAULT_PROFILE_IMAGE_7 = '/images/user/user_profile_default_7.svg'
export const DEFAULT_PROFILE_IMAGE_8 = '/images/user/user_profile_default_8.svg'

const SECONDS = 1000

export const CONTENT_BG_COLOR = '#fafafa'

// 개발자 테스트용, 자동 갱신 비활성화 - 운영서버에서는 false
export const DISABLE_AUTO_REFRESH = false

// 개발자 테스트용, API 디버깅 - 운영서버에서는 false
export const DEBUG_API = false

// 자동 갱신 간격
export const AUTO_REFRESH_INTERVAL = 10 * SECONDS

// 입력 목록 페이지당 Row 수
export const CERA_ANAL_INPUT_LIST_ROWS_PER_PAGE = 20

// 그래프 높이 - 크게
export const PLOT_HEIGHT_LARGE = 550

// 그래프 높이 - 중간 크기
export const PLOT_HEIGHT_MEDIUM = 450

// 그래프 높이 - 작은 크기
export const PLOT_HEIGHT_SMALL = 350

// ADM 비밀번호 길이
export const ADM_PASSWD_MIN_LEN = 4
export const ADM_PASSWD_MAX_LEN = 40

export const SNACKBAR_TIMEOUT = 3500
export const SNACKBAR_TIMEOUT_LONG = 3500
export const SNACKBAR_TIMEOUT_SHORT = 2000

/**
 * 개인정보 처리방침 약관ID
 * clauseUrls.view(CLAUSE_ID_PRIVACY)
 */
export const CLAUSE_ID_PRIVACY = 1000000

/**
 * 이용약관 ID
 * clauseUrls.view(CLAUSE_ID_USAGE)
 */
export const CLAUSE_ID_USAGE = 1000004
