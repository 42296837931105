import { AdminAccount } from '@aimk/domain'

export class LoginProfileWrapper {
  public readonly isMaster: boolean

  constructor(public readonly profile: AdminAccount) {
    const roles = profile.roles ?? []
    this.isMaster = roles.includes('MASTER')
  }
}
