import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'
import { SimulPlayground } from '../../model'

/**
 * Simul Playground Api
 */
export class SimulPlaygroundApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 시뮬레이터 아이콘 업데이트
   */
  config = (
    params: {
      simulId: number
    } & RequestDataBase,
  ): Promise<{
    playground: SimulPlayground
  }> => {
    const { simulId, ctx } = params
    const url = `/api/simul-playground/config/${simulId}`

    return this.withData.post(url, undefined, { ctx })
  }
}
