import { ApiHelper, ApiHelperWithData, WasUrlHelper, splitParams } from '../../api'
import {
  BbsAdminSearchOption,
  BbsAttachFile,
  BbsPost,
  BbsPostDataCount,
  BbsPostSimple,
  BbsReply,
  PagerData,
} from '../../model'
import { BbsRequest } from '../../user-main-api/payload/bbs-payload'

/**
 * 관리자 게시판 API
 */
export class BbsAdminApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 포스트 작성
   */
  createPost = async (
    params: {
      title: string
      substance: string
      fileIds: string[]
    } & BbsRequest,
  ): Promise<{ post: BbsPost }> => {
    const { bbsId, ...rest } = params
    const url = `/admin/api/bbs/${bbsId}/post/write`
    const response: { post: BbsPost } = await this.withData.post(url, ...splitParams(rest))
    response.post.attachFiles?.forEach((f) => {
      WasUrlHelper.replaceRelative(f, 'downloadUrl')
    })
    return response
  }

  /**
   * 포스트 수정
   */
  updatePost = async (
    params: {
      tid: number
      title: string
      substance: string
      fileIds: string[]
    } & BbsRequest,
  ): Promise<{ post: BbsPost }> => {
    const { bbsId, ...rest } = params
    const url = `/admin/api/bbs/${bbsId}/post/update`
    const response: { post: BbsPost } = await this.withData.post(url, ...splitParams(rest))
    return response
  }

  /**
   * 포스트 목록 조회
   */
  postList = async (
    params: BbsAdminSearchOption & BbsRequest,
  ): Promise<{ pagerData: PagerData<BbsPostSimple> }> => {
    const { bbsId, ...rest } = params
    const url = `/admin/api/bbs/${bbsId}/post/list`
    return await this.withData.postJson(url, ...splitParams(rest))
  }

  /**
   * 포스트 상세 조회
   */
  postInfo = async (params: { tid: number } & BbsRequest): Promise<{ post: BbsPost }> => {
    const { bbsId, tid, ...rest } = params
    const url = `/admin/api/bbs/${bbsId}/post/info/${tid}`
    return this.withData.post(url, ...splitParams(rest))
  }

  /**
   * 댓글 작성
   */
  createReply = (
    params: {
      postId: number
      substance: string
    } & BbsRequest,
  ): Promise<{ reply: BbsReply; dataCount: BbsPostDataCount }> => {
    const { bbsId, postId, ...rest } = params
    const url = `/admin/api/bbs/${bbsId}/reply/write/${postId}`
    return this.withData.post(url, ...splitParams(rest))
  }

  /**
   * 대댓글 작성
   */
  createGreatReply = (
    params: {
      parentReplyId: number
      substance: string
    } & BbsRequest,
  ): Promise<{ reply: BbsReply; dataCount: BbsPostDataCount }> => {
    const { bbsId, parentReplyId, ...rest } = params
    const url = `/admin/api/bbs/${bbsId}/great-reply/write/${parentReplyId}`
    return this.withData.post(url, ...splitParams(rest))
  }

  /**
   * 댓글/대댓글 수정
   */
  updateReply = (
    params: {
      replyId: number
      substance: string
    } & BbsRequest,
  ): Promise<{ reply: BbsReply; dataCount: BbsPostDataCount }> => {
    const { bbsId, replyId, ...rest } = params
    const url = `/admin/api/bbs/${bbsId}/reply/update/${replyId}`
    return this.withData.post(url, ...splitParams(rest))
  }

  /**
   * 특정 게시물의 댓글 목록 조회 - 페이징
   */
  replyList = (
    params: { postId: number; rowsPerPage: number; pageNumber: number } & BbsRequest,
  ): Promise<{ pagerData: PagerData<BbsReply> }> => {
    const { bbsId, postId, pageNumber, ...rest } = params
    const url = `/admin/api/bbs/${bbsId}/reply/list/${postId}/${pageNumber}`

    return this.withData.post(url, ...splitParams(rest))
  }

  /**
   * 전체 게시물의 댓글 목록 조회 - 페이징
   */
  replyListAll = (
    params: {
      keyword?: string
      writeUserId?: string
      rowsPerPage: number
      pageNumber: number
    } & BbsRequest,
  ): Promise<{ pagerData: PagerData<BbsReply> }> => {
    const { bbsId, ...rest } = params
    const url = `/admin/api/bbs/${bbsId}/reply/list-all`

    return this.withData.postJson(url, ...splitParams(rest))
  }

  /**
   * 포스트, 댓글, 대댓글 삭제
   */
  delete = (params: { tid: number } & BbsRequest): Promise<{ dataCount: BbsPostDataCount }> => {
    const { bbsId, tid, ...rest } = params
    const url = `/admin/api/bbs/${bbsId}/article/delete/${tid}`
    return this.withData.post(url, ...splitParams(rest))
  }

  /**
   * 좋아요 추가
   * 게시물의 좋아요 건수를 응답
   */
  favorAdd = (
    params: {
      articleId: number
    } & BbsRequest,
  ): Promise<{ count: number }> => {
    const { bbsId, articleId, ...rest } = params
    const url = `/admin/api/bbs/${bbsId}/favor/add/${articleId}`
    return this.withData.post(url, ...splitParams(rest))
  }

  /**
   * 좋아요 취소
   * 게시물의 좋아요 건수를 응답
   */
  favorRemove = (
    params: {
      articleId: number
    } & BbsRequest,
  ): Promise<{ count: number }> => {
    const { bbsId, articleId, ...rest } = params
    const url = `/admin/api/bbs/${bbsId}/favor/remove/${articleId}`
    return this.withData.post(url, ...splitParams(rest))
  }

  /**
   * 첨부파일 업로드
   */
  uploadAttachFile = async (
    params: {
      file: File
    } & BbsRequest,
  ): Promise<{ file: BbsAttachFile }> => {
    const url = `/admin/api/bbs/${params.bbsId}/upload`
    const { ctx, file } = params
    const formData = new FormData()
    formData.append('file', file, file.name) // formData.append('file', file)
    console.log('uploading file:', { name: file.name, size: file.size, type: file.type })

    const response: { file: BbsAttachFile } = await this.withData.postMultipart(url, formData, {
      ctx,
    })
    WasUrlHelper.replaceRelative(response.file, 'downloadUrl')
    return response
  }
}
