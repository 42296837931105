import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'
import { Pds } from '../../model'

/**
 * Pds Api
 */
export class PdsApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  info = (
    params: {
      pdsId: string
    } & RequestDataBase,
  ): Promise<{ pds: Pds }> => {
    const { pdsId, ctx } = params
    const url = `/p/api/pds/info/${pdsId}`

    return this.withData.post(url, undefined, { ctx })
  }
}
