import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'
import { BogunApiDayTypeKey, BogunApiTypeKey } from '../../makerkit'

/**
 * Bogun Api
 */
export class BogunApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  loadByCityName = ({
    ctx,
    day,
    apiType,
    cityName,
  }: RequestDataBase & {
    day: BogunApiDayTypeKey
    apiType: BogunApiTypeKey
    cityName: string
  }): Promise<{
    resultCode: string
    resultData: string
  }> => {
    const url = '/p/ai-api/v1/bogun/loadByCityName'
    const params = { day, apiType, cityName }
    return this.withData.postJson(url, params, { ctx })
  }
}
