import { AxiosResponse } from 'axios'
import { ApiHelper, ErrorResponseInterceptor, ResponseInterceptor } from '../api'
import { AppError } from '../errors'
import log from '../log'
import { AssistantApi } from './ai/assistant-api'
import { BardApi } from './ai/bard-api'
import { BogunApi } from './ai/bogun-api'
import { CaptchaApi } from './ai/captcha-api'
import { ExternApiCallApi } from './ai/extern-api-call-api'
import { ForecastApi } from './ai/forecast-api'
import { GanApi } from './ai/gan-api'
import { GinsideApi } from './ai/ginside-api'
import { LanguageApi } from './ai/language-api'
import { SubwayApi } from './ai/subway-api'
import { TextClassificationApi } from './ai/text-classification-api'
import { TranslateApi } from './ai/translate-api'
import { TtsApi } from './ai/tts-api'
import { VisionApi } from './ai/vision-api'

export class AiApi {
  helper: ApiHelper
  assistant: AssistantApi
  bogun: BogunApi
  subway: SubwayApi
  vision: VisionApi
  gan: GanApi
  externApiCall: ExternApiCallApi
  forecast: ForecastApi
  textClassification: TextClassificationApi
  language: LanguageApi
  translate: TranslateApi
  tts: TtsApi
  captcha: CaptchaApi
  ginside: GinsideApi
  bard: BardApi

  constructor(
    public apiBaseURL: string,
    createApiHeader: () => Record<string, string>,
    responseInterceptor: ResponseInterceptor,
    errorResponseInterceptor: ErrorResponseInterceptor,
    debug = false,
  ) {
    log.debug('create Api for ', apiBaseURL)

    // create helper
    this.helper = new ApiHelper(
      apiBaseURL,
      createApiHeader,
      responseInterceptor,
      errorResponseInterceptor,
      debug,
    )

    this.assistant = new AssistantApi(this.helper)
    this.bogun = new BogunApi(this.helper)
    this.subway = new SubwayApi(this.helper)
    this.vision = new VisionApi(this.helper)
    this.language = new LanguageApi(this.helper)
    this.gan = new GanApi(this.helper)
    this.externApiCall = new ExternApiCallApi(this.helper)
    this.forecast = new ForecastApi(this.helper)
    this.textClassification = new TextClassificationApi(this.helper)
    this.translate = new TranslateApi(this.helper)
    this.tts = new TtsApi(this.helper)
    this.captcha = new CaptchaApi(this.helper)
    this.ginside = new GinsideApi(this.helper)
    this.bard = new BardApi(this.helper)
  }

  static create = (apiBaseURL: string): AiApi => {
    const createApiHeader = () => ({})
    const responseInterceptor = (response: AxiosResponse) => response
    const errorResponseInterceptor = (error: AppError) => {}
    return new AiApi(apiBaseURL, createApiHeader, responseInterceptor, errorResponseInterceptor)
  }
}
