import { ApiHelper, ApiHelperWithData, PagingRequest, RequestDataBase } from '../../api'
import {
  AimkEventApplicant,
  AimkEventApplyField,
  AimkEventForUser,
  AimkEventSimpleForUser,
  PagerData,
} from '../../model'

/**
 * 관리자용 Event Api
 */
export class EventApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 이벤트 목록 조회
   */
  list = (params: PagingRequest): Promise<{ pagerData: PagerData<AimkEventSimpleForUser> }> => {
    const { ctx, ...restParams } = params
    const url = '/p/api/event/list'
    return this.withData.postJsonAndSecuredResponse(url, restParams, { ctx })
  }

  /**
   * 이벤트 정보 조회
   */
  info = (
    params: {
      eventId: number
      accountId?: number
      isFirstLoad?: boolean
    } & RequestDataBase,
  ): Promise<{
    event: AimkEventForUser
    applyFields: AimkEventApplyField[]
    applicant?: AimkEventApplicant
  }> => {
    const { ctx, eventId, accountId, isFirstLoad = false } = params
    const url = `/p/api/event/info/${eventId}`
    return this.withData.postAndSecuredResponse(
      url,
      { accountId, firstRun: isFirstLoad ? '1' : '0' },
      { ctx },
    )
  }

  /**
   * 이벤트 응모하기
   */
  saveApplicant = (
    params: {
      eventId: number
      fieldValues: Array<{ fieldId: number; value: string }>

      // 신규 응모일때는 값이 필수이다
      kmcisReqId?: string
    } & RequestDataBase,
  ): Promise<{
    event: AimkEventForUser
    applyFields: AimkEventApplyField[]
    applicant?: AimkEventApplicant
  }> => {
    const { ctx, eventId, fieldValues, kmcisReqId } = params
    const url = '/api/event/applicant/save'
    return this.withData.postJsonAndSecuredResponse(
      url,
      { eventId, fieldValues, kmcisReqId },
      { ctx },
    )
  }
}
