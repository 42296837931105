import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'
import { Company, CompanyStaff } from '../../model'

/**
 * 어드민 제휴사 Api
 */
export class CompanyApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 제휴사 목록 조회
   */
  list = (params: RequestDataBase): Promise<{ companyList: Company[] }> => {
    const url = '/admin/api/company/list'
    const { ctx } = params
    return this.withData.post(url, undefined, { ctx })
  }
  /**
   * 제휴사 정보 조회
   */
  info = (params: { companyId: string } & RequestDataBase): Promise<{ company: Company }> => {
    const { ctx, companyId } = params
    const url = `/admin/api/company/info/${companyId}`
    return this.withData.post(url, undefined, { ctx })
  }

  /**
   * 제휴사 담당자 목록 조회
   */
  staffList = (
    params: {
      companyId: string
    } & RequestDataBase,
  ): Promise<{ staffList: CompanyStaff[] }> => {
    const { ctx, companyId } = params
    const url = `/admin/api/company/staff-list/${companyId}`
    return this.withData.post(url, undefined, { ctx })
  }

  /**
   * 신규 등록
   */
  create = (
    params: {
      companyId: string
      companyName: string
    } & RequestDataBase,
  ): Promise<{ company: Company }> => {
    const url = '/admin/api/company/create'
    const { ctx, companyId, companyName } = params
    return this.withData.postJson(url, { companyId, companyName }, { ctx })
  }

  /**
   * 업데이트
   */
  update = (
    params: {
      companyId: string
      companyName: string
    } & RequestDataBase,
  ): Promise<{ company: Company }> => {
    const url = '/admin/api/company/update'
    const { ctx, companyId, companyName } = params
    return this.withData.postJson(url, { companyId, companyName }, { ctx })
  }

  /**
   * 삭제
   */
  delete = async (params: { companyId: string } & RequestDataBase): Promise<void> => {
    const { ctx, companyId } = params
    const url = `/admin/api/company/delete/${companyId}`
    await this.helper.post(url, undefined, { ctx })
  }

  /**
   * 제휴사 담당자 추가
   */
  staffAdd = (
    params: { companyId: string; userId: string } & RequestDataBase,
  ): Promise<{ staff: CompanyStaff }> => {
    const { ctx, companyId, userId } = params
    const url = `/admin/api/company/staff-add/${companyId}`
    return this.withData.post(url, { userId }, { ctx })
  }

  /**
   * 제휴사 담당자 삭제
   */
  staffDelete = async (
    params: { companyId: string; accountId: number } & RequestDataBase,
  ): Promise<void> => {
    const { ctx, companyId, accountId } = params
    const url = `/admin/api/company/staff-delete/${companyId}`
    await this.helper.post(url, { accountId }, { ctx })
  }
}
