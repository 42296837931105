import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'

/**
 * HandwriteDetect Api
 */
export class HandwriteDetectApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  detectHandwrite = (
    params: {
      imageDataURL: string
    } & RequestDataBase,
  ): Promise<{ texts: string[] }> => {
    const url = '/p/ai-api/v1/vision/handwrite/detect'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
