import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'

/**
 * LogoDetect Api
 */
export class LogoDetectApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  detectLogos = (
    params: {
      imageDataURL: string
    } & RequestDataBase,
  ): Promise<{ logoDescriptions: string[] }> => {
    const url = '/p/ai-api/v1/vision/logo/detect'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
