import {
  ApiHelper,
  ApiHelperWithData,
  PagingRequest,
  RequestDataBase,
  WasUrlHelper,
  splitParams,
} from '../../api'
import { PagerData, Pds, PdsSimple, UploadedFile } from '../../model'

/**
 * Pds Api
 */
export class PdsApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  create = async (
    params: {
      title: string
      substance?: string
      fileIds: string[]
    } & RequestDataBase,
  ): Promise<{ pds: Pds }> => {
    const url = '/admin/api/pds/create'
    const response: { pds: Pds } = await this.withData.postJson(url, ...splitParams(params))
    response.pds.attachFiles?.forEach((f) => {
      WasUrlHelper.replaceRelative(f, 'downloadUrl')
    })
    return response
  }

  update = async (
    params: {
      pdsId: string
      title: string
      substance?: string
      fileIds: string[]
    } & RequestDataBase,
  ): Promise<{ pds: Pds }> => {
    const { pdsId, ...rest } = params
    const url = `/admin/api/pds/update/${pdsId}`
    const response: { pds: Pds } = await this.withData.postJson(url, ...splitParams(rest))
    response.pds.attachFiles?.forEach((f) => {
      WasUrlHelper.replaceRelative(f, 'downloadUrl')
    })
    return response
  }

  list = async (
    params: {
      title?: string | null
    } & PagingRequest,
  ): Promise<{ pagerData: PagerData<PdsSimple> }> => {
    const url = '/admin/api/pds/list'
    const response: { pagerData: PagerData<PdsSimple> } = await this.withData.postJson(
      url,
      ...splitParams(params),
    )
    response.pagerData.elements?.forEach((item) => {
      item.attachFiles?.forEach((f) => {
        WasUrlHelper.replaceRelative(f, 'downloadUrl')
      })
    })
    return response
  }

  info = async (
    params: {
      pdsId: string
    } & RequestDataBase,
  ): Promise<{ pds: Pds }> => {
    const { pdsId, ...rest } = params
    const url = `/admin/api/pds/info/${pdsId}`
    const response: { pds: Pds } = await this.withData.post(url, ...splitParams(rest))
    response.pds.attachFiles?.forEach((f) => {
      WasUrlHelper.replaceRelative(f, 'downloadUrl')
    })
    return response
  }

  uploadTempFile = async (
    params: {
      file: Blob
      fileName: string
    } & RequestDataBase,
  ): Promise<{ file: UploadedFile }> => {
    const url = '/admin/api/pds/upload'
    const { ctx, file, fileName } = params
    const formData = new FormData()
    formData.append('file', file, fileName)
    const response: { file: UploadedFile } = await this.withData.postMultipart(url, formData, {
      ctx,
    })
    WasUrlHelper.replaceRelative(response.file, 'downloadUrl')
    return response
  }

  renameFile = async (
    params: {
      fileId: string
      fileName: string
    } & RequestDataBase,
  ): Promise<{ file: UploadedFile }> => {
    const { fileId, ...rest } = params
    const url = `/admin/api/pds/update-file-name/${fileId}`
    const response: { file: UploadedFile } = await this.withData.post(url, ...splitParams(rest))
    WasUrlHelper.replaceRelative(response.file, 'downloadUrl')
    return response
  }

  delete = async (
    params: {
      pdsId: string
    } & RequestDataBase,
  ): Promise<void> => {
    const { pdsId, ...rest } = params
    const url = `/admin/api/pds/delete/${pdsId}`
    await this.helper.postJson(url, ...splitParams(rest))
  }
}
