import { ApiHelper, ErrorResponseInterceptor, ResponseInterceptor } from '../api'
import log from '../log'
import { AccessLogApi } from './admin/access-log-api'
import { AdminAccessApi } from './admin/admin-access-api'
import { AdminAccountApi } from './admin/admin-account-api'
import { AiCacheApi } from './admin/ai-cache-api'
import { AllianceInqApi } from './admin/alliance-inq-api'
import { AppLogApi } from './admin/app-log-api'
import { AuthApi } from './admin/auth-api'
import { BbsAdminApi } from './admin/bbs-admin-api'
import { BlackUserIdApi } from './admin/black-user-id-api'
import { BlockApi } from './admin/block-api'
import { BlockThemeApi } from './admin/block-theme-api'
import { BrowserStatApi } from './admin/browser-stat-api'
import { ClauseApi } from './admin/clause-api'
import { CodingpackStatApi } from './admin/codingpack-stat-api'
import { CompanyApi } from './admin/company-api'
import { DSSampleGridApi } from './admin/ds-sample-grid-api'
import { DSSampleTextApi } from './admin/ds-sample-text-api'
import { EduResourceApi } from './admin/edu-resource-api'
import { EduToyApi } from './admin/edu-toy-api'
import { EventApi } from './admin/event-api'
import { FaqApi } from './admin/faq-api'
import { GalleryApi } from './admin/gallery-api'
import { GuideApi } from './admin/guide-api'
import { HwDriverApi } from './admin/hw-driver-api'
import { I18nApi } from './admin/i18n-api'
import { JobApi } from './admin/job-api'
import { KmcisApi } from './admin/kmcis-api'
import { MakerSoundApi } from './admin/maker-sound-api'
import { ObjectImageApi } from './admin/object-image-api'
import { OpenSourceApi } from './admin/open-source-api'
import { PartnerAccessApi } from './admin/partner-access-api'
import { PdsApi } from './admin/pds-api'
import { ProfileApi } from './admin/profile-api'
import { QuickDrawApi } from './admin/quick-draw-api'
import { ServerApi } from './admin/server-api'
import { SimulApi } from './admin/simul-api'
import { SimulFileUploadApi } from './admin/simul-file-upload-api'
import { SimulPubApi } from './admin/simul-pub-api'
import { UserAgentApi } from './admin/user-agent-api'
import { UserApi } from './admin/user-api'
import { UserCreateRequestApi } from './admin/user-create-request-api'
import { UserKickApi } from './admin/user-kick-api'
import { UserStatApi } from './admin/user-stat-api'
import { UserWithdrawApi } from './admin/user-withdraw-api'
import { WebPropApi } from './admin/web-prop-api'

export class AdminMainApi {
  helper: ApiHelper
  auth: AuthApi
  clause: ClauseApi
  admin: AdminAccountApi
  openSource: OpenSourceApi
  i18n: I18nApi
  block: BlockApi
  blockTheme: BlockThemeApi
  profile: ProfileApi
  faq: FaqApi
  job: JobApi
  server: ServerApi
  appLog: AppLogApi
  allianceInq: AllianceInqApi
  user: UserApi
  userStat: UserStatApi
  bbsAdmin: BbsAdminApi
  dsSampleGrid: DSSampleGridApi
  dsSampleText: DSSampleTextApi
  objectImage: ObjectImageApi
  makerSound: MakerSoundApi
  company: CompanyApi
  aiCache: AiCacheApi
  eduResource: EduResourceApi
  userAgent: UserAgentApi
  partnerAccess: PartnerAccessApi
  browserStat: BrowserStatApi
  codingpackStat: CodingpackStatApi
  simul: SimulApi
  simulFileUpload: SimulFileUploadApi
  simulPub: SimulPubApi
  pds: PdsApi
  userKick: UserKickApi
  blackUserId: BlackUserIdApi
  userWithdraw: UserWithdrawApi
  event: EventApi
  quickDraw: QuickDrawApi
  adminAccess: AdminAccessApi
  accessLog: AccessLogApi
  userCreateRequest: UserCreateRequestApi
  kmcis: KmcisApi
  gallery: GalleryApi
  hwDriver: HwDriverApi
  eduToy: EduToyApi
  webProp: WebPropApi
  guide: GuideApi

  constructor(
    public apiBaseURL: string,
    createApiHeader: () => Record<string, string>,
    responseInterceptor: ResponseInterceptor,
    errorResponseInterceptor: ErrorResponseInterceptor,
    debug = true,
  ) {
    log.debug('create Api for ', apiBaseURL)

    // create helper
    this.helper = new ApiHelper(
      apiBaseURL,
      createApiHeader,
      responseInterceptor,
      errorResponseInterceptor,
      debug,
    )

    // api list
    this.auth = new AuthApi(this.helper)
    this.clause = new ClauseApi(this.helper)
    this.admin = new AdminAccountApi(this.helper)
    this.openSource = new OpenSourceApi(this.helper)
    this.i18n = new I18nApi(this.helper)
    this.block = new BlockApi(this.helper)
    this.blockTheme = new BlockThemeApi(this.helper)
    this.profile = new ProfileApi(this.helper)
    this.faq = new FaqApi(this.helper)
    this.job = new JobApi(this.helper)
    this.server = new ServerApi(this.helper)
    this.appLog = new AppLogApi(this.helper)
    this.allianceInq = new AllianceInqApi(this.helper)
    this.user = new UserApi(this.helper)
    this.userStat = new UserStatApi(this.helper)
    this.bbsAdmin = new BbsAdminApi(this.helper)
    this.dsSampleGrid = new DSSampleGridApi(this.helper)
    this.dsSampleText = new DSSampleTextApi(this.helper)
    this.objectImage = new ObjectImageApi(this.helper)
    this.makerSound = new MakerSoundApi(this.helper)
    this.company = new CompanyApi(this.helper)
    this.aiCache = new AiCacheApi(this.helper)
    this.eduResource = new EduResourceApi(this.helper)
    this.userAgent = new UserAgentApi(this.helper)
    this.partnerAccess = new PartnerAccessApi(this.helper)
    this.browserStat = new BrowserStatApi(this.helper)
    this.codingpackStat = new CodingpackStatApi(this.helper)
    this.simul = new SimulApi(this.helper)
    this.simulFileUpload = new SimulFileUploadApi(this.helper)
    this.simulPub = new SimulPubApi(this.helper)
    this.pds = new PdsApi(this.helper)
    this.userKick = new UserKickApi(this.helper)
    this.blackUserId = new BlackUserIdApi(this.helper)
    this.userWithdraw = new UserWithdrawApi(this.helper)
    this.event = new EventApi(this.helper)
    this.quickDraw = new QuickDrawApi(this.helper)
    this.adminAccess = new AdminAccessApi(this.helper)
    this.accessLog = new AccessLogApi(this.helper)
    this.userCreateRequest = new UserCreateRequestApi(this.helper)
    this.kmcis = new KmcisApi(this.helper)
    this.gallery = new GalleryApi(this.helper)
    this.hwDriver = new HwDriverApi(this.helper)
    this.eduToy = new EduToyApi(this.helper)
    this.webProp = new WebPropApi(this.helper)
    this.guide = new GuideApi(this.helper)
  }
}
