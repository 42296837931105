import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'
import { CompanyCodingStateKey } from '../../enum-types'
import { CompanyCoding, UploadedFile } from '../../model'

/**
 * Company Coding Api
 */
export class CompanyCodingApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  create = (
    params: {
      companyId: string
      cateId: string
      title: string
      substance: string
      testSubstance?: string
      state: CompanyCodingStateKey
      genFileId: string
      imageFileId: string
    } & RequestDataBase,
  ): Promise<{ companyCoding: CompanyCoding }> => {
    const {
      ctx,
      companyId,
      cateId,
      title,
      substance,
      testSubstance,
      state,
      genFileId,
      imageFileId,
    } = params
    const url = '/api/company-coding/create'

    return this.withData.postJson(
      url,
      { companyId, cateId, title, substance, testSubstance, state, genFileId, imageFileId },
      { ctx },
    )
  }

  update = (
    params: {
      codingId: string
      cateId: string
      title: string
      substance: string
      testSubstance?: string
      state: CompanyCodingStateKey
    } & RequestDataBase,
  ): Promise<{ companyCoding: CompanyCoding }> => {
    const { ctx, codingId, cateId, title, substance, testSubstance, state } = params
    const url = `/api/company-coding/update/${codingId}`

    return this.withData.postJson(url, { cateId, title, substance, testSubstance, state }, { ctx })
  }

  /**
   * Gen 파일 업로드
   */
  uploadGenFile = (
    params: {
      file: Blob
    } & RequestDataBase,
  ): Promise<{ file: UploadedFile }> => {
    const { file, ctx } = params
    const url = '/api/company-coding/gen-upload'

    const formData = new FormData()
    formData.append('file', file, 'file.gen')
    return this.withData.postMultipart(url, formData, { ctx })
  }

  /**
   * 썸네일 파일 업로드
   */
  uploadImageFile = (
    params: {
      file: Blob
    } & RequestDataBase,
  ): Promise<{ file: UploadedFile }> => {
    const { file, ctx } = params
    const url = '/api/company-coding/img-upload'

    const formData = new FormData()
    formData.append('file', file, 'img.png')
    return this.withData.postMultipart(url, formData, { ctx })
  }

  cateList = (
    params: {
      companyId: string
    } & RequestDataBase,
  ): Promise<{ cateIdList: string[] }> => {
    const url = '/api/company-coding/cate-list'
    const { companyId, ctx } = params

    return this.withData.postJson(url, { companyId }, { ctx })
  }

  list = (
    params: {
      companyId: string
      cateId: string
    } & RequestDataBase,
  ): Promise<{ companyCodingList: CompanyCoding[] }> => {
    const url = '/api/company-coding/list'
    const { companyId, cateId, ctx } = params

    return this.withData.postJson(url, { companyId, cateId }, { ctx })
  }

  checkList = (
    params: {
      companyId: string
      state: CompanyCodingStateKey[]
      maxCount?: number
    } & RequestDataBase,
  ): Promise<{ companyCodingList: CompanyCoding[] }> => {
    const url = '/api/company-coding/check-list'
    const { companyId, state, maxCount, ctx } = params

    return this.withData.postJson(url, { companyId, maxCount, state }, { ctx })
  }

  info = (
    params: {
      codingId: string
    } & RequestDataBase,
  ): Promise<{ companyCoding: CompanyCoding }> => {
    const url = `/api/company-coding/info/${params.codingId}`
    const { ctx } = params

    return this.withData.postJson(url, undefined, { ctx })
  }

  /**
   * CompanyCoding 삭제
   */
  delete = async (
    params: {
      codingId: string
    } & RequestDataBase,
  ): Promise<void> => {
    const { codingId, ctx } = params
    const url = `/api/company-coding/delete/${codingId}`

    await this.helper.post(url, undefined, { ctx })
  }

  updateTitle = (
    params: {
      codingId: string
      title: string
    } & RequestDataBase,
  ): Promise<{ companyCoding: CompanyCoding }> => {
    const { ctx, codingId, title } = params
    const url = `/api/company-coding/update-title/${codingId}`

    return this.withData.postJson(url, { title }, { ctx })
  }
  updateCateId = (
    params: {
      codingId: string
      cateId: string
    } & RequestDataBase,
  ): Promise<{ companyCoding: CompanyCoding }> => {
    const { ctx, codingId, cateId } = params
    const url = `/api/company-coding/update-cate/${codingId}`

    return this.withData.postJson(url, { cateId }, { ctx })
  }

  updateSubstance = (
    params: {
      codingId: string
      substance: string
    } & RequestDataBase,
  ): Promise<{ companyCoding: CompanyCoding }> => {
    const { ctx, codingId, substance } = params
    const url = `/api/company-coding/update-substance/${codingId}`

    return this.withData.postJson(url, { substance }, { ctx })
  }
  updateTestSubstance = (
    params: {
      codingId: string
      testSubstance?: string
    } & RequestDataBase,
  ): Promise<{ companyCoding: CompanyCoding }> => {
    const { ctx, codingId, testSubstance } = params
    const url = `/api/company-coding/update-test-substance/${codingId}`

    return this.withData.postJson(url, { testSubstance }, { ctx })
  }
}
