import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'
import { SecQuestionTypeKey, StudentGradeKey } from '../../enum-types'
import { txCipher } from '../../lib/PayloadCipher'
import { AttachFile, UserAccount, UserAccountSimple, UserActivity } from '../../model'

/**
 * 사용자 관련 API
 */
export class ProfileApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 사용자 프로필 이미지 업로드
   */
  uploadProfileImage = (
    params: {
      file: Blob
    } & RequestDataBase,
  ): Promise<{ userProfile: UserAccount }> => {
    const url = '/api/user/upload-profile-image'
    const { ctx, file } = params
    const formData = new FormData()
    formData.append('file', file)
    return this.withData.postMultipartAndSecuredResponse(url, formData, { ctx })
  }

  /**
   * 사용자 프로필 이미지 업로드
   * 사용안할것 같다. 나중에 지우자
   */
  uploadTempProfileImage = (
    params: {
      file: Blob
    } & RequestDataBase,
  ): Promise<{ file: AttachFile }> => {
    const url = '/api/user/upload-temp-profile-image'
    const { ctx, file } = params
    const formData = new FormData()
    formData.append('file', file)
    return this.withData.postMultipart(url, formData, { ctx })
  }

  /**
   * 사용자 프로필 이미지 업데이트
   */
  updateProfileImage = (
    params: {
      fileId: string
    } & RequestDataBase,
  ): Promise<{ userProfile: UserAccount }> => {
    const url = '/api/user/update-profile-image'
    const { ctx, fileId } = params
    return this.withData.postAndSecuredResponse(url, { fileId }, { ctx })
  }

  /**
   * 사용자 기분 업데이트
   */
  updateMood = (
    params: {
      mood: string | null | undefined
    } & RequestDataBase,
  ): Promise<{ userProfile: UserAccount }> => {
    const url = '/api/user/update-user-mood'
    const { ctx, mood } = params
    return this.withData.postAndSecuredResponse(url, { mood }, { ctx })
  }

  /**
   * 관리자에 의한 사용자 기분 업데이트
   */
  updateMoodByAdmin = (
    params: {
      mood: string | null | undefined
      accountId: number
    } & RequestDataBase,
  ): Promise<{ userProfile: UserAccount }> => {
    const { ctx, mood, accountId } = params
    const url = `/api/user/update-user-mood2/${accountId}`
    return this.withData.postAndSecuredResponse(url, { mood }, { ctx })
  }

  /**
   * 사용자 프로필 조회
   */
  profileMe = (params: RequestDataBase): Promise<{ userProfile: UserAccount }> => {
    const url = '/api/user/profile-me'
    const { ctx } = params
    return this.withData.postAndSecuredResponse(url, undefined, { ctx })
  }

  /**
   * 다른 사용자의 프로필 조회
   */
  profileOther = (
    params: {
      accountId: number
    } & RequestDataBase,
  ): Promise<{ userProfile: UserAccountSimple }> => {
    const { ctx, accountId } = params
    const url = `/p/api/user/profile-other/${accountId}`
    return this.withData.postAndSecuredResponse(url, undefined, { ctx })
  }

  /**
   * 사용자 활동 이력 조회
   */
  activityList = (params: RequestDataBase): Promise<{ activityList: UserActivity[] }> => {
    const url = '/api/user/activity-list'
    const { ctx } = params
    return this.withData.postAndSecuredResponse(url, undefined, { ctx })
  }

  /**
   * 비밀번호 일치 여부
   * 회원정보를 수정하기 위해 비밀번호가 일치하는지 체크할때 사용
   * 응답에 userProfile이 있으면 비밀번호가 일치, 그렇지 않으면 일치하지 않음을 의미
   */
  pwMatched = (
    params: {
      txId: string
      pw: string
    } & RequestDataBase,
  ): Promise<{
    userProfile?: UserAccount

    // 회원중에 보안질문이 없는 사람도 있다
    q1?: SecQuestionTypeKey
    q2?: SecQuestionTypeKey
    q3?: SecQuestionTypeKey
  }> => {
    const url = '/api/user/pw-match'
    const { ctx, ...restParams } = params
    const encryptedBody = txCipher.encrypt(restParams.txId, restParams)
    return this.withData.postJsonAndSecuredResponse(url, encryptedBody, { ctx })
  }

  /**
   * 프로필 정보 업데이트
   * 보안질문을 포함하여 업데이트 한다
   */
  updateProfile = (
    params: {
      txId: string
      studentGrade: StudentGradeKey
      password?: string
      secQuestion1: SecQuestionTypeKey
      secAnswer1: string
      secQuestion2: SecQuestionTypeKey
      secAnswer2: string
      secQuestion3: SecQuestionTypeKey
      secAnswer3: string
    } & RequestDataBase,
  ): Promise<{ userProfile: UserAccount }> => {
    const url = '/api/user/update-profile'
    const { ctx, ...restParams } = params
    const encryptedBody = txCipher.encrypt(restParams.txId, restParams)
    return this.withData.postJsonAndSecuredResponse(url, encryptedBody, { ctx })
  }

  /**
   * 프로필 정보 업데이트
   * 보안질문은 빼고 업데이트 한다
   */
  updateProfileSimple = (
    params: {
      txId: string
      studentGrade: StudentGradeKey
      password?: string
    } & RequestDataBase,
  ): Promise<{ userProfile: UserAccount }> => {
    const url = '/api/user/update-profile-simple'
    const { ctx, ...restParams } = params
    const encryptedBody = txCipher.encrypt(restParams.txId, restParams)
    return this.withData.postJsonAndSecuredResponse(url, encryptedBody, { ctx })
  }
}
