import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { DSSampleTextData, PagerData } from '../../model'

/**
 * Data Science Sample Text Api
 */
export class DSSampleTextApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * Sample Text 목록 조회
   */
  list = (
    params: {
      rowsPerPage: number
      pageNumber: number
    } & RequestDataBase,
  ): Promise<{ pagerData: PagerData<DSSampleTextData> }> => {
    const url = '/p/api/ds-data/sample-text-list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * Sample Text 정보 조회
   */
  info = (
    params: {
      dataId: number
    } & RequestDataBase,
  ): Promise<{ sampleTextData: DSSampleTextData }> => {
    const { dataId, ...rest } = params
    const url = `/p/api/ds-data/sample-text-data/${dataId}`
    return this.withData.post(url, ...splitParams(rest))
  }
}
