import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'
import { GinsideSession } from '../../model'

/**
 * GinsideApi
 */
export class GinsideApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  startSession = (params: RequestDataBase): Promise<{ ginsideSession: GinsideSession }> => {
    const url = '/api/ginside/start-session'
    const { ctx } = params

    return this.withData.postJson(url, undefined, { ctx })
  }
}
