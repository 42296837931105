import { createStringEnum } from './enum-generic'

/**
 * 상태 타입(최초, 실행중, 성공 또는 에러)
 * UI에서 자주 사용되는 상태 타입
 * boolean으로는 부족한 경우에 사용
 */
export type RunningStateKey = 'first' | 'running' | 'success' | 'error'

/**
 * 상태 타입(최초, 실행중, 완료)
 * UI에서 자주 사용되는 상태 타입
 * boolean으로는 부족한 경우에 사용
 */
export type TriStateKey = 'first' | 'running' | 'done'

/**
 * On/Off 3가지 상태
 */
export type TriOnOffStateKey = 'none' | 'on' | 'off'

/**
 * Select 3가지 상태
 */
export type TriSelectStateKey = 'disabled' | 'selected' | 'unselected'

/**
 * Select 3가지 상태
 */
export type TriVisibleStateKey = 'disabled' | 'visible' | 'hidden'

export type BbsIdKey = 'GALLERY' | 'TALK' | 'QNA' | 'SUGGESTION' | 'NOTICE' | 'EVENT'
export const BbsId: Record<BbsIdKey, string> = {
  GALLERY: '갤러리',
  TALK: '우리들 이야기',
  QNA: '묻고 답하기',
  SUGGESTION: '제안하기',
  NOTICE: '공지사항',
  EVENT: '이벤트',
}

export type OpenSourceCateKey = 'ANDROID' | 'IOS' | 'SERVER' | 'WEB' | 'HWPC'
export const OpenSourceCate: Record<OpenSourceCateKey, string> = {
  WEB: '웹',
  SERVER: '서버',
  ANDROID: '안드로이드',
  IOS: 'iOS',
  HWPC: '하드웨어 PC프로그램',
}

export type ClauseAgreeTypeKey = 'MANDATORY' | 'OPTIONAL' | 'DISPLAY'
export const ClauseAgreeType: Record<ClauseAgreeTypeKey, string> = {
  MANDATORY: '필수',
  OPTIONAL: '선택',
  DISPLAY: '표시',
}

export type AppLogLevelKey = 'D' | 'I' | 'W' | 'E'
export const AppLogLevel: Record<AppLogLevelKey, string> = {
  D: '디버그',
  I: '정보',
  W: '경고',
  E: '에러',
}

export type RunningStatusKey = 'FIRST' | 'RUNNING' | 'SUCCESS' | 'ERROR'
export const RunningStatus: Record<RunningStatusKey, string> = {
  FIRST: '최초',
  RUNNING: '실행중',
  SUCCESS: '실행 완료',
  ERROR: '에러',
}

export type AccountStateKey = 'ACTIVE' | 'INACTIVE' | 'DELETED' | 'LOCKED'
export const AccountState: Record<AccountStateKey, string> = {
  ACTIVE: '디버그',
  INACTIVE: '정보',
  DELETED: '경고',
  LOCKED: '에러',
}

/** @deprecated 삭제예정, i18n 모듈의 supported-locales를 사용해야 함 */
export type I18nLocaleKey = 'ko'
export const I18nLocale: Record<I18nLocaleKey, string> = {
  ko: '한국',
}

export type I18nLangTypeKey = 'KO'
export const I18nLangType = createStringEnum<I18nLangTypeKey>({
  KO: '한국어',
})

export type I18nSectionTypeKey = 'BLOCKLY' // | 'PAINT' | 'GUI' | 'EXTENSION'
export const I18nSectionType = createStringEnum<I18nSectionTypeKey>({
  BLOCKLY: '블록리',
  // PAINT: '페인트',
  // GUI: 'GUI',
  // EXTENSION: '확장',
})

export type BlkToolboxItemKindKey = 'CATE' | 'VARIABLE' | 'PROCEDURE'
export const BlkToolboxItemKind: Record<BlkToolboxItemKindKey, string> = {
  CATE: '카테고리',
  VARIABLE: '변수',
  PROCEDURE: '함수',
}

export type BlkFlyoutItemKindKey = 'BLOCK' | 'BUTTON' | 'LABEL' | 'SEP'
export const BlkFlyoutItemKind: Record<BlkFlyoutItemKindKey, string> = {
  BLOCK: '블록',
  BUTTON: '버튼',
  LABEL: '레이블',
  SEP: '구분선',
}

export type BlkHatKey = 'NONE' | 'CAP'
export const BlkHat: Record<BlkHatKey, string> = {
  NONE: 'NONE',
  CAP: 'CAP',
}

export type BlkCateDomainKey = 'AIMK' | 'GOOGLE'
export const BlkCateDomain: Record<BlkCateDomainKey, string> = {
  AIMK: 'AIMK',
  GOOGLE: 'GOOGLE',
}

export type StudentGradeKey =
  | 'E1'
  | 'E2'
  | 'E3'
  | 'E4'
  | 'E5'
  | 'E6'
  | 'M1'
  | 'M2'
  | 'M3'
  | 'H1'
  | 'H2'
  | 'H3'
  | 'NORMAL'

export const StudentGrade: Record<StudentGradeKey, string> = {
  E1: '초등학교 1학년',
  E2: '초등학교 2학년',
  E3: '초등학교 3학년',
  E4: '초등학교 4학년',
  E5: '초등학교 5학년',
  E6: '초등학교 6학년',
  M1: '중학교 1학년',
  M2: '중학교 2학년',
  M3: '중학교 3학년',
  H1: '고등학교 1학년',
  H2: '고등학교 2학년',
  H3: '고등학교 3학년',
  NORMAL: '일반',
} as const

export type UserAccountStateKey = 'ACTIVE' | 'DELETED' | 'STOPPED' | 'DORMANT' | 'LOCKED'
export const UserAccountState: Record<UserAccountStateKey, string> = {
  ACTIVE: '정상',
  DELETED: '탈퇴',
  STOPPED: '일시사용중지',
  DORMANT: '휴면 계정',
  LOCKED: '계정 잠금',
} as const

export type SecQuestionTypeKey =
  | 'A001'
  | 'A002'
  | 'A003'
  | 'A004'
  | 'A005'
  | 'A006'
  | 'A007'
  | 'A008'
  | 'A009'
export const SecQuestionType: Record<SecQuestionTypeKey, string> = {
  A001: '가장 좋아하는 노래의 제목은 무엇입니까?',
  A002: '처음으로 여행한 도시는 어디입니까?',
  A003: '가장 좋아하는 음식은 무엇입니까?',
  A004: '가장 좋아하는 동물은 무엇입니까?',
  A005: '감명깊게 읽은 책의 제목은 무엇입니까?',
  A006: '가장 친한 친구의 이름은 무엇입니까?',
  A007: '나의 별명은?',
  A008: '아버지의 성함은 무엇입니까?',
  A009: '어머니의 성함은 무엇입니까?',
}

export type BbsPostStateKey = 'ACTIVE' | 'HIDDEN' | 'DELETE'
export const BbsPostState: Record<BbsPostStateKey, string> = {
  ACTIVE: '정상',
  HIDDEN: '숨김',
  DELETE: '삭제',
}

export type BbsSortTypeKey = 'SEEN' | 'RECENT' | 'REPLY'
export const BbsSortType: Record<BbsSortTypeKey, string> = {
  RECENT: '최신순',
  SEEN: '조회순',
  REPLY: '댓글순',
}

export type BbsQueryFieldKey = 'ALL' | 'TITLE' | 'WRITE_USER_NAME'
export const BbsQueryField: Record<BbsQueryFieldKey, string> = {
  ALL: '전체',
  TITLE: '제목',
  WRITE_USER_NAME: '작성자',
}

export type AllianceTypeKey =
  | 'HW_INTERWORK'
  | 'BUY_CODINGPACK'
  | 'EDU_RES_DEV'
  | 'BIZ_ALLIANCE'
  | 'DEAL_SUGGESTION'
  | 'ETC'
export const AllianceType: Record<AllianceTypeKey, string> = {
  HW_INTERWORK: '하드웨어 연동',
  BUY_CODINGPACK: 'KT AI Codiny Pack 구매',
  EDU_RES_DEV: '교육 컨텐츠 개발',
  BIZ_ALLIANCE: '사업 제휴',
  DEAL_SUGGESTION: '거래 제안',
  ETC: '기타',
}

export type FaqSectionTypeKey = 'SITE' | 'AICODINGPACK' | 'NORMAL' | 'TERMINAL' | 'ACCOUNT'
export const FaqSectionType: Record<FaqSectionTypeKey, string> = {
  SITE: '사이트',
  AICODINGPACK: 'KT AI Codiny Pack',
  NORMAL: '일반',
  TERMINAL: '단말 문제해결',
  ACCOUNT: '계정 문제해결',
}

export type DSModelTypeKey =
  | 'REGRESS'
  | 'MULTI_REGRESS'
  | 'LOGISTIC_REGRESS'
  | 'KNN_IMAGE_CLASSIFY'
  | 'KNN_TEXT_CLASSIFY'
  | 'WORD_CLOUD'
  | 'KMEANS'

export const DSModelType: Record<DSModelTypeKey, string> = {
  REGRESS: '단순 회귀분석',
  MULTI_REGRESS: '다중 회귀분석',
  LOGISTIC_REGRESS: '로지스틱 회귀분석',
  WORD_CLOUD: '워드 클라우드',
  KNN_IMAGE_CLASSIFY: '이미지 분류',
  KNN_TEXT_CLASSIFY: '텍스트 분류',
  KMEANS: '비지도 학습',
}

export type DSModelStateKey = 'UPLOADING' | 'OK'
export const DSModelState: Record<DSModelStateKey, string> = {
  UPLOADING: '업로드 중',
  OK: '정상',
}

export type ExApiResponseTypeKey = 'JSON' | 'XML'
export const ExApiResponseType: Record<ExApiResponseTypeKey, string> = {
  JSON: 'json',
  XML: 'xml',
}

export type ObjCateKey =
  | 'CODINY'
  | 'HUMAN'
  | 'ANIMAL'
  | 'PLANT'
  | 'STUFF'
  | 'FOOD'
  | 'CAR'
  | 'BUILDING'
  | 'MATTER'
  | 'MUSIC'
  | 'SPORTS'
  | 'FANTASY'
  | 'GAME'
  | 'NUMBER'
  | 'DEVICE'
  | 'INTERFACE'

export const ObjCate = createStringEnum<ObjCateKey>({
  CODINY: '코디니',
  HUMAN: '사람',
  ANIMAL: '동물',
  PLANT: '식물',
  STUFF: '물건',
  FOOD: '음식',
  CAR: '탈것',
  BUILDING: '건물',
  MATTER: '환경',
  MUSIC: '음악', //추가
  SPORTS: '스포츠', //추가
  FANTASY: '판타지',
  GAME: '게임',
  NUMBER: '숫자', //추가
  DEVICE: '디바이스',
  INTERFACE: '인터페이스',
})

// EXAM: '예제배경', 삭제
export type BgCateKey =
  | 'BG_NATURE'
  | 'BG_OUTDOOR'
  | 'BG_INSIDE'
  | 'BG_SCHOOL'
  | 'BG_CITY'
  | 'BG_PATTERN'
  | 'BG_SPORTS'
  | 'BG_FANTASY'
  | 'BG_ANNIVERSARY'
  | 'BG_CORONA'
  | 'BG_ETC'

export const BgCate = createStringEnum<BgCateKey>({
  BG_NATURE: '자연',
  BG_OUTDOOR: '실외',
  BG_INSIDE: '실내',
  BG_SCHOOL: '학교',
  BG_CITY: '도시',
  BG_PATTERN: '패턴',
  BG_SPORTS: '스포츠',
  BG_FANTASY: '판타지',
  BG_ANNIVERSARY: '기념일',
  BG_CORONA: '코로나',
  BG_ETC: '기타',
})

export type SoundCateKey = 'HUMAN' | 'NATURE' | 'INSTRUMENT' | 'STUFF' | 'PIANO' | 'ETC'
export const SoundCate = createStringEnum<SoundCateKey>({
  HUMAN: '사람',
  NATURE: '자연',
  INSTRUMENT: '악기',
  STUFF: '사물',
  PIANO: '피아노',
  ETC: '기타',
})

export type UserActivityTypeKey =
  | 'LOGIN'
  | 'GALLERY_POST'
  | 'BBS_POST_TALK'
  | 'GALLERY_FAVORED'
  | 'GALLERY_REPLIED'
  | 'GALLERY_CHIMED'
  | 'GALLERY_FAVOR'
  | 'BBS_FAVOR_QNA'
  | 'BBS_FAVOR_TALK'
  | 'GALLERY_REPLY'
  | 'BBS_REPLY_QNA'
  | 'BBS_REPLY_TALK'

export const UserActivityType: Record<
  UserActivityTypeKey,
  { desc: string; score: number; maxCountPerDay: number }
> = {
  LOGIN: { desc: '출석체크', score: 500, maxCountPerDay: 1 },
  GALLERY_POST: { desc: '갤러리에 작품 공유', score: 100, maxCountPerDay: 9999 },
  BBS_POST_TALK: { desc: '우리들이야기 게시물 공유', score: 20, maxCountPerDay: 10 },
  GALLERY_FAVORED: { desc: '나의 작품 좋아요 받음', score: 20, maxCountPerDay: 9999 },
  GALLERY_REPLIED: { desc: '나의 작품 댓글 받음', score: 20, maxCountPerDay: 9999 },
  GALLERY_CHIMED: { desc: '나의 작품 관심 받음', score: 20, maxCountPerDay: 9999 },
  GALLERY_FAVOR: { desc: '갤러리 좋아요', score: 10, maxCountPerDay: 10 },
  BBS_FAVOR_QNA: { desc: '묻고 답하기 좋아요', score: 10, maxCountPerDay: 10 },
  BBS_FAVOR_TALK: { desc: '우리들의 이야기 좋아요', score: 10, maxCountPerDay: 10 },
  GALLERY_REPLY: { desc: '갤러리 댓글 등록', score: 10, maxCountPerDay: 10 },
  BBS_REPLY_QNA: { desc: '묻고 답하기 댓글 등록', score: 10, maxCountPerDay: 10 },
  BBS_REPLY_TALK: { desc: '우리들의 이야기 댓글 등록', score: 10, maxCountPerDay: 10 },
}

export type UserGradeTypeKey =
  | 'AI_STARTER'
  | 'AI_SPECIALIST'
  | 'AI_EXPERT'
  | 'AI_MASTER'
  | 'AI_GRAND_MASTER'
export const UserGradeType: Record<UserGradeTypeKey, string> = {
  AI_STARTER: 'AI스타터',
  AI_SPECIALIST: 'AI스페셜리스트',
  AI_EXPERT: 'AI엑스퍼트',
  AI_MASTER: 'AI마스터',
  AI_GRAND_MASTER: 'AI그랜드마스터',
}

export type GallerySortTypeKey = 'SEEN' | 'RECENT' | 'REPLY'
export const GallerySortType: Record<GallerySortTypeKey, string> = {
  RECENT: '최신순',
  SEEN: '조회순',
  REPLY: '댓글순',
}

export type GalleryQueryFieldKey = 'ALL' | 'TITLE' | 'WRITE_USER_NAME'
export const GalleryQueryField: Record<GalleryQueryFieldKey, string> = {
  ALL: '전체',
  TITLE: '제목',
  WRITE_USER_NAME: '작성자',
}

// 나중에 초중급은 A200 이런식으로 추가하자, SQL 정렬순서 고려
export type BlockEduLevelKey = 'A000' | 'A500' | 'A900'
export const BlockEduLevel: Record<BlockEduLevelKey, string> = {
  A000: '초급',
  A500: '중급',
  A900: '고급',
}

export type GalleryStampKind = 'NORMAL' | 'GREAT' | 'POPULAR'

export type CompanyCodingStateKey = 'ASK' | 'FAIL' | 'SUCCESS'
export const CompanyCodingState: Record<CompanyCodingStateKey, string> = {
  ASK: '테스트 요청',
  FAIL: '테스트 실패',
  SUCCESS: '테스트 성공',
}

export type SimulStateKey = 'REQ' | 'DENY' | 'SHOW' | 'HIDE'
export const SimulState: Record<SimulStateKey, string> = {
  REQ: '승인 요청',
  DENY: '승인 거절',
  SHOW: '승인',
  HIDE: '감추기',
}

export type BlockDefinitionTypeKey = 'JSON' | 'JS'
export const BlockDefinitionType: Record<BlockDefinitionTypeKey, string> = {
  JSON: 'JSON',
  JS: 'JavaScript',
}

export type SimulFlyoutItemKind = 'UNIT_BLOCK' | 'COMPOSITE_BLOCK' | 'LABEL' | 'BUTTON'
export type SimulFlyoutBlockKind = 'UNIT_BLOCK' | 'COMPOSITE_BLOCK'

export type UserKickReasonKey = 'ILGMTRL' | 'PORN' | 'SPAM' | 'ABUSE' | 'ILBE' | 'ETC'
export const UserKickReason: Record<UserKickReasonKey, string> = {
  ILGMTRL: '불법자료', // illegal material
  PORN: '음란',
  SPAM: '스팸/광고',
  ABUSE: '욕설',
  ILBE: '일베',
  ETC: '기타',
}

export type UserWithdrawStateKey = 'PENDING' | 'FINISH' | 'ERROR'
export const UserWithdrawState: Record<UserWithdrawStateKey, string> = {
  PENDING: '처리 대기중',
  FINISH: '처리 완료',
  ERROR: '처리 실패',
}

export type AimkEventStateKey =
  | 'BEFORE'
  | 'PUBLISHED'
  | 'STARTED'
  | 'WINNERING'
  | 'ANNOUNCED'
  | 'UNPUBLISHED'
export const AimkEventState: Record<AimkEventStateKey, string> = {
  BEFORE: '노출전',
  PUBLISHED: '응모전',
  STARTED: '진행중',
  WINNERING: '당첨자 선정중',
  ANNOUNCED: '당첨자 발표',
  UNPUBLISHED: '종료됨',
}

export type AimkEventFieldKindKey = 'EMAIL' | 'GALLERY' | 'URL' | 'ADDR' | 'PHONE' | 'TEXT' | 'INT'
export const AimkEventFieldKind: Record<AimkEventFieldKindKey, string> = {
  EMAIL: '이메일',
  GALLERY: '작품',
  ADDR: '집주소',
  URL: 'URL',
  PHONE: '모바일폰 번호',
  TEXT: '텍스트',
  INT: '숫자',
}
export const AimkEventFieldMaxLength: Record<AimkEventFieldKindKey, number> = {
  EMAIL: 255,
  GALLERY: 512,
  ADDR: 512,
  URL: 512,
  PHONE: 20, // +08210-1234-4567
  TEXT: 0, // 직접 입력
  INT: 0, // 직접 입력
}

export type AimkEventFilePropKey =
  | 'icon'
  | 'topBanner1'
  | 'topBanner2'
  | 'guideImage'
  | 'prizeImage'
  | 'applicants'
export type OneTimeActionKey =
  | 'BBS_WRITE' // login required
  | 'BBS_REPLY' // login required
  | 'LOGIN'
  | 'JOIN'
  | 'WITHDRAW' // login required
  | 'JEHUE_INQ'
  | 'GAL_WRITE' // login required
  | 'GAL_REPLY' // login required
  | 'PW_FIND'
  | 'PROFILE_EDIT' // login required
  | 'PW_CHECK' // login required
  | 'QUICK_DRAW' // login required

export type SupervisedModelIdAndType = {
  modelId: string
  modelType: 'KNN_TEXT_CLASSIFY' | 'KNN_IMAGE_CLASSIFY'
}

export type UnsupervisedModelIdAndType = {
  modelId: string
  modelType: 'KMEANS'
}

export type DSModelIdAndType = {
  modelId: string
  modelType: 'REGRESS' | 'MULTI_REGRESS' | 'LOGISTIC_REGRESS'
}

export type ModelIdAndType =
  | SupervisedModelIdAndType
  | UnsupervisedModelIdAndType
  | DSModelIdAndType

export type QDQuizStep =
  | 'empty' // 퀴즈가 없는 상태
  | 'word_ready' // 단어가 준비된 상태
  | 'drawing_before' // 단어를 컨펌해서 그릴 수 있는 상태
  | 'drawing_started' // 첫번째 점을 그리기 시작
  | 'drawing' // 그리는 중
  | 'drawing_after' // 그리기 완료 - (정답을 맞춤, 못맞춤, 취소됨)
  | 'finished' // 마지막 그리기 완료 - (정답을 맞춤, 못맞춤, 취소됨)

export type QuickDrawWordSearchSortFieldKey = 'word' | 'imageCount' | 'successCount' | 'failCount'

export const QuickDrawWordSearchSortField: Record<QuickDrawWordSearchSortFieldKey, string> = {
  word: '이름순',
  imageCount: '이미지순',
  successCount: '성공순',
  failCount: '실패순',
}

export type AdminAccessPolicyCodeKey = 'ANY' | 'SOME'
export const AdminAccessPolicyCode: Record<AdminAccessPolicyCodeKey, string> = {
  ANY: '모두 허용',
  SOME: '일부 허용',
}

export type AdminBlockReasonKey = 'PW_FAIL' | 'ETC'
export const AdminBlockReason: Record<AdminBlockReasonKey, string> = {
  PW_FAIL: '비밀번호 실패',
  ETC: '기타',
}

export type CustomRendererTypeKey = 'auto' | 'thrasos' | 'zelos'
export const CustomRendererType: Record<CustomRendererTypeKey, string> = {
  auto: '자동 선택',
  thrasos: '기본 모양',
  zelos: '스크래치 모양',
}

export type AdminLogKindKey = 'ACCESS' | 'PERSON' | 'ROLE'
export const AdminLogKind: Record<AdminLogKindKey, string> = {
  ACCESS: '접근',
  PERSON: '개인정보처리',
  ROLE: '관리자 권한',
}

export type UserLogKindKey = 'ACCESS'
export const UserLogKind: Record<UserLogKindKey, string> = {
  ACCESS: '접근',
}

export type BooleanSearchTypeKey = 'BOTH' | 'SUCCESS_ONLY' | 'FAIL_ONLY'
export const BooleanSearchType: Record<BooleanSearchTypeKey, string> = {
  BOTH: '모두',
  SUCCESS_ONLY: '성공만',
  FAIL_ONLY: '실패만',
}

// kmcis 본인인증용
export type KmcisPhoneCorpKey = 'KTF' | 'SKT' | 'LGT' | 'SKM' | 'KTM' | 'LGM'

export const KmcisPhoneCorp: Record<KmcisPhoneCorpKey, string> = {
  KTF: 'KT',
  SKT: 'SKT',
  LGT: 'LG U+',
  SKM: 'SKTmvno',
  KTM: 'KTmvno',
  LGM: 'LGU+mvno',
}

// kmcis 본인인증용
export type KmcisGenderKey = 'MALE' | 'FEMALE'
export const KmcisGender: Record<KmcisGenderKey, string> = {
  MALE: '남성',
  FEMALE: '여성',
}

// kmcis 본인인증용
export type KmcisNationKey = 'LOCAL' | 'FOREIGNER'
export const KmcisNation: Record<KmcisNationKey, string> = {
  LOCAL: '내국인',
  FOREIGNER: '외국인',
}

// kmcis 페이지 ID
export type KmcisPageIdKey = 'EVENT_APPL' | 'ALLIANCE'
export const KmcisPageId: Record<KmcisPageIdKey, string> = {
  EVENT_APPL: '이벤트 응모',
  ALLIANCE: '제휴문의',
}

// kmcis 상태
export type KmcisStateKey = 'FIRST' | 'SEND' | 'SUCCESS' | 'FAIL' | 'COMPLETED'
export const KmcisState: Record<KmcisStateKey, string> = {
  FIRST: '최초 상태',
  SEND: '요청 보냄',
  SUCCESS: '성공',
  FAIL: '실패',
  COMPLETED: '완료',
}

export type HwOsKey = 'windows' | 'macos' | 'linux'
export const HwOs: Record<HwOsKey, string> = {
  windows: '윈도우즈',
  macos: '맥OS',
  linux: '리눅스',
} as const

export type HwArchKey = 'x86' | 'x64'
export const HwArch: Record<HwArchKey, string> = {
  x86: 'x86',
  x64: 'x64',
} as const

export type CodsId = 'incheon' | 'ktcsnewsac'
export const Cods: Record<CodsId, string> = {
  incheon: '인천광역시교육청',
  ktcsnewsac: 'KTCS디지털새싹',
} as const

export type GanStyleKey = 'styleTransfer' | 'selfie2anime' | 'bard'
export const GanStyle: Record<GanStyleKey, string> = {
  styleTransfer: '화풍 변환',
  selfie2anime: '애니메이션 변환',
  bard: '초거대 언어 모델',
}
export type GuideCateKey = 'ARDUINO_BLOCK'
export const GuideCate: Record<GuideCateKey, string> = {
  ARDUINO_BLOCK: '아두이노 블록 도움말',
}
