import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { AdminLogKindKey, BooleanSearchTypeKey } from '../../enum-types'
import { AdminAccessLog, AdminPwFail, PagerData, UserAccessLog } from '../../model'

export class AccessLogApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 관리자 접속 이력 조회
   */
  adminAccessLogList = (
    params: {
      pageNumber: number
      rowsPerPage: number
      kinds: AdminLogKindKey[]
      resultType: BooleanSearchTypeKey
      workName?: string
      email?: string
      clientIp?: string
    } & RequestDataBase,
  ): Promise<{ adminAccessLogPageData: PagerData<AdminAccessLog> }> => {
    const url = '/admin/api/log/admin-access-log-list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 비밀번호 실패 이력 조회
   */
  adminPwFailList = (
    params: {
      pageNumber: number
      rowsPerPage: number
      email?: string
      ip?: string
    } & RequestDataBase,
  ): Promise<{ adminPwFailPageData: PagerData<AdminPwFail> }> => {
    const url = '/admin/api/log/admin-pw-fail-list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 사용자 접속 이력 조회
   */
  userAccessLogList = (
    params: {
      pageNumber: number
      rowsPerPage: number
      kinds: AdminLogKindKey[]
      resultType: BooleanSearchTypeKey
      workName?: string
      email?: string
      clientIp?: string
    } & RequestDataBase,
  ): Promise<{ userAccessLogPageData: PagerData<UserAccessLog> }> => {
    const url = '/admin/api/log/user-access-log-list'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
