import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'
import { SubwayDayTypeKey, SubwayDirectionKey, SubwayStationId } from '../../makerkit'

/**
 * Subway Api
 */
export class SubwayApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  getLastTrainTime = ({
    ctx,
    dayType,
    direction,
    stationId,
  }: RequestDataBase & {
    dayType: SubwayDayTypeKey
    direction: SubwayDirectionKey
    stationId: SubwayStationId
  }): Promise<{
    resultData: string
  }> => {
    const url = '/p/ai-api/v1/subway/last-time'
    const params = {
      dayType,
      direction,
      stationId,
    }
    return this.withData.postJson(url, params, { ctx })
  }
}
