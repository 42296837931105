import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'
import { CodingpackDailyCount, CodingpackModelCount, CodingpackMonthlyCount } from '../../model'

/**
 * CodingpackStat Api
 */
export class CodingpackStatApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 일별 브라우저 접속 통계 조회
   */
  dailyList = (
    params: {
      recentDayCount: number
    } & RequestDataBase,
  ): Promise<{ dailyList: CodingpackDailyCount[] }> => {
    const { ctx, recentDayCount } = params
    const url = `/admin/api/codingpack-stats/daily/${recentDayCount}`
    return this.withData.post(url, undefined, { ctx })
  }

  /**
   * 월별 브라우저 접속 통계 조회
   */
  monthlyList = (params: RequestDataBase): Promise<{ monthlyList: CodingpackMonthlyCount[] }> => {
    const url = '/admin/api/codingpack-stats/monthly'
    const { ctx } = params
    return this.withData.post(url, undefined, { ctx })
  }

  modelCountList = (
    params: {
      recentDayCount: number
    } & RequestDataBase,
  ): Promise<{ modelCountList: CodingpackModelCount[] }> => {
    const { ctx, recentDayCount } = params
    const url = `/admin/api/codingpack-stats/models/${recentDayCount}`
    return this.withData.post(url, undefined, { ctx })
  }
}
