export type TtsGenderKey = 'MALE' | 'FEMALE' | 'NEUTRAL'
export const TtsGender: Record<TtsGenderKey, string> = {
  MALE: '남성',
  FEMALE: '여성',
  NEUTRAL: '부드러운',
}

export type TtsSpeedKey = 'VERY_SLOW' | 'SLOW' | 'NORMAL' | 'FAST' | 'VERY_FAST'
export const TtsSpeed: Record<TtsSpeedKey, { title: string; speedValue: number }> = {
  VERY_SLOW: { title: '매우 느린', speedValue: 0.5 },
  SLOW: { title: '느린', speedValue: 0.75 },
  NORMAL: { title: '보통', speedValue: 1 },
  FAST: { title: '빠른', speedValue: 1.25 },
  VERY_FAST: { title: '매우빠른', speedValue: 1.5 },
}

export type TtsToneKey = 'VERY_LOW' | 'LOW' | 'NORMAL' | 'HIGH' | 'VERY_HIGH'
export const TtsTone: Record<TtsToneKey, { title: string; toneValue: number }> = {
  VERY_LOW: { title: '매우 낮은', toneValue: -6 },
  LOW: { title: '낮은', toneValue: -3 },
  NORMAL: { title: '보통', toneValue: 0 },
  HIGH: { title: '높은', toneValue: 3 },
  VERY_HIGH: { title: '매우 높은', toneValue: 6 },
}

export type TtsLangKey =
  | 'ko-KR'
  | 'ja-JP'
  | 'en-GB'
  | 'en-US'
  | 'zh-CN'
  | 'zh-TW'
  | 'da-DK'
  | 'nl-NL'
  | 'fr-CA'
  | 'fr-FR'
  | 'de-DE'
  | 'it-IT'
  | 'nb-NO'
  | 'pl-PL'
  | 'pt-BR'
  | 'pt-PT'
  | 'ru-RU'
  | 'sk-SK'
  | 'es-ES'
  | 'sv-SE'
  | 'tr-TR'
  | 'uk-UA'
  | 'th-TH'
