import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'
import { ChangeHistory, PagerData, PagingQuery } from '../../model'

/**
 * ChangeHistory API
 */
export class ChangeHistoryApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  list = (
    params: PagingQuery & RequestDataBase,
  ): Promise<{ pagerData: PagerData<ChangeHistory> }> => {
    const url = '/p/api/change-history/list'
    const { ctx, rowsPerPage, pageNumber } = params

    return this.withData.postJson(
      url,
      {
        rowsPerPage,
        pageNumber,
      },
      { ctx },
    )
  }

  info = (
    params: { historyId: number } & RequestDataBase,
  ): Promise<{ changeHistory: ChangeHistory }> => {
    const { ctx, historyId } = params
    const url = `/p/api/change-history/info/${historyId}`

    return this.withData.postJson(url, undefined, { ctx })
  }

  create = (
    params: {
      title: string
      ymd: string
      substance: string
      deleted: boolean
    } & RequestDataBase,
  ): Promise<{ changeHistory: ChangeHistory }> => {
    const url = '/api/change-history/create'
    const { ctx, title, ymd, substance, deleted } = params
    return this.withData.postJson(url, { title, ymd, substance, deleted }, { ctx })
  }

  update = (
    params: {
      historyId: number
      title: string
      ymd: string
      substance: string
      deleted: boolean
    } & RequestDataBase,
  ): Promise<{ changeHistory: ChangeHistory }> => {
    const { ctx, historyId, title, ymd, substance, deleted } = params
    const url = `/api/change-history/update/${historyId}`
    return this.withData.postJson(url, { title, ymd, substance, deleted }, { ctx })
  }

  delete = async (params: { historyId: number } & RequestDataBase): Promise<void> => {
    const { ctx, historyId } = params
    const url = `/api/change-history/delete/${historyId}`
    await this.helper.post(url, undefined, { ctx })
  }

  /**
   * 변경이력 좋아요 추가
   * 좋아요 건수를 응답
   */
  favorAdd = (
    params: {
      historyId: number
    } & RequestDataBase,
  ): Promise<{ count: number }> => {
    const { ctx, historyId } = params
    const url = `/api/change-history/favor/add/${historyId}`
    return this.withData.post(url, undefined, { ctx })
  }

  /**
   * 변경이력 좋아요 삭제
   * 좋아요 건수를 응답
   */
  favorRemove = (
    params: {
      historyId: number
    } & RequestDataBase,
  ): Promise<{ count: number }> => {
    const { ctx, historyId } = params
    const url = `/api/change-history/favor/remove/${historyId}`
    return this.withData.post(url, undefined, { ctx })
  }
}
