import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { Lesson, LessonProject, UploadedFile } from '../../model'

/**
 * LessonProject Api
 */
export class LessonProjectApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  create = (
    params: {
      lessonId: string
      projectName: string
      seq: number
      genFileId: string
      imageFileId: string
    } & RequestDataBase,
  ): Promise<{ lessonProject: LessonProject }> => {
    const url = '/api/lesson-project/create'
    return this.withData.postJson(url, ...splitParams(params))
  }

  update = (
    params: {
      projectId: number
      projectName: string
      seq: number
      genFileId: string
      imageFileId: string
    } & RequestDataBase,
  ): Promise<{ lessonProject: LessonProject }> => {
    const { projectId, ...rest } = params
    const url = `/api/lesson-project/update/${projectId}`
    return this.withData.postJson(url, ...splitParams(rest))
  }

  /**
   * Gen 파일 업로드
   */
  uploadGenFile = (
    params: {
      file: Blob
    } & RequestDataBase,
  ): Promise<{ file: UploadedFile }> => {
    const { file, ctx } = params
    const url = '/api/lesson-project/gen-upload'

    const formData = new FormData()
    formData.append('file', file, 'file.gen')
    return this.withData.postMultipart(url, formData, { ctx })
  }

  /**
   * 썸네일 파일 업로드
   */
  uploadImageFile = (
    params: {
      file: Blob
    } & RequestDataBase,
  ): Promise<{ file: UploadedFile }> => {
    const { file, ctx } = params
    const url = '/api/lesson-project/img-upload'

    const formData = new FormData()
    formData.append('file', file, 'img.png')
    return this.withData.postMultipart(url, formData, { ctx })
  }

  /**
   * Lesson Project 삭제
   */
  delete = async (
    params: {
      projectId: number
    } & RequestDataBase,
  ): Promise<void> => {
    const { projectId, ...rest } = params
    const url = `/api/lesson-project/delete/${projectId}`
    await this.helper.post(url, ...splitParams(rest))
  }

  info = (
    params: {
      projectId: number
    } & RequestDataBase,
  ): Promise<{ lessonProject: LessonProject; lesson: Lesson }> => {
    const { projectId, ...rest } = params
    const url = `/p/api/lesson-project/info/${projectId}`
    return this.withData.postJson(url, ...splitParams(rest))
  }
}
