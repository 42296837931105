import useStore from '@/store/useStore'
import { observer } from 'mobx-react'
import React from 'react'

type Props = {
  children?: React.ReactNode
}

function I18nMsgEnsureHoc(props: Props) {
  const intlStore = useStore().intlStore

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{intlStore.hasLoaded && props.children}</>
}

export default observer(I18nMsgEnsureHoc)
