import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams, WasUrlHelper } from '../../api'
import { DSSampleTextData, PagerData } from '../../model'

/**
 * Data Science Sample Text Api
 */
export class DSSampleTextApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * Sample Text 목록 조회 : 페이징
   */
  list = async (
    params: {
      rowsPerPage: number
      pageNumber: number
    } & RequestDataBase,
  ): Promise<{ pagerData: PagerData<DSSampleTextData> }> => {
    const url = `/admin/api/ds-data/sample-text-list`
    const { rowsPerPage, pageNumber, ctx } = params

    const response: { pagerData: PagerData<DSSampleTextData> } = await this.withData.postJson(
      url,
      { rowsPerPage, pageNumber },
      { ctx },
    )
    response.pagerData.elements?.forEach((item) => {
      WasUrlHelper.replaceRelative(item, 'dataFileUrl')
    })
    return response
  }

  /**
   * Sample Text 정보 조회
   */
  info = async (
    params: {
      dataId: number
    } & RequestDataBase,
  ): Promise<{ sampleTextData: DSSampleTextData }> => {
    const { dataId, ...rest } = params
    const url = `/admin/api/ds-data/sample-text-data/${dataId}`

    const response: { sampleTextData: DSSampleTextData } = await this.withData.post(
      url,
      ...splitParams(rest),
    )
    WasUrlHelper.replaceRelative(response.sampleTextData, 'dataFileUrl')
    return response
  }

  /**
   * Sample Text 생성
   */
  create = async (
    params: {
      title: string
      subtitle: string
      substance?: string
      dataProvider?: string
      fileId: string
    } & RequestDataBase,
  ): Promise<{ sampleTextData: DSSampleTextData }> => {
    const url = '/admin/api/ds-data/sample-text-create'

    const response: { sampleTextData: DSSampleTextData } = await this.withData.postJson(
      url,
      ...splitParams(params),
    )
    WasUrlHelper.replaceRelative(response.sampleTextData, 'dataFileUrl')
    return response
  }

  /**
   * Sample Text 한건 업데이트
   */
  update = async (
    params: {
      dataId: number
      title: string
      subtitle: string
      substance?: string
      dataProvider?: string
      fileId: string
    } & RequestDataBase,
  ): Promise<{ sampleTextData: DSSampleTextData }> => {
    const { dataId, ...rest } = params
    const url = `/admin/api/ds-data/sample-text-update/${dataId}`

    const response: { sampleTextData: DSSampleTextData } = await this.withData.postJson(
      url,
      ...splitParams(rest),
    )

    WasUrlHelper.replaceRelative(response.sampleTextData, 'dataFileUrl')
    return response
  }

  /**
   * Sample Text 삭제
   */
  delete = async (
    params: {
      dataId: number
    } & RequestDataBase,
  ): Promise<void> => {
    const { dataId, ...rest } = params
    const url = `/admin/api/ds-data/sample-text-delete/${dataId}`

    await this.helper.post(url, ...splitParams(rest))
  }

  /**
   * Sample Text 파일 생성
   */
  createFile = (
    params: {
      text: string
    } & RequestDataBase,
  ): Promise<{ fileId: string }> => {
    const url = '/admin/api/ds-data/sample-text-create-file'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
