import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { AiCache } from '../../model'

/**
 * 캐시 API
 */
export class AiCacheApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  list = (params: RequestDataBase): Promise<{ cacheList: AiCache[] }> => {
    const url = '/admin/api/ai-cache/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  disable = async (params: { cacheId: string } & RequestDataBase): Promise<void> => {
    const { cacheId, ...rest } = params
    const url = `/admin/api/ai-cache/disable/${cacheId}`
    await this.helper.postJson(url, ...splitParams(rest))
  }

  enable = async (params: { cacheId: string } & RequestDataBase): Promise<void> => {
    const { cacheId, ...rest } = params
    const url = `/admin/api/ai-cache/enable/${cacheId}`
    await this.helper.postJson(url, ...splitParams(rest))
  }
}
