import { AllianceTypeKey } from '../../enum-types'
import { AttachFile } from '../../model'
import { RequestDataBase, ApiHelper, ApiHelperWithData } from '../../api'

/**
 * 제휴 문의 API
 */
export class AllianceInqApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 문의 작성
   */
  save = async (
    params: {
      kmcisReqId: string
      txId: string
      companyName: string
      allianceType: AllianceTypeKey
      chargePersonName?: string
      email: string
      title: string
      substance: string
      fileIds: string[]
    } & RequestDataBase,
  ) => {
    const url = '/p/api/alliance-inq/save'
    const { ctx, ...rest } = params
    await this.helper.post(url, rest, { ctx })
  }

  /**
   * 첨부파일 업로드
   */
  uploadAttachFile = (
    params: {
      file: File
    } & RequestDataBase,
  ): Promise<{ file: AttachFile }> => {
    const url = '/p/api/alliance-inq/upload'
    const { ctx, file } = params
    const formData = new FormData()
    formData.append('file', file, file.name) // formData.append('file', file)

    return this.withData.postMultipart(url, formData, { ctx })
  }
}
