import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { LessonForStudent, LessonSimple } from '../../model'

/**
 * LessonForStudent Api
 */
export class LessonForStudentApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  list = (params: RequestDataBase): Promise<{ lessonList: LessonSimple[] }> => {
    const url = '/p/api/lesson-for-student/list'
    return this.withData.post(url, ...splitParams(params))
  }

  info = (
    params: { lessonId: string } & RequestDataBase,
  ): Promise<{ lesson: LessonForStudent }> => {
    const { lessonId, ...rest } = params
    const url = `/p/api/lesson-for-student/info/${lessonId}`
    return this.withData.post(url, ...splitParams(rest))
  }
}
