import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'
import {
  SimulButton,
  SimulCompositeBlock,
  SimulFlyoutItem,
  SimulLabel,
  SimulUnitBlock,
} from '../../model'

/**
 * SimulFlyoutItem Api
 */
export class SimulFlyoutItemApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 플라이아웃 아이템 저장
   * 정렬순서를 저장한다.
   */
  save = (
    params: {
      simulId: number
      fids: string[]
    } & RequestDataBase,
  ): Promise<{ flyoutItemList: SimulFlyoutItem[] }> => {
    const url = '/api/simul-flyout-item/save'
    const { simulId, fids, ctx } = params

    return this.withData.postJson(url, { simulId, fids }, { ctx })
  }

  /**
   * 플라이아웃 목록 조회
   */
  list = (
    params: {
      simulId: number
    } & RequestDataBase,
  ): Promise<{ flyoutItemList: SimulFlyoutItem[] }> => {
    const { simulId, ctx } = params
    const url = `/api/simul-flyout-item/list/${simulId}`

    return this.withData.post(url, undefined, { ctx })
  }

  /**
   * 플라이아웃 오브젝트 목록 조회
   */
  objects = (
    params: {
      simulId: number
    } & RequestDataBase,
  ): Promise<{
    flyoutItems: SimulFlyoutItem[]
    labels: SimulLabel[]
    buttons: SimulButton[]
    unitBlocks: SimulUnitBlock[]
    compositeBlocks: SimulCompositeBlock[]
  }> => {
    const { simulId, ctx } = params
    const url = `/api/simul-flyout-item/objects/${simulId}`

    return this.withData.post(url, undefined, { ctx })
  }
}
