import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'

/**
 * TextDetect Api
 */
export class TextDetectApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  detectText = (
    params: {
      imageDataURL: string
    } & RequestDataBase,
  ): Promise<{ texts: string[] }> => {
    const url = '/p/ai-api/v1/vision/text/detect'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
