import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { BoundingPoly } from '../../model'

/**
 * CropHintDetect Api
 */
export class CropHintDetectApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  detectCropHint = (
    params: {
      imageDataURL: string
    } & RequestDataBase,
  ): Promise<{ boundingPoly: BoundingPoly }> => {
    const url = '/p/ai-api/v1/vision/crop-hint/detect'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
