import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { LandmarkDetectionResult } from '../../model'

/**
 * LandmarkDetect Api
 */
export class LandmarkDetectApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  detectLandmarks = (
    params: {
      imageDataURL: string
    } & RequestDataBase,
  ): Promise<{ results: LandmarkDetectionResult[] }> => {
    const url = '/p/ai-api/v1/vision/landmark/detect'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
