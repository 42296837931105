import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { BlkCateDomainKey } from '../../enum-types'
import { AttachFile, BlkBlockStyle, BlkCategoryStyle } from '../../model'

/**
 * Block Theme API
 */
export class BlockThemeApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 카테고리 스타일 목록 조회
   */
  cateStyles = (
    params: RequestDataBase & { cateDomain?: BlkCateDomainKey },
  ): Promise<{
    cateStyleList: BlkCategoryStyle[]
  }> => {
    const url = '/admin/api/blk-theme/cate/list'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 카테고리 스타일 저장
   */
  cateStyleSave = async (
    params: {
      cateId: string
      colour: string
      memo?: string
    } & RequestDataBase,
  ): Promise<void> => {
    const { cateId, ...rest } = params
    const url = `/admin/api/blk-theme/cate/save/${cateId}`
    await this.helper.post(url, ...splitParams(rest))
  }

  /**
   * 카테고리 아이콘 업로드
   */
  cateIconUpload = (
    params: RequestDataBase & {
      cateId: string
      isActive: boolean
      file: Blob
    },
  ): Promise<{ file: AttachFile }> => {
    const { cateId, isActive, file, ctx } = params
    const url = `/admin/api/blk-theme/cate/icon-upload/${cateId}/${isActive ? 'true' : 'false'}`
    const formData = new FormData()
    formData.append('file', file)
    return this.withData.postMultipart(url, formData, { ctx })
  }

  /**
   * 카테고리 스타일 삭제
   */
  cateStyleDelete = async (
    params: RequestDataBase & {
      cateId: string
    },
  ): Promise<void> => {
    const { cateId, ...rest } = params
    const url = `/admin/api/blk-theme/cate/delete/${cateId}`
    await this.helper.post(url, ...splitParams(rest))
  }

  /**
   * 블록 스타일 목록 조회
   */
  blockStyles = (
    params: RequestDataBase,
  ): Promise<{
    blockStyleList: BlkBlockStyle[]
  }> => {
    const url = '/admin/api/blk-theme/block/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 블록 스타일 저장
   * TODO hat = cap or none
   */
  blockStyleSave = async (
    params: RequestDataBase & {
      blockStyleId: string
      colourPrimary: string
      colourSecondary?: string
      colourTertiary?: string
      hat?: 'cap' | 'none'
      memo?: string
    },
  ): Promise<void> => {
    const { blockStyleId, ...rest } = params
    const url = `/admin/api/blk-theme/block/save/${blockStyleId}`
    await this.helper.post(url, ...splitParams(rest))
  }

  /**
   * 블록 스타일 삭제
   */
  blockStyleDelete = async (
    params: RequestDataBase & {
      blockStyleId: string
    },
  ): Promise<void> => {
    const { blockStyleId, ...rest } = params
    const url = `/admin/api/blk-theme/block/delete/${blockStyleId}`
    await this.helper.post(url, ...splitParams(rest))
  }
}
