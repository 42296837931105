export type ObjectAnimationTypeKey = 'sprite' | 'sprite_multi' | 'onoff' | 'image'
export const ObjectAnimationType: Record<ObjectAnimationTypeKey, string> = {
  sprite: '스프라이트',
  sprite_multi: '여러 이미지로 스프라이트',
  onoff: '온오프 이미지(애니메이션 없고, On과 Off 상태가 있음)',
  image: '기본 이미지(애니메이션 없음)',
}

export type ImageFormat = 'svg' | 'png' | 'jpg'

export type ObjectImageSpriteOptions = {
  spriteWidth: number
  spriteHeight: number
  frameTime: number
}

export type ObjectImageOnOffOptions = {
  isOn: boolean
}

export interface ObjectImage {
  objectId: string
  objectName: string
  animationType: ObjectAnimationTypeKey
  imageFormat: ImageFormat
  imageUrls: string[] // or dataUrls
  isBackground: boolean
  x: number
  y: number
  width: number
  height: number
  angle: number
  scaleX: number
  scaleY: number
  flipX: boolean
  flipY: boolean
  visible: boolean
  selectable: boolean

  spriteOptions?: ObjectImageSpriteOptions
  onOffOptions?: ObjectImageOnOffOptions
}

// 오브젝트 이미지가 fabric이미지로 변환하고
// 반대로, fabric이미지가 오브젝트 이미지로 변환하려면
// 몇 가지 부가적인 정보가 필요하다
export interface ObjectImageMeta {
  objectId: string
  isBackground: boolean
  imageFormat: ImageFormat
  animationType: ObjectAnimationTypeKey
  imageUrls: string[]
  objectName: string
  spriteOptions?: ObjectImageSpriteOptions
  onOffOptions?: ObjectImageOnOffOptions
  saying?: {
    text: string
    expireAt: number
  }
}
