import UserGrade1Icon from '@/icons/UserGrade1'
import UserGrade2Icon from '@/icons/UserGrade2'
import UserGrade3Icon from '@/icons/UserGrade3'
import UserGrade4Icon from '@/icons/UserGrade4'
import UserGrade5Icon from '@/icons/UserGrade5'
import { scoreToUserGrade, UserGradeType, UserGradeTypeKey } from '@aimk/domain'
import { Box, Tooltip } from '@mui/material'
import clsx from 'clsx'
import React, { useMemo } from 'react'

type Props = {
  size?: 'small' | 'medium' | 'large' | number
  score: number | undefined
  className?: string
  showTooltip?: boolean
}

const USER_GRADE_ICONS = [
  UserGrade1Icon,
  UserGrade2Icon,
  UserGrade3Icon,
  UserGrade4Icon,
  UserGrade5Icon,
]

const GradeIcon = React.forwardRef<HTMLOrSVGElement, { grade: UserGradeTypeKey }>((props, ref) => {
  const { grade } = props
  let idx = Object.keys(UserGradeType).indexOf(grade)
  if (idx < 0) idx = 0
  const Comp = USER_GRADE_ICONS[idx]
  return <Comp ref={ref} />
})
GradeIcon.displayName = 'GradeIcon'

export default function UserGradeIcon(props: Props) {
  const { className, size = 'small', score, showTooltip = true } = props
  const userGrade = useMemo(() => scoreToUserGrade(score ?? 0), [score])

  return (
    <Box
      className={clsx('UserGradeIcon-root', className)}
      sx={[
        {
          ml: '2px',
        },
        typeof size === 'number' && {
          width: size,
          height: size,
        },
        size === 'small' && {
          '& .UserGradeIcon-grade': {
            width: 12,
            height: 12,
          },
        },
        size === 'medium' && {
          '& .UserGradeIcon-grade': {
            width: 16,
            height: 16,
          },
        },
        size === 'large' && {
          '& .UserGradeIcon-grade': {
            width: 24,
            height: 24,
          },
        },
      ]}
    >
      <Box className="UserGradeIcon-grade" sx={{ lineHeight: 1 }}>
        {showTooltip && (
          <Tooltip title={UserGradeType[userGrade] ?? 'AI 스타터'}>
            <GradeIcon grade={userGrade} />
          </Tooltip>
        )}
        {!showTooltip && <GradeIcon grade={userGrade} />}
      </Box>
    </Box>
  )
}
