import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'
import { SoundCateKey } from '../../enum-types'
import { MakerSoundResource, NextPageData } from '../../model'

/**
 * Maker Sound Api
 */
export class MakerSoundApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  listByCate = (
    params: {
      cate: SoundCateKey
      nextKey: string | null
    } & RequestDataBase,
  ): Promise<{ pagerData: NextPageData<MakerSoundResource> }> => {
    const url = '/p/api/maker-sound/list-by-cate'
    const { cate, nextKey, ctx } = params

    return this.withData.postJson(url, { cate, nextKey }, { ctx })
  }

  search = (
    params: {
      title: string
      nextKey: string | null
    } & RequestDataBase,
  ): Promise<{ pagerData: NextPageData<MakerSoundResource> }> => {
    const url = '/p/api/maker-sound/list-by-title'
    const { title, nextKey, ctx } = params

    return this.withData.postJson(url, { title, nextKey }, { ctx })
  }
}
