import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { Computer, ComputerDisk, ServerInfo } from '../../model'

/**
 * Server Api
 */
export class ServerApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 서버 정보 조회
   */
  info = (params: RequestDataBase): Promise<{ serverInfo: ServerInfo }> => {
    const url = '/admin/api/server/info'
    return this.withData.post(url, ...splitParams(params))
  }

  // 컴퓨터 목록 조회
  list = (params: RequestDataBase): Promise<{ computerList: Computer[]; batchNodeId?: string }> => {
    const url = '/admin/api/computer/list'
    return this.withData.post(url, ...splitParams(params))
  }

  // 컴퓨터 디스크 목록 조회
  diskList = (
    params: { computerId: string } & RequestDataBase,
  ): Promise<{ computerDiskList: ComputerDisk[] }> => {
    const { computerId, ...rest } = params
    const url = `/admin/api/computer/disk-list/${computerId}`
    return this.withData.post(url, ...splitParams(rest))
  }

  // 배치노드 변경
  changeBatchNode = async (params: { computerId: string } & RequestDataBase) => {
    const { computerId, ...rest } = params
    const url = `/admin/api/computer/batch-node-change/${computerId}`
    return this.withData.post(url, ...splitParams(rest))
  }
}
