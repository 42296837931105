import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'

/**
 * 파트너 접속 API
 */
export class PartnerAccessApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  saveLog = async (
    params: {
      partnerId?: string
    } & RequestDataBase,
  ): Promise<void> => {
    const url = '/p/api/partner-access/save-log'
    const { partnerId, ctx } = params
    await this.helper.post(url, { partnerId }, { ctx })
  }
}
