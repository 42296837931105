export type OzoObstacleTypeKey =
  | 'FRONT'
  | 'FRONT_RIGHT'
  | 'FRONT_LEFT'
  | 'REAR'
  | 'REAR_RIGHT'
  | 'REAR_LEFT'

export const OzoObstacleType: Record<OzoObstacleTypeKey, { name: string; num: number }> = {
  FRONT: { name: '앞쪽 장애물', num: 0 },
  FRONT_RIGHT: { name: '앞쪽 오른편 장애물', num: 2 },
  FRONT_LEFT: { name: '앞쪽 왼편 장애물', num: 3 },
  REAR: { name: '뒤쪽 장애물', num: 1 },
  REAR_RIGHT: { name: '뒤쪽 오른편 장애물', num: 4 },
  REAR_LEFT: { name: '뒤쪽 왼편 장애물', num: 5 },
}

export type OzoFloorColorKey =
  | 'black'
  | 'red'
  | 'green'
  | 'yellow'
  | 'blue'
  | 'magenta'
  | 'cyan'
  | 'white'
export const OzoFloorColor: Record<OzoFloorColorKey, string> = {
  black: '검은색',
  red: '빨간색',
  green: '초록색',
  yellow: '노란색',
  blue: '파란색',
  magenta: '자주색',
  cyan: '청록색',
  white: '흰색',
}

export type OzoDirectionTypeKey = 'FRONT' | 'REAR'
export const OzoDirectionType: Record<OzoDirectionTypeKey, { name: string; num: number }> = {
  FRONT: { name: '앞', num: 0 },
  REAR: { name: '뒤', num: 1 },
}

export type OzoWheelSpeedTypeKey = 'SLOW' | 'NORMAL' | 'FAST' | 'VERY_FAST'
export const OzoWheelSpeedType: Record<OzoWheelSpeedTypeKey, { name: string; num: number }> = {
  SLOW: { name: '느리게', num: 1 },
  NORMAL: { name: '보통으로', num: 2 },
  FAST: { name: '빠르게', num: 3 },
  VERY_FAST: { name: '매우빠르게', num: 4 },
}

export type OzoRotationDirectionTypeKey = 'CW' | 'CCW'
export const OzoRotationDirectionType: Record<
  OzoRotationDirectionTypeKey,
  { name: string; num: number }
> = {
  CW: { name: '시계', num: 0 },
  CCW: { name: '반시계', num: 1 },
}

export type OzoRotationAmountTypeKey = 'ONE' | 'HALF'
export const OzoRotationAmountType: Record<
  OzoRotationAmountTypeKey,
  { name: string; num: number }
> = {
  ONE: { name: '한', num: 0 },
  HALF: { name: '반', num: 1 },
}

export type OzoSayEmotionTypeKey =
  | 'happy1'
  | 'happy2'
  | 'happy3'
  | 'happy4'
  | 'happy5'
  | 'allright1'
  | 'allright2'
  | 'sad1'
  | 'sad2'
  | 'sad3'
  | 'sad4'
  | 'surprised1'
  | 'surprised2'
  | 'surprised3'
  | 'surprised4'
  | 'surprised5'
  | 'laugh1'
  | 'laugh2'
  | 'laugh3'
  | 'laugh4'
  | 'laugh5'
  | 'laugh6'

export const OzoSayEmotion: Record<OzoSayEmotionTypeKey, string> = {
  happy1: '행복1',
  happy2: '행복2',
  happy3: '행복3',
  happy4: '행복4',
  happy5: '행복5',
  allright1: '좋아1',
  allright2: '좋아2',
  sad1: '슬픔1',
  sad2: '슬픔2',
  sad3: '슬픔3',
  sad4: '슬픔4',
  surprised1: '놀람1',
  surprised2: '놀람2',
  surprised3: '놀람3',
  surprised4: '놀람4',
  surprised5: '놀람5',
  laugh1: '웃음1',
  laugh2: '웃음2',
  laugh3: '웃음3',
  laugh4: '웃음4',
  laugh5: '웃음5',
  laugh6: '웃음6',
}

export type OzoSayDirectionTypeKey = 'forward' | 'left' | 'right' | 'back'
export const OzoSayDirectionType: Record<OzoSayDirectionTypeKey, string> = {
  forward: 'Forward',
  left: 'Left',
  right: 'Right',
  back: 'Back',
}

export type OzoSayColorTypeKey = 'red' | 'green' | 'blue' | 'cyan' | 'white' | 'black'
export const OzoSayColorType: Record<OzoSayColorTypeKey, string> = {
  red: 'Red',
  green: 'Green',
  blue: 'Blue',
  cyan: 'Cyan',
  white: 'White',
  black: 'Blank',
}
