import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'
import {
  BrowserCount,
  BrowserDailyCountWithUser,
  BrowserMonthlyCountWithUser,
  DevicePlatformCount,
  DeviceTypeCount,
} from '../../model'

/**
 * BrowserStat Api
 */
export class BrowserStatApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 일별 브라우저 접속 통계 조회
   */
  dailyList = (
    params: {
      recentDayCount: number
    } & RequestDataBase,
  ): Promise<{ dailyList: BrowserDailyCountWithUser[] }> => {
    const { ctx, recentDayCount } = params
    const url = `/admin/api/browser-stats/daily/${recentDayCount}`
    return this.withData.post(url, undefined, { ctx })
  }

  /**
   * 월별 브라우저 접속 통계 조회
   */
  monthlyList = (
    params: RequestDataBase,
  ): Promise<{ monthlyList: BrowserMonthlyCountWithUser[] }> => {
    const url = '/admin/api/browser-stats/monthly'
    const { ctx } = params
    return this.withData.post(url, undefined, { ctx })
  }

  browserCountList = (
    params: {
      recentDayCount: number
    } & RequestDataBase,
  ): Promise<{ browserCountList: BrowserCount[] }> => {
    const { ctx, recentDayCount } = params
    const url = `/admin/api/browser-stats/browsers/${recentDayCount}`
    return this.withData.post(url, undefined, { ctx })
  }

  deviceTypeCountList = (
    params: {
      recentDayCount: number
    } & RequestDataBase,
  ): Promise<{ deviceTypeCountList: DeviceTypeCount[] }> => {
    const { ctx, recentDayCount } = params
    const url = `/admin/api/browser-stats/device-types/${recentDayCount}`
    return this.withData.post(url, undefined, { ctx })
  }

  platformCountList = (
    params: {
      recentDayCount: number
    } & RequestDataBase,
  ): Promise<{ platformCountList: DevicePlatformCount[] }> => {
    const { ctx, recentDayCount } = params
    const url = `/admin/api/browser-stats/platforms/${recentDayCount}`
    return this.withData.post(url, undefined, { ctx })
  }
}
