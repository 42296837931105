import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'

/**
 * Translate Api
 */
export class TranslateApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  translateText = (
    params: {
      sourceLang: string
      targetLang: string
      text: string
    } & RequestDataBase,
  ): Promise<{ translatedText: string }> => {
    const url = '/p/ai-api/v1/translate/translate-text'
    const { ctx, sourceLang, targetLang, text } = params

    return this.withData.postJson(url, { sourceLang, targetLang, text }, { ctx })
  }
}
