import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'
import { Clause, UserClause } from '../../model'

/**
 * 약관 API
 */
export class ClauseApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 활성화된 특정 ID들의 약관 정보 조회
   */
  enabledInfos = ({
    clauseIds,
    ctx,
  }: { clauseIds: number[] } & RequestDataBase): Promise<{ clauseList: Clause[] }> => {
    const url = '/p/api/clause-enabled/infos'
    return this.withData.post(url, { clauseIds: clauseIds.join(',') }, { ctx })
  }

  /**
   * 활성화된 모든 약관 목록 조회
   */
  enabledList = ({ ctx }: RequestDataBase): Promise<{ clauseList: Clause[] }> => {
    const url = '/p/api/clause-enabled/list'
    return this.withData.post(url, undefined, { ctx })
  }

  /**
   * 사용자가 동의한 약관 목록 조회
   */
  userClauseList = ({ ctx }: RequestDataBase): Promise<{ clauseList: UserClause[] }> => {
    const url = '/api/user-clause/list'
    return this.withData.post(url, undefined, { ctx })
  }

  /**
   * 사용자 동의 약관 업데이트
   */
  userClauseUpdate = async ({
    ctx,
    clauseIds,
  }: { clauseIds: number[] } & RequestDataBase): Promise<void> => {
    const url = '/api/user-clause/update'
    await this.helper.post(url, { clauseIds: clauseIds.join(',') }, { ctx })
  }
}
