import { LoginTokenStorage } from '@/auth/authentication'
import config from '@/config'

const HTTP_TOKEN_HEADER = config.httpHeaderKeys.token

/**
 * 인증 토큰 HTTP 헤더를 생성한다
 */
const createApiHeader = (): Record<string, string> => {
  const headers = {}
  const token = LoginTokenStorage.get()
  if (token && token.length > 0) {
    headers[HTTP_TOKEN_HEADER] = token
  }

  // log.debug('createApiHeader', { headers })
  return headers
}

export default createApiHeader
