import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { DSWordCloudData } from '../../model'

/**
 * Data Science User Word Cloud Api
 */
export class DSWordCloudApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 워드 클라우드 데이터 바꾸기
   */
  saveData = (
    params: {
      text: string
    } & RequestDataBase,
  ): Promise<{ freq: DSWordCloudData }> => {
    const url = '/p/api/ds-word-cloud/word-freq'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
