import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { DSUserTextData, PagerData } from '../../model'

/**
 * Data Science User Grid Api
 */
export class DSUserTextApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * User Text 목록 조회
   */
  list = (
    params: {
      rowsPerPage: number
      pageNumber: number
    } & RequestDataBase,
  ): Promise<{ pagerData: PagerData<DSUserTextData> }> => {
    const url = '/api/ds-data/user-text-list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * User Text 정보 조회
   */
  info = (
    params: {
      dataId: number
    } & RequestDataBase,
  ): Promise<{ userTextData: DSUserTextData }> => {
    const { dataId, ...rest } = params
    const url = `/api/ds-data/user-text-data/${dataId}`
    return this.withData.post(url, ...splitParams(rest))
  }
  /**
   * User Text 생성
   */
  create = (
    params: {
      title: string
      fileId: string
    } & RequestDataBase,
  ): Promise<{ userTextData: DSUserTextData }> => {
    const url = '/api/ds-data/user-text-create'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * User Grid 한건 업데이트
   */
  update = (
    params: {
      dataId: number
      title: string
      fileId: string
    } & RequestDataBase,
  ): Promise<{ userTextData: DSUserTextData }> => {
    const { dataId, ...rest } = params
    const url = `/api/ds-data/user-text-update/${dataId}`
    return this.withData.postJson(url, ...splitParams(rest))
  }

  /**
   * User Grid 삭제
   */
  delete = async (
    params: {
      dataId: number
    } & RequestDataBase,
  ): Promise<void> => {
    const { dataId, ...rest } = params
    const url = `/api/ds-data/user-text-delete/${dataId}`
    await this.helper.post(url, ...splitParams(rest))
  }

  /**
   * User Grid 파일 생성
   */
  createFile = (
    params: {
      text: string
    } & RequestDataBase,
  ): Promise<{ fileId: string }> => {
    const url = '/api/ds-data/user-text-create-file'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
