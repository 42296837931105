export type WiseDigitalOutputPinKey = 'OUT1' | 'OUT2' | 'OUT3' | 'OUT4' | 'OUT5'
export const WiseDigitalOutputPin: Record<WiseDigitalOutputPinKey, number> = {
  OUT1: 1,
  OUT2: 2,
  OUT3: 3,
  OUT4: 4,
  OUT5: 5,
}

export type WiseDigitalInputPinKey = 'IN1' | 'IN2' | 'IN3' | 'IN4' | 'IN5'
export const WiseDigitalInputPin: Record<WiseDigitalInputPinKey, number> = {
  IN1: 1,
  IN2: 2,
  IN3: 3,
  IN4: 4,
  IN5: 5,
}

export type WiseAnalogInputPinKey = 'IN1' | 'IN2' | 'IN3' | 'IN4' | 'IN5'
export const WiseAnalogInputPin: Record<WiseAnalogInputPinKey, number> = {
  IN1: 1,
  IN2: 2,
  IN3: 3,
  IN4: 4,
  IN5: 5,
}
