import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { HwOsKey } from '../../enum-types'
import { HwDriver, HwDriverFile } from '../../model'

/**
 * 하드웨어 드라이버 API
 */
export class HwDriverApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 드라이버 정보 조회
   * 주어진 toyId로 드라이버 파일 목록을 조회한다
   */
  infoByToy = (
    params: {
      toyId: string
    } & RequestDataBase,
  ): Promise<{
    hwDriver: HwDriver
    hwDriverFileList: HwDriverFile[]
  }> => {
    const url = '/p/api/hw-driver/info-by-toy'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 드라이버 파일 존재 여부 체크
   * 주어진 toyId와 os로 드라이버 파일이 존재하는지 체크한다
   * @return 존재하면 true, 그렇지 않으면 false
   */
  existsDriverFileByToyIdAndOs = (
    params: {
      toyId: string
      os: HwOsKey
    } & RequestDataBase,
  ): Promise<boolean> => {
    const url = '/p/api/hw-driver/exists-driver'
    return this.withData.post(url, ...splitParams(params))
  }
}
