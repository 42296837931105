import { SxProps, Theme } from '@mui/material'

export const rootSx: SxProps<Theme> = {
  '& .MuiDialogTitle-root': {
    py: 1,
    pl: 2,
    pr: 1,
  },
  '& .MuiDialogContent-root': {
    py: 2,
    px: 0,
  },

  '& .ProfileViewDialog-dialogTitle': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    py: 0.5,
    px: 1,
    '& .MuiTypography-h6': {
      lineHeight: '0.6rem',
    },
  },
  '& .ProfileViewDialog-dialogContent': {
    position: 'relative',
    width: 290,
    minHeight: 200,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    pt: '4px',
  },

  '& .ProfileViewDialog-profileTitle': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  '& .ProfileViewDialog-profileImage': {
    position: 'relative',
    width: 150,
    height: 150,
    borderRadius: '50%',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    my: 2,
    mx: 0,
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'contain',
    },
  },
  '& .ProfileViewDialog-studentGrade': {
    color: '#666',
    fontSize: '.8rem',
  },
  '& .ProfileViewDialog-mood': {
    display: 'block',
    width: '100%',
    px: 1.5,
    mt: 1,
    mx: 0,
    pb: 1.5,
    fontSize: '0.8rem',
  },

  '& .ProfileViewDialog-score': {
    fontSize: '0.6rem',
    color: 'steelblue',
    fontWeight: 400,
  },
}
