import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { PartnerAccessCount, PartnerDailyCount } from '../../model'

/**
 * 어드민 파트너 접속 Api
 */
export class PartnerAccessApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 모든 파트너의 파트너별 카운트 조회
   */
  countList = (
    params: {
      monthCount: number
    } & RequestDataBase,
  ): Promise<{ countList: PartnerAccessCount[] }> => {
    const url = '/admin/api/partner-access/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 특정 파트너의 일별 카운트 조회
   */
  dailyCountList = (
    params: {
      partnerId: string
      monthCount: number
    } & RequestDataBase,
  ): Promise<{ dailyCountList: PartnerDailyCount[] }> => {
    const url = '/admin/api/partner-access/list-daily'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
