import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'
import {
  DSKnnImageClassFeature,
  DSKnnImageClassifyModelJson,
  DSKnnImageClassifyModelJson2,
  DSKnnImageClassifyModelWithImageUrlsJson,
  DSKnnImageClassifyModelWithImageUrlsJson2,
  DSModel,
} from '../../model/data-science'

// mobx의 proxy된 배열이 전달되어서 Array.from과 Array.prototype.slice.call이 동작하지 않는다
// 그래서 무식하게 돌리는 것만 정상동작한다.(f32arrayToNumberArray)
// Proxy라서 로그를 찍는 것으로는 비교가 안된다
//     it.features.forEach((f32arr) => {
//         console.log('Array.from f32arr to num_arr', Array.from(f32arr))
//         console.log('Array.prototype.slice.call f32arr to num_arr', Array.prototype.slice.call(f32arr))
//         console.log('f32arrayToNumberArray f32arr to num_arr', f32arrayToNumberArray(f32arr))
//     })
const f32arrayToNumberArray = (f32arr: Float32Array): number[] => {
  const result = [] as number[]
  for (let i = 0; i < f32arr.length; i++) {
    result[i] = f32arr[i]
  }

  return result
}

/**
 * Data Science KNN Text Classification Model Api
 */
export class DSModelKnnImageClassifyApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * Knn Image 모델 정보 조회
   * 20230418 jjfive 로그인하지 않아도 모델을 로드할 수 있도록 함
   */
  model = async (
    params: {
      modelId: string
    } & RequestDataBase,
  ): Promise<DSKnnImageClassifyModelJson> => {
    const { modelId, ctx } = params
    const url = `/p/api/ds-model/knn-image-classify/model/${modelId}/model.json`

    const { model } = await this.withData.postJson<
      Promise<{ model: DSKnnImageClassifyModelJson2 }>
    >(url, undefined, {
      ctx,
    })

    const { classFeatures, ...rest } = model
    const classFeaturesF32 = classFeatures.map(({ features, ...remain }) => ({
      ...remain,
      features: features.map((arr) => Float32Array.from(arr)),
    }))
    return { ...rest, classFeatures: classFeaturesF32 }
  }

  /**
   * Knn Image 모델 정보 조회
   * 20230418 jjfive 로그인하지 않아도 모델을 로드할 수 있도록 함
   */
  modelWithImageUrls = async (
    params: {
      modelId: string
    } & RequestDataBase,
  ): Promise<DSKnnImageClassifyModelWithImageUrlsJson> => {
    const { modelId, ctx } = params
    const url = `/p/api/ds-model/knn-image-classify/data/${modelId}`

    const { model } = await this.withData.post<
      Promise<{ model: DSKnnImageClassifyModelWithImageUrlsJson2 }>
    >(url, undefined, {
      ctx,
    })

    const { classFeatures, ...rest } = model
    const classFeaturesF32 = classFeatures.map(({ features, ...remain }) => ({
      ...remain,
      features: features.map((arr) => Float32Array.from(arr)),
    }))
    return { ...rest, classFeatures: classFeaturesF32 }
  }

  saveModel = (
    params: {
      modelId: string
      modelName: string
      classFeatures: DSKnnImageClassFeature[]
    } & RequestDataBase,
  ): Promise<{ model: DSModel }> => {
    const url = '/api/ds-model/knn-image-classify/save-model'
    const { modelId, modelName, classFeatures, ctx } = params
    const classFeaturesAsNumberArrays = classFeatures.map((it) => ({
      ...it,
      features: it.features.map(f32arrayToNumberArray),
    }))

    return this.withData.postJsonAndSecuredResponse(
      url,
      { modelId, modelName, classFeatures: classFeaturesAsNumberArrays },
      { ctx },
    )
  }

  saveModelData = (
    params: {
      modelId: string
      classId: string
      imageFiles: Blob[]
      isLast: boolean
    } & RequestDataBase,
  ): Promise<{ model: DSModel }> => {
    const url = '/api/ds-model/knn-image-classify/save-data'
    const { modelId, classId, imageFiles, isLast, ctx } = params
    const formData = new FormData()
    formData.append('modelId', modelId)
    formData.append('classId', classId)
    formData.append('isLast', isLast ? 'true' : 'false')
    imageFiles.forEach((f, idx) => {
      formData.append('imageFiles', f, `${f}_${idx}.jpg`)
    })

    return this.withData.postMultipart(url, formData, { ctx })
  }
}
