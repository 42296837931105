import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'

/**
 * Captcha Api
 */
export class CaptchaApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  // startUserSession = ({ ctx, token }: { token: string } & RequestDataBase): Promise<{ sid: string }> => {
  //     const url = '/api/captcha/start-session'

  //     return this.withData.postJson(url, { token, isAdmin: false }, { ctx })
  // }

  verify = async (params: { token: string } & RequestDataBase): Promise<void> => {
    const { ctx, token } = params
    const url = '/api/captcha/verify'

    await this.withData.postJson(url, { token }, { ctx })
  }
}
