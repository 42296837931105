import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'
import {
  BlkBlock,
  BlkToolboxItem,
  BlkToolboxItemMini,
  BlkToolboxItemSimple,
  BlkWorkspace,
  BlkWorkspaceDetail,
  BlocklyBlockStyle,
  BlocklyCategoryStyle,
  BlocklyComponentStyles,
} from '../../model/blk'
import { WorkspaceIdRequest } from '../payload/block-payload'

/**
 * Block API
 */
export class BlockApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 워크 스페이스 목록 조회
   */
  workspaceList = ({
    ctx,
  }: RequestDataBase): Promise<{
    workspaceList: BlkWorkspace[]
  }> => {
    const url = '/p/api/blk-workspace/list'
    return this.withData.post(url, undefined, { ctx })
  }

  /**
   * 워크 스페이스 상세 정보 조회
   * 워크 스페이스, 카테고리 목록, 카테고리 스타일 목록, 블록 스타일 목록
   */
  workspaceDetail = ({
    ctx,
    workspaceId,
  }: WorkspaceIdRequest): Promise<{
    workspaceDetail: BlkWorkspaceDetail
  }> => {
    const url = `/p/api/blk-workspace/detail/${workspaceId}`
    return this.withData.post(url, undefined, { ctx })
  }

  /**
   * 툴박스 아이템 목록 조회 - Simple
   */
  toolboxItemSimples = ({
    ctx,
    workspaceId,
  }: WorkspaceIdRequest): Promise<{
    toolboxItemList: BlkToolboxItemSimple[]
  }> => {
    const url = '/p/api/blk-workspace/toolbox-item/list'
    return this.withData.post(url, { workspaceId }, { ctx })
  }

  /**
   * 툴박스 아이템 목록 조회 - With FlyoutItems
   */
  toolboxItems = ({
    ctx,
    workspaceId,
  }: WorkspaceIdRequest): Promise<{
    toolboxItemList: BlkToolboxItem[]
  }> => {
    const url = '/p/api/blk-workspace/toolbox-item-with-child/list'
    return this.withData.post(url, { workspaceId }, { ctx })
  }

  /**
   * 툴박스 XML
   */
  toolboxXml = ({
    ctx,
    workspaceId,
  }: WorkspaceIdRequest): Promise<{
    workspaceId: number
    workspaceName: string
    toolboxXml: string
    toolboxItems: BlkToolboxItemMini[]
    categoryStyles: Record<string, BlocklyCategoryStyle>
    componentStyles: BlocklyComponentStyles
    blockStyles: Record<string, BlocklyBlockStyle>
  }> => {
    const url = `/p/api/blk-toolbox-xml/${workspaceId}`
    return this.withData.post(url, undefined, { ctx })
  }

  /**
   * 툴박스 XML
   */
  defaultToolboxXml = ({
    ctx,
  }: RequestDataBase): Promise<{
    workspaceId: number
    workspaceName: string
    toolboxXml: string
    toolboxItems: BlkToolboxItemMini[]
    categoryStyles: Record<string, BlocklyCategoryStyle>
    componentStyles: BlocklyComponentStyles
    blockStyles: Record<string, BlocklyBlockStyle>
  }> => {
    const url = '/p/api/blk-toolbox-xml-default'
    return this.withData.post(url, undefined, { ctx })
  }

  /**
   * 블록 목록 조회
   */
  blockList = ({
    ctx,
    blockOrigin,
  }: RequestDataBase & { blockOrigin?: string }): Promise<{
    blockList: BlkBlock[]
  }> => {
    const url = '/p/api/blk-block/list'
    return this.withData.post(url, { blockOrigin }, { ctx })
  }
}
