import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'
import { Simul, SimulCompositeBlock, SimulUnitBlock } from '../../model'

/**
 * SimulCompositeBlock Api
 */
export class SimulCompositeBlockApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 조합블록 생성
   */
  create = (
    params: {
      simulId: number
      imageFileId: string
      title: string
      blockXml: string
    } & RequestDataBase,
  ): Promise<{ compositeBlock: SimulCompositeBlock }> => {
    const url = '/api/simul-composite-block/create'
    const { simulId, imageFileId, title, blockXml, ctx } = params

    return this.withData.postJson(url, { simulId, imageFileId, blockXml, title }, { ctx })
  }

  /**
   * 조합블록 수정
   */
  update = (
    params: {
      fid: string
      simulId: number
      imageFileId: string
      title: string
      blockXml: string
    } & RequestDataBase,
  ): Promise<{ compositeBlock: SimulCompositeBlock }> => {
    const { fid, simulId, imageFileId, title, blockXml, ctx } = params
    const url = `/api/simul-composite-block/update/${fid}`

    return this.withData.postJson(url, { simulId, imageFileId, title, blockXml }, { ctx })
  }

  /**
   * 조합블록 삭제
   */
  delete = async (
    params: {
      fid: string
    } & RequestDataBase,
  ): Promise<void> => {
    const { fid, ctx } = params
    const url = `/api/simul-composite-block/delete/${fid}`
    // E1_NO_SUCH_SIMUL_COMPOSITE_BLOCK
    await this.helper.post(url, undefined, { ctx })
  }

  /**
   * 조합블록 목록 조회
   */
  list = (
    params: {
      simulId: number
    } & RequestDataBase,
  ): Promise<{ compositeBlockList: SimulCompositeBlock[] }> => {
    const { simulId, ctx } = params
    const url = '/api/simul-composite-block/list'

    return this.withData.post(url, { simulId }, { ctx })
  }

  /**
   * 조합블록 정보 조회
   */
  info = (
    params: {
      fid: string
    } & RequestDataBase,
  ): Promise<{ compositeBlock: SimulCompositeBlock }> => {
    const { fid, ctx } = params
    const url = `/api/simul-composite-block/info/${fid}`

    return this.withData.post(url, undefined, { ctx })
  }

  /**
   * 단위블록을 사용중인 조합블록 목록 조회
   */
  findUsageByUnitBlock = (
    params: {
      unitBlockFid: string
    } & RequestDataBase,
  ): Promise<{
    simul: Simul
    unitBlock: SimulUnitBlock
    compositeBlockList: SimulCompositeBlock[]
  }> => {
    const { unitBlockFid, ctx } = params
    const url = '/api/simul-composite-block/find-usage-by-unit-block-fid'

    return this.withData.postAndSecuredResponse(url, { unitBlockFid }, { ctx })
  }
}
