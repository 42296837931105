import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { AdminAccount } from '../../model'

/**
 * 어드민 프로필 Api
 */
export class ProfileApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 어드민 프로필 조회
   */
  profileMe = (params: RequestDataBase): Promise<{ profile: AdminAccount }> => {
    const url = '/admin/api/profile-me'
    return this.withData.post(url, ...splitParams(params))
  }
}
