import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'
import { EduResource } from '../../model/EduResource'

/**
 * EduResource 사용자용 Api
 */
export class EduResourceApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 교육자료 목록 조회
   */
  list = (
    params: {
      cateId?: string
    } & RequestDataBase,
  ): Promise<{ eduResourceList: EduResource[] }> => {
    const url = '/p/api/edu-resource/list'
    const { ctx, cateId } = params

    return this.withData.postJson(url, { cateId }, { ctx })
  }

  /**
   * 교육자료 정보 조회
   */
  info = (
    params: {
      resId: string
    } & RequestDataBase,
  ): Promise<{ eduResource: EduResource }> => {
    const { ctx, resId } = params
    const url = `/p/api/edu-resource/info/${resId}`

    return this.withData.postJson(url, { resId }, { ctx })
  }

  /**
   * 카테고리 목록 조회
   */
  cateList = (params: RequestDataBase): Promise<{ cateList: string[] }> => {
    const url = '/p/api/edu-resource/cate-list'
    const { ctx } = params

    return this.withData.postJson(url, undefined, { ctx })
  }
}
