import log from '@/log'
import { filter, fromEvent, map, Observable } from 'rxjs'
import { dispatch, EventTypes } from './common'

type Payload = {
  accountId: number
}

const checkNumberProp = (obj: any, propertyName: string): boolean => {
  return propertyName in obj && typeof obj[propertyName] === 'number'
}

export class ProfileDialogOpenEvent {
  static send = (params: { accountId: number }) => {
    dispatch(EventTypes.profile.openViewDialog, params)
  }

  private static retrieveEventBody = (event: any): Payload | null => {
    if (!event.detail) return null
    const detail = event.detail
    const type = event.type

    if (type === EventTypes.profile.openViewDialog && checkNumberProp(detail, 'accountId')) {
      const accountId = detail['accountId']
      return { accountId }
    }

    log.warn('unknown event payload', event)
    return null
  }

  static observe = (): Observable<Payload> => {
    return fromEvent(window, EventTypes.profile.openViewDialog).pipe(
      map((event: any) => ProfileDialogOpenEvent.retrieveEventBody(event)),
      filter((body) => body != null),
      map((body) => body as Payload), // as not-null
    )
  }
}
