import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { DSModel, DSSimpleRegressData, DSSimpleRegressModelJson } from '../../model/data-science'

/**
 * Data Science Model Api
 */
export class DSModelSimpleRegressApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * Simple Regress 모델 정보 조회
   */
  model = (
    params: {
      modelId: string
    } & RequestDataBase,
  ): Promise<{ model: DSSimpleRegressModelJson }> => {
    const { modelId, ...rest } = params
    const url = `/p/api/ds-model/simple-regress/model/${modelId}/model.json`
    return this.withData.postJson(url, ...splitParams(rest))
  }

  /**
   * Simple Regress 데이터 조회
   */
  loadData = (
    params: {
      modelId: string
    } & RequestDataBase,
  ): Promise<{ model: DSSimpleRegressModelJson; data: DSSimpleRegressData }> => {
    const { modelId, ...rest } = params
    const url = `/p/api/ds-model/simple-regress/data/${modelId}`
    return this.withData.postJson(url, ...splitParams(rest))
  }

  /**
   * 모델 저장
   */
  saveModelData = (
    params: {
      modelId: string
      modelName: string
      coefficients: number[]
      columnNames: string[]
      columnKeys: string[]
      featureKey: string
      predictKey: string
      rows: string[][]
    } & RequestDataBase,
  ): Promise<{ model: DSModel }> => {
    const url = '/api/ds-model/simple-regress/save-data'
    return this.withData.postJsonAndSecuredResponse(url, ...splitParams(params))
  }
}
