import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { DSUserGridData, PagerData } from '../../model'

/**
 * Data Science User Grid Api
 */
export class DSUserGridApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * User Grid 목록 조회
   */
  list = (
    params: {
      rowsPerPage: number
      pageNumber: number
    } & RequestDataBase,
  ): Promise<{ pagerData: PagerData<DSUserGridData> }> => {
    const url = '/api/ds-data/user-grid-list'
    return this.withData.postJsonAndSecuredResponse(url, ...splitParams(params))
  }

  /**
   * User Grid 정보 조회
   */
  info = (
    params: {
      dataId: number
    } & RequestDataBase,
  ): Promise<{ userGridData: DSUserGridData }> => {
    const { dataId, ...rest } = params
    const url = `/api/ds-data/user-grid-data/${dataId}`
    return this.withData.postJsonAndSecuredResponse(url, ...splitParams(rest))
  }

  /**
   * User Grid 생성
   */
  create = (
    params: {
      title: string
      fileId: string
    } & RequestDataBase,
  ): Promise<{ userGridData: DSUserGridData }> => {
    const url = '/api/ds-data/user-grid-create'
    return this.withData.postJsonAndSecuredResponse(url, ...splitParams(params))
  }

  /**
   * User Grid 한건 업데이트
   */
  update = (
    params: {
      dataId: number
      title: string
      fileId: string
    } & RequestDataBase,
  ): Promise<{ userGridData: DSUserGridData }> => {
    const { dataId, ...rest } = params
    const url = `/api/ds-data/user-grid-update/${dataId}`
    return this.withData.postJson(url, ...splitParams(rest))
  }

  /**
   * User Grid 삭제
   */
  delete = async (
    params: {
      dataId: number
    } & RequestDataBase,
  ): Promise<void> => {
    const { dataId, ...rest } = params
    const url = `/api/ds-data/user-grid-delete/${dataId}`
    await this.helper.post(url, ...splitParams(rest))
  }

  /**
   * User Grid 파일 생성
   */
  createFile = (
    params: {
      csv: string
    } & RequestDataBase,
  ): Promise<{
    fileId: string
    rowCount: number
    columnCount: number
    columnName: string[]
    rows: string[][]
  }> => {
    const url = '/api/ds-data/user-grid-create-file'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * csv url로 csv를 fetch 한다
   * @param params
   * @returns
   */
  fetchCsvFromUrl = (
    params: {
      csvUrl: string
    } & RequestDataBase,
  ): Promise<{
    rowCount: number
    columnCount: number
    columnNames: string[]
    rows: string[][]
  }> => {
    const url = '/p/api/ds-data/fetch-csv'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
