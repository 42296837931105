import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'

/**
 * Assistant Api
 */
export class AssistantApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  ask = ({
    ctx,
    text,
  }: RequestDataBase & { text: string }): Promise<{
    resultData: string
  }> => {
    const url = '/p/ai-api/v1/assistant/ask'
    return this.withData.postJson(url, { text }, { ctx })
  }
}
