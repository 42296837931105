import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'
import { BlockExam, UploadedFile } from '../../model'

/**
 * 사용자 외부 API Api
 */
export class BlockExamApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * BlockExam 신규 저장
   */
  create = (
    params: {
      cate?: string
      title: string
      memo?: string
      blockImageFileId: string
      blockXml: string
    } & RequestDataBase,
  ): Promise<{ blockExam: BlockExam }> => {
    const url = '/api/block-exam/create'
    const { ctx, cate, title, memo, blockImageFileId, blockXml } = params

    return this.withData.postJson(url, { cate, title, memo, blockImageFileId, blockXml }, { ctx })
  }

  /**
   * BlockExam 업데이트 저장
   */
  update = (
    params: {
      examId: string
      cate?: string
      title: string
      memo?: string
      blockImageFileId: string
      blockXml: string
    } & RequestDataBase,
  ): Promise<{ blockExam: BlockExam }> => {
    const { ctx, examId, cate, title, memo, blockImageFileId, blockXml } = params
    const url = `/api/block-exam/update/${examId}`

    return this.withData.postJson(
      url,
      {
        cate,
        title,
        memo,
        blockImageFileId,
        blockXml,
      },
      { ctx },
    )
  }

  /**
   * BlockExam 제목 업데이트 저장
   */
  updateTitle = (
    params: {
      examId: string
      title: string
    } & RequestDataBase,
  ): Promise<{ blockExam: BlockExam }> => {
    const { ctx, examId, title } = params
    const url = `/api/block-exam/update-title/${examId}`

    return this.withData.postJson(url, { title }, { ctx })
  }

  /**
   * BlockExam 메모 업데이트 저장
   */
  updateMemo = (
    params: {
      examId: string
      memo: string
    } & RequestDataBase,
  ): Promise<{ blockExam: BlockExam }> => {
    const { ctx, examId, memo } = params
    const url = `/api/block-exam/update-memo/${examId}`

    return this.withData.postJson(url, { memo }, { ctx })
  }

  /**
   * BlockExam 카테고리 업데이트 저장
   */
  updateCate = (
    params: {
      examId: string
      cate: string
    } & RequestDataBase,
  ): Promise<{ blockExam: BlockExam }> => {
    const { ctx, examId, cate } = params
    const url = `/api/block-exam/update-cate/${examId}`

    return this.withData.postJson(url, { cate }, { ctx })
  }

  /**
   * BlockExam 목록 조회
   */
  list = (
    params: {
      cate: string
    } & RequestDataBase,
  ): Promise<{
    blockExamList: BlockExam[] // BlockExam 목록 조회
    cateList: string[] // Cate 목록
  }> => {
    const url = '/api/block-exam/list'
    const { ctx, cate } = params

    return this.withData.postJson(url, { cate }, { ctx })
  }

  /**
   * BlockExam Cate 목록 조회
   */
  cates = (
    params: RequestDataBase,
  ): Promise<{
    cateList: string[]
  }> => {
    const url = '/api/block-exam/cate-list'
    const { ctx } = params

    return this.withData.postJson(url, undefined, { ctx })
  }

  /**
   * BlockExam 이미지 파일 업로드
   */
  uploadBlockImageFile = (
    params: {
      file: File
    } & RequestDataBase,
  ): Promise<{ file: UploadedFile }> => {
    const url = '/api/block-exam/image-upload'
    const { ctx, file } = params
    const formData = new FormData()
    formData.append('file', file, file.name) // formData.append('file', file)
    console.log('uploading file:', { name: file.name, size: file.size, type: file.type })

    return this.withData.postMultipart(url, formData, { ctx })
  }

  /**
   * BlockExam 삭제
   */
  delete = async (
    params: {
      examId: string
    } & RequestDataBase,
  ): Promise<void> => {
    const { ctx, examId } = params
    const url = `/api/block-exam/delete/${examId}`

    await this.helper.post(url, undefined, { ctx })
    return
  }
}
