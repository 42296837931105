import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import {
  AttachFile,
  BlkBlock,
  BlkComponentStyles,
  BlkToolboxItem,
  BlkToolboxItemMini,
  BlkToolboxItemSimple,
  BlkWorkspace,
  BlkWorkspaceDetail,
  BlocklyBlockStyle,
  BlocklyCategoryStyle,
  BlocklyComponentStyles,
} from '../../model'
import {
  BlockIdRequest,
  BlockImageUploadRequest,
  BlockSaveRequest,
  SaveToolboxItemByXmlRequest,
  ToolboxItemIdRequest,
  ToolboxItemSortRequest,
  WorkspaceIdRequest,
  WorkspaceInfoSaveRequest,
} from './payload/block-payload'

/**
 * Block API
 */
export class BlockApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 워크 스페이스 목록 조회
   */
  workspaceList = (
    params: RequestDataBase,
  ): Promise<{
    workspaceList: BlkWorkspace[]
  }> => {
    const url = '/admin/api/blk-workspace/list'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 워크 스페이스 상세 정보 조회
   * 워크 스페이스, 카테고리 목록, 카테고리 스타일 목록, 블록 스타일 목록
   */
  workspaceDetail = (
    params: { workspaceId: number } & RequestDataBase,
  ): Promise<{
    workspaceDetail: BlkWorkspaceDetail
  }> => {
    const { workspaceId, ...rest } = params
    const url = `/admin/api/blk-workspace/detail/${workspaceId}`
    return this.withData.post(url, ...splitParams(rest))
  }

  /**
   * 툴박스 아이템 목록 조회 - Simple
   */
  toolboxItemSimples = ({
    ctx,
    workspaceId,
  }: WorkspaceIdRequest): Promise<{
    toolboxItemList: BlkToolboxItemSimple[]
  }> => {
    const url = '/admin/api/blk-workspace/toolbox-item/list'
    return this.withData.post(url, { workspaceId }, { ctx })
  }

  /**
   * 툴박스 아이템 목록 조회 - With FlyoutItems
   */
  toolboxItems = ({
    ctx,
    workspaceId,
  }: WorkspaceIdRequest): Promise<{
    toolboxItemList: BlkToolboxItem[]
  }> => {
    const url = '/admin/api/blk-workspace/toolbox-item-with-child/list'
    return this.withData.post(url, { workspaceId }, { ctx })
  }

  /**
   * 툴박스 아이템 상세정보 조회 - With FlyoutItems
   */
  toolboxItem = ({
    ctx,
    toolboxItemId,
  }: ToolboxItemIdRequest): Promise<{
    toolboxItem: BlkToolboxItem
  }> => {
    const url = `/admin/api/blk-toolbox-item/info/${toolboxItemId}`
    return this.withData.post(url, undefined, { ctx })
  }

  /**
   * 툴박스 아이템 정렬 저장
   */
  toolboxItemSaveSorting = async ({
    ctx,
    workspaceId,
    toolboxItemIds,
  }: ToolboxItemSortRequest): Promise<void> => {
    const url = '/admin/api/blk-workspace/toolbox-item/save-sort'
    await this.helper.post(url, { workspaceId, toolboxItemIds: toolboxItemIds.join(',') }, { ctx })
  }

  /**
   * 툴박스 XML
   */
  toolboxXml = ({
    ctx,
    workspaceId,
  }: WorkspaceIdRequest): Promise<{
    workspaceId: number
    workspaceName: string
    toolboxXml: string
    toolboxItems: BlkToolboxItemMini[]
    categoryStyles: Record<string, BlocklyCategoryStyle>
    blockStyles: Record<string, BlocklyBlockStyle>
    componentStyles: BlocklyComponentStyles
  }> => {
    const url = `/admin/api/blk-toolbox-xml/${workspaceId}`
    return this.withData.post(url, undefined, { ctx })
  }

  /**
   * 블록 목록 조회
   */
  blockList = ({
    ctx,
    blockOrigin,
  }: RequestDataBase & { blockOrigin?: string }): Promise<{
    blockList: BlkBlock[]
  }> => {
    const url = '/admin/api/blk-block/list'
    return this.withData.post(url, { blockOrigin }, { ctx })
  }

  /**
   * 블록 저장
   */
  blockSave = async ({
    ctx,
    blockXml,
    description,
    blockOrigin,
  }: BlockSaveRequest): Promise<void> => {
    const url = '/admin/api/blk-block/save'
    await this.helper.post(url, { blockXml, description, blockOrigin }, { ctx })
  }

  /**
   * 블록 삭제
   */
  blockDelete = async ({ ctx, blockId }: BlockIdRequest): Promise<void> => {
    const url = `/admin/api/blk-block/delete/${blockId}`
    await this.helper.post(url, undefined, { ctx })
  }

  /**
   * 블록 이미지 업로드
   */
  blockImageUpload = ({
    ctx,
    blockId,
    file,
  }: BlockImageUploadRequest): Promise<{ file: AttachFile }> => {
    const url = `/admin/api/blk-block/image-upload/${blockId}`
    const formData = new FormData()
    formData.append('file', file)
    return this.withData.postMultipart(url, formData, { ctx })
  }

  /**
   * 툴박스 아이템을 XML로 저장
   */
  saveToolboxItemByXml = async ({
    ctx,
    toolboxItemId,
    toolboxItemXml,
  }: SaveToolboxItemByXmlRequest): Promise<void> => {
    const url = `/admin/api/blk-toolbox-item/save-by-xml/${toolboxItemId}`
    await this.helper.post(url, { toolboxItemXml }, { ctx })
  }

  /**
   * 워크 스페이스 상세 정보 조회
   * 워크 스페이스, 카테고리 목록, 카테고리 스타일 목록, 블록 스타일 목록
   */
  saveWorkspaceInfo = ({
    ctx,
    workspaceId,
    workspaceName,
    componentStyles,
  }: WorkspaceInfoSaveRequest): Promise<{
    workspace: BlkWorkspace
    componentStyles: BlkComponentStyles
  }> => {
    const url = '/admin/api/blk-workspace/save'
    return this.withData.postJson(
      url,
      {
        workspaceId,
        workspaceName,
        componentStyles,
      },
      { ctx },
    )
  }
}
