import config from '@/config'
import { AdminMainApi } from '@aimk/domain'
import React, { createContext, useContext } from 'react'
import { apiResponseInterceptor, errorResponseInterceptor } from './apiResponseInterceptor'
import createApiHeader from './createApiHeader'

const API_DEBUG = false
const api = new AdminMainApi(
  config.apiBaseURL,
  createApiHeader,
  apiResponseInterceptor,
  errorResponseInterceptor,
  API_DEBUG,
)

export const ApiContext = createContext(api)

/**
 * API hook
 */
export const useApi = () => useContext(ApiContext)

type Props = {
  children: React.ReactNode
}

/**
 * api provider
 */
export default function ApiProvider(props: Props) {
  const { children } = props
  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
}
