import { createStringEnum } from '../enum-generic'

export interface EventRunLoopCode {
  event: string
  handler: string | null
}

export interface SceneJavascript {
  header: string[]
  procedures: string[]
  body: EventRunLoopCode[]
}

export type UserVariable = {
  varId: string
  varName: string
  defaultValue?: string
}

export type UserJsVariable = {
  jsVarName: string
  jsVarValue: string
}

export type ObjectSignal = {
  signalId: string
  signalName: string
}

export const USER_VAR_PREFIX = 'aimk_user_var_'

/**
 * 사용자 정의 리스트
 */
export type UserDefinedList = {
  listId: string
  listName: string
  values: string[]
}

export type SttState =
  | 'none' // 최초 상태
  | 'prepare' // 준비중(마이크 연결 시도)
  | 'listen' // 듣는중
  | 'analysis' // 분석중
  | 'success' // 성공
  | 'error' // 에러

export type ObjectSpeedKey = 'very_fast' | 'fast' | 'normal' | 'slow' | 'very_slow'
export const ObjectSpeed: Record<ObjectSpeedKey, string> = {
  very_fast: '매우 빠르게',
  fast: '빠르게',
  normal: '보통',
  slow: '느리게',
  very_slow: '매우 느리게',
}

export type ObjectDebugRunModeKey = 'very_slow' | 'slow' | 'off'
export const ObjectDebugRunMode: Record<ObjectDebugRunModeKey, string> = {
  very_slow: '매우 느리게',
  slow: '느리게',
  off: '사용안함',
}

export const ObjectSpeedFactor: Record<ObjectSpeedKey, number> = {
  very_fast: 2.7,
  fast: 1.7,
  normal: 1,
  slow: 0.6,
  very_slow: 0.2,
}

export type ObjectImageFilterTypeKey =
  | 'brightness'
  | 'contrast'
  | 'saturation'
  | 'hue'
  | 'noise'
  | 'pixelate'
  | 'blur'

export const ObjectImageFilterType: Record<ObjectImageFilterTypeKey, string> = {
  brightness: '밝기',
  contrast: '대비',
  saturation: '채도',
  hue: '색상',
  noise: '노이즈',
  pixelate: '픽셀',
  blur: '번짐',
}

export type ObjectImageFilter = {
  // 필터키
  type: ObjectImageFilterTypeKey

  // 필터값 [0,1]
  value: number
}

export type SubwayDayTypeKey = 'NORMAL' | 'SATURDAY' | 'SUNDAY'
export const SubwayDayType: Record<SubwayDayTypeKey, string> = {
  NORMAL: '평일',
  SATURDAY: '토요일',
  SUNDAY: '일요일',
}

export type SubwayDirectionKey = 'U' | 'D' // 상행(U), 하행(D)

export type SubwayStationId =
  | 'SUB133'
  | 'SUB1511'
  | 'SUB228'
  | 'SUB226'
  | 'SUB160'
  | 'SUB161'
  | 'SUB70113'
  | 'SUB40132'
  | 'SUB30104'
  | 'SUB179'
  | 'SUB141'
  | 'SUB1408'
  | 'SUB1416'
  | 'SUB140'

export const SubwayStation: Record<SubwayStationId, string> = {
  SUB133: '서울역',
  SUB1511: '서울숲역',
  SUB228: '서울대입구역',
  SUB226: '사당역',
  SUB160: '동인천역',
  SUB161: '인천역',
  SUB70113: '부산역',
  SUB40132: '대구역',
  SUB30104: '대전역',
  SUB179: '금정역',
  SUB141: '구로역',
  SUB1408: '천안역',
  SUB1416: '신창역',
  SUB140: '신도림역',
}

// 20211220 피부질환가능지수 제거
// export type BogunApiTypeKey = 'SKIN_DISEASE' | 'STROKE' | 'COLD' | 'ASTHMA'
// export const BogunApiType = createStringEnum<BogunApiTypeKey>({
//     SKIN_DISEASE: '피부질환가능지수',
//     STROKE: '뇌졸증가능지수',
//     COLD: '감기가능지수',
//     ASTHMA: '천식폐질환가능지수',
// })
export type BogunApiTypeKey = 'STROKE' | 'COLD' | 'ASTHMA'
export const BogunApiType = createStringEnum<BogunApiTypeKey>({
  STROKE: '뇌졸증가능지수',
  COLD: '감기가능지수',
  ASTHMA: '천식폐질환가능지수',
})

export type BogunApiDayTypeKey = 'TODAY' | 'TOMORROW' | 'AFTER_TOMORROW'
export const BogunApiDayType = createStringEnum<BogunApiDayTypeKey>({
  TODAY: '오늘',
  TOMORROW: '내일',
  AFTER_TOMORROW: '내일모레',
})

export type SentimentAnalLangKey =
  | 'ar'
  | 'zh'
  | 'zh-Hant'
  | 'nl'
  | 'en'
  | 'fr'
  | 'de'
  | 'id'
  | 'it'
  | 'ja'
  | 'ko'
  | 'pt'
  | 'es'
  | 'th'
  | 'tr'
  | 'vi'
export const SentimentAnalLang = createStringEnum<SentimentAnalLangKey>({
  ar: '아랍어',
  zh: '중국어(간체)',
  'zh-Hant': '중국어(번체)',
  nl: '네덜란드어',
  en: '영어',
  fr: '프랑스어',
  de: '독일어',
  id: '인도네시아어',
  it: '이탈리아어',
  ja: '일본어',
  ko: '한국어',
  pt: '포르투갈어(브라질 및 콘티넨탈)',
  es: '스페인어',
  th: '태국어',
  tr: '터키어',
  vi: '베트남어',
})

export type SimulPrefix = {
  simulId: number
  blockPrefix: string
}
