import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { Faq } from '../../model/Faq'

/**
 * Faq Api
 */
export class FaqApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * Faq 목록 조회
   */
  faqList = (params: RequestDataBase): Promise<{ faqList: Faq[] }> => {
    const url = '/p/api/faq/list'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
