import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { Lesson, LessonSimple, UploadedFile } from '../../model'

/**
 * Lesson Api
 */
export class LessonApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  list = (params: RequestDataBase): Promise<{ lessonList: LessonSimple[] }> => {
    const url = '/p/api/lesson/list'
    return this.withData.post(url, ...splitParams(params))
  }

  listRandom = (
    params: {
      maxCount: number
    } & RequestDataBase,
  ): Promise<{ lessonList: LessonSimple[] }> => {
    const url = '/p/api/lesson/list-random'
    return this.withData.post(url, ...splitParams(params))
  }

  info = (params: { lessonId: string } & RequestDataBase): Promise<{ lesson: Lesson }> => {
    const { lessonId, ...rest } = params
    const url = `/p/api/lesson/info/${lessonId}`
    return this.withData.post(url, ...splitParams(rest))
  }

  infoByLessonNum = (
    params: { lessonNum: number } & RequestDataBase,
  ): Promise<{ lesson: Lesson }> => {
    const { lessonNum, ...rest } = params
    const url = `/p/api/lesson/info-by-lesson-num/${lessonNum}`
    return this.withData.post(url, ...splitParams(rest))
  }

  create = (
    params: {
      lessonNum: number
      section: string
      title: string
      intro?: string
      goal: string
      lessonTip?: string
      subtitles: string[]
      youtubeUrl?: string
    } & RequestDataBase,
  ): Promise<{ lesson: Lesson }> => {
    const url = '/api/lesson/create'
    return this.withData.postJson(url, ...splitParams(params))
  }

  update = (
    params: {
      lessonId: string
      lessonNum: number
      section: string
      title: string
      intro?: string
      goal: string
      lessonTip?: string
      subtitles: string[]
      youtubeUrl?: string
    } & RequestDataBase,
  ): Promise<{ lesson: Lesson }> => {
    const { lessonId, ...rest } = params
    const url = `/api/lesson/update/${lessonId}`
    return this.withData.postJson(url, ...splitParams(rest))
  }

  updateLecture = (
    params: {
      lessonId: string
      lecture: string
      teachingGuide: string
    } & RequestDataBase,
  ): Promise<{ lesson: Lesson }> => {
    const { lessonId, ...rest } = params
    const url = `/api/lesson/update-lecture/${lessonId}`
    return this.withData.postJson(url, ...splitParams(rest))
  }

  /**
   * 강의 파일 업로드
   * kind=LECTURE: 강의자료
   * kind=LECTURE_PREVIEW: 강의자료 PDF
   * kind=TEACHING_GUIDE: 교수 자료
   * kind=TEACHING_GUIDE_PREVIEW: 교수 자료 PDF
   */
  uploadFile = (
    params: {
      lessonId: string
      file: Blob
      fileName: string
      fileKind: 'LECTURE' | 'LECTURE_PREVIEW' | 'TEACHING_GUIDE' | 'TEACHING_GUIDE_PREVIEW'
    } & RequestDataBase,
  ): Promise<{ file: UploadedFile }> => {
    const { file, lessonId, fileName, fileKind, ctx } = params
    const url = `/api/lesson/upload/${lessonId}`

    const formData = new FormData()
    formData.append('file', file, fileName)
    formData.append('fileKind', fileKind)
    return this.withData.postMultipart(url, formData, { ctx })
  }

  /**
   * Lesson 삭제
   */
  delete = async (
    params: {
      lessonId: string
    } & RequestDataBase,
  ): Promise<void> => {
    const { lessonId, ...rest } = params
    const url = `/api/lesson/delete/${lessonId}`
    await this.helper.post(url, ...splitParams(rest))
  }

  /**
   * LessonFile 삭제
   */
  deleteFile = async (
    params: {
      lessonId: string
      fileId: string
    } & RequestDataBase,
  ): Promise<void> => {
    const url = '/api/lesson/delete-file'
    await this.helper.post(url, ...splitParams(params))
  }
}
