import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { I18nLangTypeKey, I18nLocaleKey, I18nSectionTypeKey } from '../../enum-types'
import { I18nMsg } from '../../model'

/**
 * I18n API
 */
export class I18nApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * i18n 메시지 목록 조회
   */
  msgList = (
    params: {
      langType: I18nLangTypeKey
      sectionType: I18nSectionTypeKey
    } & RequestDataBase,
  ): Promise<{
    i18nMsgList: I18nMsg[]
  }> => {
    const { langType, sectionType, ...rest } = params
    const url = `/p/api/i18n/msg-list/${langType}/${sectionType}`
    return this.withData.postJson(url, ...splitParams(rest))
  }

  /**
   * 모든 메시지 조회
   * 로케일별 메시지 매핑
   * Map<언어, Map<메시지키,내용>>
   */
  allMessagesFlatten = (
    params: RequestDataBase,
  ): Promise<{
    allMessages: Record<I18nLocaleKey, Record<string, string>>
  }> => {
    const url = '/p/api/i18n/msg-list-all-flatten'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 모든 메시지 조회
   * 로케일별 메시지 매핑
   * Map<언어, Map<섹션, Map<메시지키,내용>>>
   */
  allMessagesPerSection = (
    params: RequestDataBase,
  ): Promise<{
    allMessages: Record<I18nLocaleKey, Record<I18nSectionTypeKey, Record<string, string>>>
  }> => {
    const url = '/p/api/i18n/msg-list-all'
    return this.withData.post(url, ...splitParams(params))
  }
}
