import { ApiHelper, ApiHelperWithData, PagingRequest, RequestDataBase } from '../../api'
import { PagerData, PubSimul, PublishedSimulFile } from '../../model'

/**
 * PubSimul Api
 */
export class SimulPubApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 일반 사용자에게 공개된 시뮬레이터 정보를 조회한다.
   * 배포된 적이 있어야 하고, 공개된 정보만 조회한다.
   */
  info = (
    params: {
      simulId: number
    } & RequestDataBase,
  ): Promise<{ simul: PubSimul }> => {
    const { simulId, ctx } = params
    const url = `/p/api/simul-pub/info/${simulId}`

    return this.withData.postAndSecuredResponse(url, { simulId }, { ctx })
  }

  simulList = (
    params: {
      companyName?: string
      userId?: string
      simulName?: string
      ownerType?: 'PERSONAL' | 'COMPANY'
    } & PagingRequest,
  ): Promise<{ pagerData: PagerData<PubSimul> }> => {
    const { ctx, rowsPerPage, pageNumber, companyName, userId, simulName, ownerType } = params
    const url = '/p/api/simul-pub/simul-list'

    return this.withData.postJsonAndSecuredResponse(
      url,
      {
        rowsPerPage,
        pageNumber,
        companyName,
        userId,
        simulName,
        ownerType,
      },
      { ctx },
    )
  }

  publish = async (
    params: {
      simulId: number
    } & RequestDataBase,
  ): Promise<void> => {
    const { simulId, ctx } = params
    const url = `/api/simul-pub/publish/${simulId}`

    await this.helper.post(url, { simulId }, { ctx })
  }

  unpublish = async (
    params: {
      simulId: number
    } & RequestDataBase,
  ): Promise<void> => {
    const { simulId, ctx } = params
    const url = `/api/simul-pub/unpublish/${simulId}`

    await this.helper.post(url, { simulId }, { ctx })
  }

  files = (
    params: {
      simulIds: number[]
    } & RequestDataBase,
  ): Promise<{ publishedSimulFileList: PublishedSimulFile[] }> => {
    const { simulIds, ctx } = params
    const url = '/p/api/simul-pub/files'

    return this.withData.postJson(url, { simulIds }, { ctx })
  }

  file = (
    params: {
      simulId: number
    } & RequestDataBase,
  ): Promise<{ publishedSimulFile: PublishedSimulFile }> => {
    const { simulId, ctx } = params
    const url = `/p/api/simul-pub/file/${simulId}`

    return this.withData.post(url, undefined, { ctx })
  }

  infos = (
    params: {
      simulIds: number[]
    } & RequestDataBase,
  ): Promise<{ simulList: PubSimul[] }> => {
    const { simulIds, ctx } = params
    const url = '/p/api/simul-pub/simul-infos'

    return this.withData.postJsonAndSecuredResponse(url, { simulIds }, { ctx })
  }
}
