import urlParse from 'url-parse'

export class UrlUtils {
  static pathName = (urlString: string) => {
    const url = urlParse(urlString, true)
    return url.pathname
  }

  static pathSegments = (urlString: string) => {
    const url = urlParse(urlString, true)
    return url.pathname.split('/').filter((item) => item.length > 0)
  }

  static lastPathSegment = (urlString: string) => {
    const pathSegments = UrlUtils.pathSegments(urlString)
    if (pathSegments.length === 0) return undefined
    return pathSegments[pathSegments.length - 1]
  }
}
