import {
  PagingRequest,
  ApiHelper,
  ApiHelperWithData,
  RequestDataBase,
  WasUrlHelper,
  splitParams,
} from '../../api'
import { StudentGradeKey, UserAccountStateKey } from '../../enum-types'
import { PagerData, UserAccountForAdmin, UserActivity } from '../../model'

/**
 * User Api
 */
export class UserApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 사용자 목록 조회
   */
  userList = async (
    params: {
      userId?: string
      studentGrades?: StudentGradeKey[]
      userAccountState?: UserAccountStateKey[]
    } & PagingRequest,
  ): Promise<{ pagerData: PagerData<UserAccountForAdmin> }> => {
    const url = '/admin/api/user/list'
    const response: { pagerData: PagerData<UserAccountForAdmin> } = await this.withData.postJson(
      url,
      ...splitParams(params),
    )
    response.pagerData.elements?.forEach((item) => {
      WasUrlHelper.replaceRelative(item, 'profileImageUrl')
    })
    return response
  }

  /**
   * 사용자 정보 조회
   */
  userInfo = async (
    params: { accountId: number } & RequestDataBase,
  ): Promise<{ userInfo: UserAccountForAdmin }> => {
    const { accountId, ...rest } = params
    const url = `/admin/api/user/info/${accountId}`

    const response: { userInfo: UserAccountForAdmin } = await this.withData.post(
      url,
      ...splitParams(rest),
    )
    WasUrlHelper.replaceRelative(response.userInfo, 'profileImageUrl')
    return response
  }

  /**
   * 사용자 활동 이력 조회
   */
  activities = (
    params: { accountId: number } & RequestDataBase,
  ): Promise<{ activityList: UserActivity[] }> => {
    const url = '/admin/api/user-activity/list'

    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 사용자 계정 unlock
   */
  unlockUser = async (params: { accountId: number } & RequestDataBase): Promise<void> => {
    const url = '/admin/api/user/unlock'
    await this.helper.post(url, ...splitParams(params))
    return
  }

  /**
   * 사용자 비밀번호 변경
   */
  updateUserPw = async (
    params: { accountId: number; pw: string } & RequestDataBase,
  ): Promise<{ userInfo: UserAccountForAdmin }> => {
    const url = '/admin/api/user/update-user-pw'
    const response: { userInfo: UserAccountForAdmin } = await this.withData.post(
      url,
      ...splitParams(params),
    )

    WasUrlHelper.replaceRelative(response.userInfo, 'profileImageUrl')
    return response
  }
}
