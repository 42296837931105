import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { AppLogLevelKey } from '../../enum-types'
import { JobConfig, JobLog, PagerData } from '../../model'

/**
 * Job Api
 */
export class JobApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * Job 목록 조회
   */
  jobList = (params: RequestDataBase): Promise<{ jobConfigList: JobConfig[] }> => {
    const url = '/admin/api/job/list'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * Job 로그 목록 조회 - 페이징
   */
  jobLogList = (
    params: {
      rowsPerPage: number
      pageNumber: number
      jobId?: string
      logLevel?: AppLogLevelKey[]
      logYyyymmdd?: string
    } & RequestDataBase,
  ): Promise<{ jobLogPagerData: PagerData<JobLog> }> => {
    const url = '/admin/api/job/job-log-list'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
