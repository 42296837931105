import {
  ApiHelper,
  ApiHelperWithData,
  ApiRequestContext,
  RequestDataBase,
  splitParams,
} from '../../api'
import { DSModelTypeKey } from '../../enum-types'
import { DSKMeansModel, DSModel, DSModelSimple, ModelItem, PagerData } from '../../model'

/**
 * Data Science Sample Text Api
 */
export class DSUserModelApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 사용자 모델 생성
   */
  createModel = (
    params: {
      modelName: string
      modelType: DSModelTypeKey
    } & RequestDataBase,
  ): Promise<{ model: DSModel }> => {
    const url = '/api/ds-user-model/create'
    return this.withData.postJsonAndSecuredResponse(url, ...splitParams(params))
  }

  /**
   * 사용자 모델 목록 조회
   * 페이지 단위 조회
   */
  list = (
    params: {
      rowsPerPage: number
      pageNumber: number
    } & RequestDataBase,
  ): Promise<{ modelPagerData: PagerData<DSModel> }> => {
    const url = '/api/ds-user-model/list'
    return this.withData.postJsonAndSecuredResponse(url, ...splitParams(params))
  }

  /**
   * @deprecated listAllForBlocks()로 대체한다. 20230601에 지우자
   *
   * 사용자 모델 목록 조회
   * 전체 조회
   * 원래는 암호화할 필요가 없음 - 실수로 서버에서 이미 암호화해버려서 그냥 암호화한것
   */
  listAll = (ctx?: ApiRequestContext): Promise<{ modelList: DSModelSimple[] }> => {
    const url = '/api/ds-user-model/list-all'
    return this.withData.postJsonAndSecuredResponse(url, undefined, { ctx })
  }

  /**
   * 블록코딩을 위한 사용자의 전체 모델 목록 조회
   */
  listAllForBlockly = (
    ctx?: ApiRequestContext,
  ): Promise<{
    knnImageModels: ModelItem[]
    knnTextModels: ModelItem[]
    regressModels: ModelItem[]
    kmeansModels: DSKMeansModel[]
  }> => {
    const url = '/api/ds-user-model/list-all-for-blockly'
    return this.withData.postJson(url, undefined, { ctx })
  }

  /**
   * GEN 파일에 포함된 모델의 목록을 조회
   */
  listByModelIds = (
    params: {
      modelIds: string[]
    } & RequestDataBase,
  ): Promise<{
    modelList: ModelItem[]
    kmeansModelList: DSKMeansModel[]
  }> => {
    const { modelIds, ctx } = params
    const url = '/p/api/ds-user-model/simple-list-by-ids'
    return this.withData.post(url, { modelIds: modelIds.join(',') }, { ctx })
  }

  /**
   * 사용자 모델 삭제
   */
  delete = async (
    params: {
      modelId: string
    } & RequestDataBase,
  ): Promise<void> => {
    const { modelId, ...rest } = params
    const url = `/api/ds-user-model/delete/${modelId}`
    await this.helper.post(url, ...splitParams(rest))
  }
}
