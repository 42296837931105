import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { WebPropSimple } from '../../model'

export class WebPropApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * WebProp 단건 조회 by propId
   */
  info = (
    params: {
      propId: number
    } & RequestDataBase,
  ): Promise<{ webProp: WebPropSimple }> => {
    const url = '/p/api/web-prop/info'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * WebProp 단건 조회 by propKey
   */
  findByPropKey = (
    params: {
      propKey: string
    } & RequestDataBase,
  ): Promise<{ webProp: WebPropSimple }> => {
    const url = '/p/api/web-prop/find'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * WebProp 단건 조회 by propKey
   */
  findByPropKeys = (
    params: {
      propKeys: string[]
    } & RequestDataBase,
  ): Promise<{ webPropList: WebPropSimple[] }> => {
    const url = '/p/api/web-prop/find-by-prop-keys'
    const { propKeys, ...rest } = params
    return this.withData.post(url, { propKeys: propKeys.join(',') }, rest)
  }
}
