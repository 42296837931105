import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { KmcisGenderKey, KmcisNationKey, KmcisPageIdKey, KmcisPhoneCorpKey } from '../../enum-types'

/**
 * Kmcis API
 * kmcis 본인인증 API
 */
export class KmcisApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  request = (
    params: {
      pageId: KmcisPageIdKey
      userName: string
      phoneNumber: string
      phoneCorp: KmcisPhoneCorpKey
      birthYyyymmdd: string
      gender: KmcisGenderKey
      nation: KmcisNationKey
    } & RequestDataBase,
  ): Promise<{ reqId: string; tr_cert: string; tr_url: string; tr_add: string }> => {
    const url = '/p/api/kmcis/request'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
