import IntlStore from './sub-stores/IntlStore'
import SidebarStore from './sub-stores/SidebarStore'
import UiStore from './sub-stores/UiStore'
import { RootStoreInitialState } from './types'

/**
 * Mobx 루트 스토어
 */
class RootStore {
  uiStore = new UiStore()

  sidebarStore = new SidebarStore()

  intlStore = new IntlStore()

  allStores = {
    uiStore: this.uiStore,
    sidebarStore: this.sidebarStore,
    intlStore: this.intlStore,
  }

  /**
   * hydrate
   * @param data for nextjs hydrate
   */

  hydrate = (data: RootStoreInitialState | null | undefined) => {
    // log.debug('store hydrate:', data)
    // if (!data) return
    // this.intlStore.hydrate(data.intlStoreData)
    // add other stores
  }
}

export default RootStore
