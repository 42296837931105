import { ApiHelper, ApiHelperWithData, RequestDataBase, HTTP_TOKEN_HEADER } from '../../api'
import { txCipher } from '../../lib/PayloadCipher'
import { UserAccount } from '../../model'

/**
 * SSO API
 */
export class SsoApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 로그인
   */
  signIn = (
    params: {
      providerId: string
      authProvider: 'aice' | 'milkt'
    } & RequestDataBase,
  ): Promise<{ userProfile: UserAccount }> => {
    const url = '/p/api/auth/sso'
    const header = { [HTTP_TOKEN_HEADER]: '' }
    const { providerId, authProvider, ctx } = params

    const txId = txCipher.createTxId()
    const payload = txCipher.encrypt(txId, {
      txId,
      providerId,
      authProvider,
    })
    return this.withData.postJsonAndSecuredResponse(url, payload, { ctx, header })
  }

  /**
   * 로그아웃
   */
  signOut = async (params: RequestDataBase): Promise<void> => {
    const url = '/api/auth/logout'
    const { ctx } = params
    await this.helper.post(url, undefined, { ctx })
  }
}
