import {
  ApiHelper,
  ApiHelperWithData,
  PagingRequest,
  RequestDataBase,
  splitParams,
} from '../../api'
import { BlackUserId, PagerData } from '../../model'

/**
 * BlackUserId Api
 * 등록할 수 없는 사용자ID 목록을 관리하는 API
 */
export class BlackUserIdApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * BlackUserId 추가
   */
  add = async (
    params: {
      userId: string[]
    } & RequestDataBase,
  ): Promise<void> => {
    const url = '/admin/api/black-user-id/add'
    await this.helper.postJson(url, ...splitParams(params))
  }

  /**
   * BlackUserId 삭제
   */
  remove = async (params: { userId: string[] } & RequestDataBase): Promise<void> => {
    const url = '/admin/api/black-user-id/remove'
    await this.helper.postJson(url, ...splitParams(params))
  }

  /**
   * BlackUserId 목록 조회
   */
  list = (
    params: {
      userId?: string | null
    } & PagingRequest,
  ): Promise<{ pagerData: PagerData<BlackUserId> }> => {
    const url = '/admin/api/black-user-id/list'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
