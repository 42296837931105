import { ApiHelper, ApiHelperWithData, RequestDataBase, WasUrlHelper, splitParams } from '../../api'
import { Gallery, GalleryDataCount, GalleryReply, PagerData } from '../../model'

/**
 * Gallery API
 */
export class GalleryApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 갤러리 목록 조회
   */
  list = async (
    params: {
      rowsPerPage: number
      pageNumber: number
      writeUserId?: string
    } & RequestDataBase,
  ): Promise<{ pagerData: PagerData<Gallery> }> => {
    const url = '/admin/api/gallery/list'
    const response: { pagerData: PagerData<Gallery> } = await this.withData.postJson(
      url,
      ...splitParams(params),
    )
    response.pagerData.elements?.forEach((item) => {
      WasUrlHelper.replaceRelative(item, 'genFileUrl', 'thumbnailUrl')
    })
    return response
  }

  /**
   * 갤러리 상세 조회
   */
  info = async (params: { galleryId: number } & RequestDataBase): Promise<{ gallery: Gallery }> => {
    const { galleryId, ...rest } = params
    const url = `/admin/api/gallery/info/${galleryId}`
    const response: { gallery: Gallery } = await this.withData.post(url, ...splitParams(rest))
    WasUrlHelper.replaceRelative(response.gallery, 'genFileUrl', 'thumbnailUrl')
    return response
  }

  /**
   * 특정 갤러리의 댓글 목록 조회
   */
  replyList = (
    params: {
      galleryId: number
      rowsPerPage: number
      pageNumber: number
    } & RequestDataBase,
  ): Promise<{ pagerData: PagerData<GalleryReply> }> => {
    const { galleryId, pageNumber, ...rest } = params
    const url = `/admin/api/gallery/reply/list/${galleryId}/${pageNumber}`

    return this.withData.post(url, ...splitParams(rest))
  }

  /**
   * 모든 댓글 목록 조회
   */
  replyListAll = (
    params: {
      writeUserId?: string
      rowsPerPage: number
      pageNumber: number
    } & RequestDataBase,
  ): Promise<{ pagerData: PagerData<GalleryReply> }> => {
    const url = '/admin/api/gallery/reply/list-all'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 갤러리 삭제
   */
  delete = async (params: { galleryId: number } & RequestDataBase): Promise<void> => {
    const { galleryId, ...rest } = params
    const url = `/admin/api/gallery/delete/${galleryId}`
    await this.helper.post(url, ...splitParams(rest))
  }

  /**
   * 댓글 또는 대댓글 삭제
   */
  deleteReply = (
    params: { replyId: number } & RequestDataBase,
  ): Promise<{ dataCount: GalleryDataCount }> => {
    const { replyId, ...rest } = params
    const url = `/admin/api/gallery/reply/delete/${replyId}`
    return this.withData.post(url, ...splitParams(rest))
  }
}
