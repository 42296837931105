import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { AdminAccessPolicyCodeKey, AdminBlockReasonKey } from '../../enum-types'
import {
  AdminAccessPolicy,
  AdminAllowIp,
  AdminBlockIp,
  AdminBlockPolicy,
  PagerData,
  UserBlockPolicy,
} from '../../model'

export class AdminAccessApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  policyInfo = (
    params: RequestDataBase,
  ): Promise<{
    policy: AdminAccessPolicy
    userBlockPolicy: UserBlockPolicy
    adminBlockPolicy: AdminBlockPolicy
  }> => {
    const url = '/admin/admin-access/policy/info'
    return this.withData.post(url, ...splitParams(params))
  }

  updatePolicy = async (
    params: { policyCode: AdminAccessPolicyCodeKey } & RequestDataBase,
  ): Promise<void> => {
    const url = '/admin/admin-access/policy/update'
    await this.helper.post(url, ...splitParams(params))
  }

  addAllowIp = async (
    params: {
      ip: string
      memo?: string
    } & RequestDataBase,
  ): Promise<void> => {
    const url = '/admin/admin-access/allow-ip/add'
    await this.helper.post(url, ...splitParams(params))
  }

  removeAllowIp = async (
    params: {
      ip: string
    } & RequestDataBase,
  ): Promise<void> => {
    const url = '/admin/admin-access/allow-ip/remove'
    await this.helper.post(url, ...splitParams(params))
  }

  addBlockIp = async (
    params: {
      ip: string
      expireTimeMilli: number
      reasonCode: AdminBlockReasonKey
      reasonText?: string
    } & RequestDataBase,
  ): Promise<void> => {
    const url = '/admin/admin-access/block-ip/add'
    await this.helper.postJson(url, ...splitParams(params))
  }

  removeBlockIp = async (
    params: {
      rid: number
    } & RequestDataBase,
  ): Promise<void> => {
    const url = '/admin/admin-access/block-ip/remove'
    await this.helper.post(url, ...splitParams(params))
  }

  allowIpList = (
    params: {
      pageNumber: number
      rowsPerPage: number
    } & RequestDataBase,
  ): Promise<{ pagerData: PagerData<AdminAllowIp> }> => {
    const url = '/admin/admin-access/allow-ip/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  blockIpList = (
    params: {
      pageNumber: number
      rowsPerPage: number
    } & RequestDataBase,
  ): Promise<{ pagerData: PagerData<AdminBlockIp> }> => {
    const url = '/admin/admin-access/block-ip/list'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
