import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { DSSampleGridData, PagerData } from '../../model'

/**
 * Data Science Sample Grid Api
 */
export class DSSampleGridApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * Sample Grid 목록 조회
   */
  list = (
    params: {
      rowsPerPage: number
      pageNumber: number
    } & RequestDataBase,
  ): Promise<{ pagerData: PagerData<DSSampleGridData> }> => {
    const url = '/p/api/ds-data/sample-grid-list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * Sample Grid 정보 조회
   */
  info = (
    params: {
      dataId: number
    } & RequestDataBase,
  ): Promise<{ sampleGridData: DSSampleGridData }> => {
    const { dataId, ...rest } = params
    const url = `/p/api/ds-data/sample-grid-data/${dataId}`
    return this.withData.postJson(url, ...splitParams(rest))
  }
}
