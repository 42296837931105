import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'
import { KoreaMajorCityKey, WeatherCode } from '../../block'

/**
 * Forecast Api
 */
export class ForecastApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  pm = ({
    ctx,
    sido,
  }: RequestDataBase & {
    sido: string
  }): Promise<{
    sido: string
    pm10: number
    pm25: number
  }> => {
    const url = '/api/v1/pm'
    const params = {
      sido,
    }
    return this.withData.postJson(url, params, { ctx })
  }

  /**
   * sido 파라미터는 CityKey이거나 도시이름이어도 된다
   * @param params
   * @returns
   */
  weather = (
    params: RequestDataBase & {
      sido: KoreaMajorCityKey | string
      weatherCode: WeatherCode
    },
  ): Promise<{
    resultData: string
  }> => {
    const url = '/api/v1/weather'
    const { sido, weatherCode, ctx } = params
    return this.withData.postJson(url, { sido, weatherCode }, { ctx })
  }
}
