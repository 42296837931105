import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { GalleryQueryFieldKey, GallerySortTypeKey, GalleryStampKind } from '../../enum-types'
import {
  Gallery,
  GalleryDataCount,
  GalleryPrize,
  GalleryPrizeSearchOption,
  GalleryReply,
  GallerySearchOption,
  PagerData,
  PagingQuery,
} from '../../model'

/**
 * Gallery API
 */
export class GalleryApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 갤러리 상세 조회
   */
  info = (params: { galleryId: number } & RequestDataBase): Promise<{ gallery: Gallery }> => {
    const { ctx, galleryId } = params
    const url = `/p/api/gallery/info/${galleryId}`
    return this.withData.postAndSecuredResponse(url, undefined, { ctx })
  }

  /**
   * 찜한 목록 조회
   */
  chimList = (
    params: {
      sortType: GallerySortTypeKey
      queryField: GalleryQueryFieldKey
      query?: string
    } & PagingQuery &
      RequestDataBase,
  ): Promise<{ pagerData: PagerData<Gallery> }> => {
    const url = '/api/gallery/chim-list'
    return this.withData.postJsonAndSecuredResponse(url, ...splitParams(params))
  }

  /**
   * 갤러리 목록 조회
   */
  list = (
    params: GallerySearchOption & RequestDataBase,
  ): Promise<{ pagerData: PagerData<Gallery> }> => {
    const url = '/p/api/gallery/list'
    return this.withData.postJsonAndSecuredResponse(url, ...splitParams(params))
  }

  /**
   * 갤러리 수상작품 목록 조회
   */
  prizeList = (
    params: GalleryPrizeSearchOption & RequestDataBase,
  ): Promise<{ pagerData: PagerData<Gallery> }> => {
    const url = '/p/api/gallery/prize-list'
    return this.withData.postJsonAndSecuredResponse(url, ...splitParams(params))
  }

  /**
   * 댓글 목록 조회
   */
  replyList = (
    params: { galleryId: number; rowsPerPage: number; pageNumber: number } & RequestDataBase,
  ): Promise<{ pagerData: PagerData<GalleryReply> }> => {
    const { galleryId, pageNumber, ...rest } = params
    const url = `/p/api/gallery/reply/list/${galleryId}/${pageNumber}`
    return this.withData.postAndSecuredResponse(url, ...splitParams(rest))
  }

  /**
   * 갤러리 신규 등록
   */
  create = (
    params: {
      projectId: number
      youtubeUrl?: string
      title: string
      intro?: string
      hashtag?: string
      substance?: string
      txId: string
    } & RequestDataBase,
  ): Promise<{ gallery: Gallery }> => {
    const url = '/api/gallery/create'
    return this.withData.postJsonAndSecuredResponse(url, ...splitParams(params))
  }

  /**
   * 갤러리 업데이트
   */
  update = (
    params: {
      galleryId: number
      title: string
      youtubeUrl?: string
      intro: string
      hashtag?: string
      substance?: string
      stampKind?: GalleryStampKind
      prize?: GalleryPrize
      sortKey?: string
      txId: string
    } & RequestDataBase,
  ): Promise<{ gallery: Gallery }> => {
    const { galleryId, ...rest } = params
    const url = `/api/gallery/update/${galleryId}`
    return this.withData.postJsonAndSecuredResponse(url, ...splitParams(rest))
  }

  /**
   * 댓글 작성
   */
  createReply = (
    params: {
      galleryId: number
      substance: string
      txId: string
    } & RequestDataBase,
  ): Promise<{ reply: GalleryReply; dataCount: GalleryDataCount }> => {
    const { galleryId, ...rest } = params
    const url = `/api/gallery/reply/write/${galleryId}`
    return this.withData.postAndSecuredResponse(url, ...splitParams(rest))
  }

  /**
   * 대댓글 작성
   */
  createGreatReply = (
    params: {
      parentReplyId: number
      substance: string
      txId: string
    } & RequestDataBase,
  ): Promise<{ reply: GalleryReply; dataCount: GalleryDataCount }> => {
    const { parentReplyId, ...rest } = params
    const url = `/api/gallery/great-reply/write/${parentReplyId}`
    return this.withData.postAndSecuredResponse(url, ...splitParams(rest))
  }

  /**
   * 댓글 또는 대댓글 수정
   */
  updateReply = (
    params: {
      replyId: number
      substance: string
      txId: string
    } & RequestDataBase,
  ): Promise<{ reply: GalleryReply; dataCount: GalleryDataCount }> => {
    const url = '/api/gallery/reply/update'
    return this.withData.postAndSecuredResponse(url, ...splitParams(params))
  }

  /**
   * 갤러리 삭제
   */
  delete = async (params: { galleryId: number } & RequestDataBase): Promise<void> => {
    const { galleryId, ...rest } = params
    const url = `/api/gallery/delete/${galleryId}`
    await this.helper.post(url, ...splitParams(rest))
  }

  /**
   * 댓글 또는 대댓글 삭제
   */
  deleteReply = (
    params: { replyId: number } & RequestDataBase,
  ): Promise<{ dataCount: GalleryDataCount }> => {
    const { replyId, ...rest } = params
    const url = `/api/gallery/reply/delete/${replyId}`
    return this.withData.post(url, ...splitParams(rest))
  }

  /**
   * 갤러리의 좋아요 추가
   * 좋아요 건수를 응답
   */
  favorAdd = (
    params: {
      galleryId: number
    } & RequestDataBase,
  ): Promise<{ count: number }> => {
    const { galleryId, ...rest } = params
    const url = `/api/gallery/favor/add/${galleryId}`
    return this.withData.post(url, ...splitParams(rest))
  }

  /**
   * 갤러리의 좋아요 삭제
   * 좋아요 건수를 응답
   */
  favorRemove = (
    params: {
      galleryId: number
    } & RequestDataBase,
  ): Promise<{ count: number }> => {
    const { galleryId, ...rest } = params
    const url = `/api/gallery/favor/remove/${galleryId}`
    return this.withData.post(url, ...splitParams(rest))
  }

  /**
   * 갤러리의 찜 추가
   * 찜 건수를 응답
   */
  chimAdd = (
    params: {
      galleryId: number
    } & RequestDataBase,
  ): Promise<{ count: number }> => {
    const { galleryId, ...rest } = params
    const url = `/api/gallery/chim/add/${galleryId}`
    return this.withData.post(url, ...splitParams(rest))
  }

  /**
   * 갤러리의 찜 삭제
   * 찜 건수를 응답
   */
  chimRemove = (
    params: {
      galleryId: number
    } & RequestDataBase,
  ): Promise<{ count: number }> => {
    const { galleryId, ...rest } = params
    const url = `/api/gallery/chim/remove/${galleryId}`
    return this.withData.post(url, ...splitParams(rest))
  }

  /**
   * 댓글의 좋아요 추가
   * 댓글의 좋아요 건수를 응답
   */
  replyFavorAdd = (
    params: {
      replyId: number
    } & RequestDataBase,
  ): Promise<{ count: number }> => {
    const { replyId, ...rest } = params
    const url = `/api/gallery/reply/favor-add/${replyId}`
    return this.withData.post(url, ...splitParams(rest))
  }

  /**
   * 댓글의 좋아요 삭제
   * 댓글의 좋아요 건수를 응답
   */
  replyFavorRemove = (
    params: {
      replyId: number
    } & RequestDataBase,
  ): Promise<{ count: number }> => {
    const { replyId, ...rest } = params
    const url = `/api/gallery/reply/favor-remove/${replyId}`
    return this.withData.post(url, ...splitParams(rest))
  }

  /**
   * 갤러리 도장 업데이트
   */
  updateGalleryStamp = (
    params: {
      galleryId: number
      stampKind: GalleryStampKind
    } & RequestDataBase,
  ): Promise<{ gallery: Gallery }> => {
    const { galleryId, ...rest } = params
    const url = `/api/gallery/stamp-update/${galleryId}`
    return this.withData.postAndSecuredResponse(url, ...splitParams(rest))
  }
}
