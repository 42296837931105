import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { AppError } from '../errors'

export interface RequestCancel {
  cancel?: (message?: string) => void
}

export interface ApiRequestContext {
  canceled: boolean
  config?: AxiosRequestConfig
  cancel?: (message?: string) => void
}

export interface ApiResponse {
  success: boolean
  errorCode?: string
  // 왜 message로 했을까? errorMessage인데? 확인해보자
  message?: string
  errorMessage?: string
}

export interface ApiResponseWithData<T> extends ApiResponse {
  body: T
}

export interface ApiResponseWithSecuredBody extends ApiResponse {
  signature: string
  txId: string
  timestamp: number
  securedBody: string
}

export interface RequestDataBase {
  ctx?: ApiRequestContext
  header?: Record<string, string>
}

export interface PagingRequest extends RequestDataBase {
  rowsPerPage: number
  pageNumber: number
}

export type ResponseInterceptor = (
  response: AxiosResponse,
) => AxiosResponse | Promise<AxiosResponse>

export type ErrorResponseInterceptor = (error: AppError) => void

export function splitParams<T extends RequestDataBase>(
  params: T,
): [Omit<T, 'ctx' | 'header'>, RequestDataBase] {
  const { ctx, header, ...rest } = params
  return [rest, { ctx, header }]
}

// export function wasUrlToRelative(url: string): string {
//   let v = url
//   while (v.includes('https://aicodiny.com/aimk-server/')) {
//     v = v.replace('https://aicodiny.com/aimk-server/', '/')
//   }
//   return v
// }
//
// export function wasUrlToRelative2(url?: string | null): string | undefined | null {
//   if (!url) return url
//   return wasUrlToRelative(url)
// }

export class WasUrlHelper {
  static toRelative = (url: string): string => {
    let v = url
    while (v.includes('https://aicodiny.com/aimk-server/')) {
      v = v.replace('https://aicodiny.com/aimk-server/', '/aimk-server/')
    }
    return v
  }

  static toRelative2 = (url: string | undefined | null): string | undefined | null => {
    if (!url) return url
    return WasUrlHelper.toRelative(url)
  }

  private static replaceRelative_ = <T extends {}>(obj: T, propertyName: string): T => {
    const v = obj[propertyName]
    if (typeof v === 'string') {
      obj[propertyName] = WasUrlHelper.toRelative(v)
    }
    return obj
  }

  static replaceRelative = <T extends {}>(
    obj: T | undefined,
    ...propertyNames: (keyof T)[]
  ): T | undefined => {
    if (!obj) return obj
    propertyNames.forEach((propertyName) => {
      WasUrlHelper.replaceRelative_(obj, propertyName as string)
    })
    return obj
  }
}
