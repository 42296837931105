import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { FaqSectionTypeKey } from '../../enum-types'
import { Faq } from '../../model'

/**
 * Faq Api
 */
export class FaqApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * Faq 신규 작성
   */
  faqWrite = async (
    params: {
      sectionType: FaqSectionTypeKey
      sortNumber: number
      title: string
      substance: string
    } & RequestDataBase,
  ) => {
    const url = '/admin/api/faq/write'
    await this.helper.post(url, ...splitParams(params))
  }

  /**
   * Faq 업데이트
   */
  faqUpdate = async (
    params: {
      faqId: number
      sectionType: FaqSectionTypeKey
      sortNumber: number
      title: string
      substance: string
    } & RequestDataBase,
  ) => {
    const url = '/admin/api/faq/update'
    await this.helper.post(url, ...splitParams(params))
  }

  /**
   * Faq 목록 조회
   */
  faqList = (params: RequestDataBase): Promise<{ faqList: Faq[] }> => {
    const url = '/admin/api/faq/list'
    return this.withData.post(url, ...splitParams(params))
    // const { faqList } = await this.withData.post(url, undefined, { ctx })
    // for (let i = 0; i < faqList.length; i++) {
    //     const faq = faqList[i] as Faq
    //     faq.sortNumber += FaqSectionSortNumber[faq.sectionType].value
    // }
    // faqList.sort((a: Faq, b: Faq) => a.sortNumber - b.sortNumber)
    // return { faqList }
  }

  /**
   * Faq 한건 조회
   */
  faqInfo = (
    params: {
      faqId: number
    } & RequestDataBase,
  ): Promise<{ faq: Faq }> => {
    const { faqId, ...rest } = params
    const url = `/admin/api/faq/info/${faqId}`
    return this.withData.post(url, ...splitParams(rest))
  }

  /**
   * Faq 삭제
   */
  faqDelete = async (
    params: {
      faqId: number
    } & RequestDataBase,
  ) => {
    const { faqId, ...rest } = params
    const url = `/admin/api/faq/delete/${faqId}`

    await this.helper.post(url, ...splitParams(rest))
  }
}
