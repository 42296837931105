import { ApiHelper, ApiHelperWithData, RequestDataBase, splitParams } from '../../api'
import { ClauseAgreeTypeKey } from '../../enum-types'
import { Clause, ClauseHistory, ClauseSubstance } from '../../model'

/**
 * 약관 API
 */
export class ClauseApi {
  private withData: ApiHelperWithData

  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 약관 목록 조회
   */
  list = (params: RequestDataBase): Promise<{ clauseList: Clause[] }> => {
    const url = '/admin/api/clause/list'
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 활성화된 특정 ID들의 약관 정보 조회
   */
  enabledInfos = (
    params: { clauseIds: number[] } & RequestDataBase,
  ): Promise<{ clauseList: Clause[] }> => {
    const url = '/admin/api/clause-enabled/infos'
    const { clauseIds, ...rest } = params
    const clauseIdsCsv = params.clauseIds.join(',')
    return this.withData.post(url, { clauseIds: clauseIdsCsv }, rest)
  }

  /**
   * 활성화된 전체 약관 목록 조회
   */
  enabledList = (params: RequestDataBase): Promise<{ clauseList: Clause[] }> => {
    const url = '/admin/api/clause-enabled/list'
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 약관 정보 조회
   */
  info = (params: { clauseId: number } & RequestDataBase): Promise<{ clause: Clause }> => {
    const { clauseId, ...rest } = params
    const url = `/admin/api/clause/${clauseId}/info`
    return this.withData.postJson(url, ...splitParams(rest))
  }

  /**
   * 약관 상세 조회 - 개정이력포함
   */
  details = (
    params: { clauseId: number } & RequestDataBase,
  ): Promise<{ clause?: Clause; clauseHistoryList: ClauseHistory[] }> => {
    const { clauseId, ...rest } = params
    const url = `/admin/api/clause/${clauseId}/details`
    return this.withData.postJson(url, ...splitParams(rest))
  }

  /**
   * 약관 신규 등록
   */
  insert = async (
    params: {
      title: string
      sortNumber: number
      agreeType: ClauseAgreeTypeKey
    } & RequestDataBase,
  ): Promise<void> => {
    const { title, sortNumber, agreeType, ...rest } = params
    const url = '/admin/api/clause/insert'
    const data = { clause: { title, sortNumber, agreeType } }
    await this.helper.postJson(url, data, rest)
  }

  /**
   * 약관 업데이트
   */
  update = async (
    params: {
      clauseId: number
      title: string
      sortNumber: number
      agreeType: ClauseAgreeTypeKey
    } & RequestDataBase,
  ): Promise<void> => {
    const { clauseId, title, sortNumber, agreeType, ...rest } = params
    const url = `/admin/api/clause/${clauseId}/update`
    const data = {
      clause: { clauseId, title, sortNumber, agreeType },
    }
    await this.helper.postJson(url, data, rest)
  }

  /**
   * 약관 삭제
   */
  delete = async (params: { clauseId: number } & RequestDataBase): Promise<void> => {
    const { clauseId, ...rest } = params
    const url = `/admin/api/clause/${clauseId}/delete`
    await this.helper.postJson(url, ...splitParams(rest))
  }

  /**
   * 약관 활성화
   */
  enable = async (params: { clauseId: number } & RequestDataBase): Promise<void> => {
    const { clauseId, ...rest } = params
    const url = `/admin/api/clause/${clauseId}/enable`
    await this.helper.postJson(url, ...splitParams(rest))
  }

  /**
   * 약관 비활성화
   */
  disable = async (params: { clauseId: number } & RequestDataBase): Promise<void> => {
    const { clauseId, ...rest } = params
    const url = `/admin/api/clause/${clauseId}/disable`
    await this.helper.postJson(url, ...splitParams(rest))
  }

  /**
   * 약관 재동의
   */
  reAgree = async (params: { clauseId: number } & RequestDataBase): Promise<void> => {
    const { clauseId, ...rest } = params
    const url = `/admin/api/clause/${clauseId}/re-agree`
    await this.helper.postJson(url, ...splitParams(rest))
  }

  /**
   * 약관 내용 조회
   */
  substanceInfo = (
    params: { substanceId: number } & RequestDataBase,
  ): Promise<{ clause: Clause; clauseSubstance: ClauseSubstance }> => {
    const { substanceId, ...rest } = params
    const url = `/admin/api/clause-substance/${substanceId}/info`
    return this.withData.postJson(url, ...splitParams(rest))
  }

  /**
   * 약관 내용 신규 등록
   */
  substanceInsert = async (
    params: {
      clauseId: number
      substance: string
      changeLog?: string
      yyyymmdd: string
      noticeYyyymmdd: string
    } & RequestDataBase,
  ): Promise<void> => {
    const { clauseId, substance, changeLog, yyyymmdd, noticeYyyymmdd, ...rest } = params
    const url = '/admin/api/clause-substance/insert'
    const data = {
      clauseId,
      clauseSubstance: {
        substance,
        yyyymmdd,
        noticeYyyymmdd,
        changeLog,
      },
    }
    await this.helper.postJson(url, data, rest)
  }
  /**
   * 약관 내용 업데이트
   */
  substanceUpdate = async (
    params: {
      substanceId: number
      substance: string
      changeLog?: string
      yyyymmdd: string
      noticeYyyymmdd: string
    } & RequestDataBase,
  ): Promise<void> => {
    const { substanceId, substance, changeLog, yyyymmdd, noticeYyyymmdd, ...rest } = params

    const url = `/admin/api/clause-substance/${substanceId}/update`
    const data = { clauseSubstance: { substance, yyyymmdd, noticeYyyymmdd, changeLog } }
    await this.helper.postJson(url, data, rest)
  }

  /**
   * 약관 내용 삭제
   */
  substanceDelete = async (params: { substanceId: number } & RequestDataBase): Promise<void> => {
    const { substanceId, ...rest } = params
    const url = `/admin/api/clause-substance/${substanceId}/delete`
    await this.helper.postJson(url, ...splitParams(rest))
  }

  /**
   * 약관 내용 활성화
   */
  substanceEnable = async (params: { substanceId: number } & RequestDataBase): Promise<void> => {
    const { substanceId, ...rest } = params
    const url = `/admin/api/clause-substance/${substanceId}/enable`
    await this.helper.postJson(url, ...splitParams(rest))
  }

  /**
   * 약관 내용 비활성화
   */
  substanceDisable = async (params: { substanceId: number } & RequestDataBase): Promise<void> => {
    const { substanceId, ...rest } = params
    const url = `/admin/api/clause-substance/${substanceId}/disable`
    await this.helper.postJson(url, ...splitParams(rest))
  }
}
