import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'
import { SecQuestionTypeKey } from '../../enum-types'
import { txCipher } from '../../lib/PayloadCipher'

/**
 * 비밀번호 관련 API
 */
export class PwApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 비밀번호 찾기 1/3단계: 사용자 ID 확인
   */
  checkUserId = (
    params: {
      userId: string
      txId: string
    } & RequestDataBase,
  ): Promise<{
    questionToken: string
    q1: SecQuestionTypeKey
    q2: SecQuestionTypeKey
    q3: SecQuestionTypeKey
  }> => {
    const url = '/p/api/user/pw-find/check-user-id'
    const { ctx, txId, userId } = params
    const encryptedBody = txCipher.encrypt(txId, {
      userId,
      txId,
    })
    return this.withData.postJsonAndSecuredResponse(url, encryptedBody, { ctx })
  }

  /**
   * 비밀번호 찾기 2/3단계: 보안질문 응답 검증
   */
  answerSecQuestion = (
    params: {
      txId: string
      questionToken: string
      a1: string
      a2: string
      a3: string
    } & RequestDataBase,
  ): Promise<{ pwResetToken: string }> => {
    const url = '/p/api/user/pw-find/answer-question'
    const { ctx, txId, questionToken, a1, a2, a3 } = params
    const encryptedBody = txCipher.encrypt(txId, {
      txId,
      questionToken,
      a1,
      a2,
      a3,
    })
    return this.withData.postJsonAndSecuredResponse(url, encryptedBody, { ctx })
  }

  /**
   * 비밀번호 찾기 3/3단계: 새로운 비밀번호 변경
   */
  saveNewPassword = async (
    params: {
      txId: string
      pwResetToken: string
      password: string
    } & RequestDataBase,
  ): Promise<void> => {
    const url = '/p/api/user/pw-find/save-new-passwd'
    const { ctx, txId, pwResetToken, password } = params
    const encryptedBody = txCipher.encrypt(txId, {
      txId,
      pwResetToken,
      password,
    })
    await this.helper.postJson(url, encryptedBody, { ctx })
  }
}
