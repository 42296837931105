import { ApiHelper, ApiHelperWithData, RequestDataBase, WasUrlHelper } from '../../api'
import { PublishedSimulFile, UploadedFile } from '../../model'

/**
 * PubSimul Api
 */
export class SimulFileUploadApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  uploadTempItemImage = async (
    params: {
      file: File
    } & RequestDataBase,
  ): Promise<{ file: UploadedFile }> => {
    const { file, ctx } = params
    const url = '/admin/api/simul-upload/item-image'
    const formData = new FormData()
    formData.append('file', file, file.name)
    const response: { file: UploadedFile } = await this.withData.postMultipart(url, formData, {
      ctx,
    })
    WasUrlHelper.replaceRelative(response.file, 'downloadUrl')
    return response
  }

  uploadTempBrandIcon = async (
    params: {
      file: File
    } & RequestDataBase,
  ): Promise<{ file: UploadedFile }> => {
    const { file, ctx } = params
    const url = '/admin/api/simul-upload/brand-icon'

    const formData = new FormData()
    formData.append('file', file, file.name)
    const response: { file: UploadedFile } = await this.withData.postMultipart(url, formData, {
      ctx,
    })
    WasUrlHelper.replaceRelative(response.file, 'downloadUrl')
    return response
  }

  uploadTempCateIcon = async (
    params: {
      file: File
    } & RequestDataBase,
  ): Promise<{ file: UploadedFile }> => {
    const { file, ctx } = params
    const url = '/admin/api/simul-upload/cate-icon'

    const formData = new FormData()
    formData.append('file', file, file.name)
    const response: { file: UploadedFile } = await this.withData.postMultipart(url, formData, {
      ctx,
    })
    WasUrlHelper.replaceRelative(response.file, 'downloadUrl')
    return response
  }

  files = (
    params: {
      simulIds: number[]
    } & RequestDataBase,
  ): Promise<{ publishedSimulFileList: PublishedSimulFile[] }> => {
    const { simulIds, ctx } = params
    const url = '/p/api/simul-pub/files'

    return this.withData.post(url, { simulIds }, { ctx })
  }

  file = (
    params: {
      simulId: number
    } & RequestDataBase,
  ): Promise<{ publishedSimulFile: PublishedSimulFile }> => {
    const { simulId, ctx } = params
    const url = `/p/api/simul-pub/file/${simulId}`

    return this.withData.post(url, undefined, { ctx })
  }
}
