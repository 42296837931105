const colors = {
  colorPrimary: '#2a4cda',
  colorSecondary: '#f44336',
  colorError: '#ff1744',
  // default grey
  colorBlackText0: '#000000',
  colorBlackText1: '#161616',
  colorBlackText2: '#242424',
  colorBlackText3: '#363636',
  colorBlackText4: '#424242',
  colorBlackText5: '#616161',
  colorBlackText6: '#757575',
  colorBlackText7: '#9e9e9e',
  colorBlackText8: '#bdbdbd',
  colorBlackText9: '#e0e0e0',
  colorBlackText10: '#eeeeee',
  colorBlackText11: '#f5f5f5',
  colorBlackText12: '#ffffff',

  // dark grey
  colorWhiteText9: '#242424',
  colorWhiteText8: '#3c3c3c',
  colorWhiteText7: '#525252',
  colorWhiteText6: '#696969',
  colorWhiteText5: '#7c7c7c',
  colorWhiteText4: '#909090',
  colorWhiteText3: '#a6a6a6',
  colorWhiteText2: '#bbb',
  colorWhiteText1: '#d0d0d0',

  // dark grey blue
  colorBlueText10: '#32323d',
  colorBlueText9: '#40404d',
  colorBlueText8: '#464650',
  colorBlueText7: '#5a5a63',
  colorBlueText6: '#6f6f77',
  colorBlueText5: '#84848a',
  colorBlueText4: '#98989e',
  colorBlueText3: '#adadb1',
  colorBlueText2: '#c1c1c4',
  colorBlueText1: '#d6d6d8',

  colorLightDivider0: 'rgba(255, 255, 255, 0.08)',
  colorLightDivider1: 'rgba(255, 255, 255, 0.1)',
  colorLightDivider2: 'rgba(255, 255, 255, 0.15)',
  colorDarkDivider0: 'rgba(0, 0, 0, 0.08)',
  colorDarkDivider1: 'rgba(0, 0, 0, 0.1)',
  colorDarkDivider2: 'rgba(0, 0, 0, 0.15)',
}

export default colors
