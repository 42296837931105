import { ApiHelper, ApiHelperWithData, RequestDataBase } from '../../api'
import { SimulLabel } from '../../model'

/**
 * SimulLabel Api
 */
export class SimulLabelApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 레이블 생성
   */
  create = (
    params: {
      simulId: number
      title: string
    } & RequestDataBase,
  ): Promise<{ label: SimulLabel }> => {
    const url = '/api/simul-label/create'
    const { simulId, title, ctx } = params

    return this.withData.postJson(url, { simulId, title }, { ctx })
  }

  /**
   * 레이블 수정
   */
  update = (
    params: {
      fid: string
      simulId: number
      title: string
    } & RequestDataBase,
  ): Promise<{ label: SimulLabel }> => {
    const { fid, simulId, title, ctx } = params
    const url = `/api/simul-label/update/${fid}`

    return this.withData.postJson(url, { simulId, title }, { ctx })
  }

  /**
   * 레이블 삭제
   */
  delete = async (
    params: {
      fid: string
    } & RequestDataBase,
  ): Promise<void> => {
    const { fid, ctx } = params
    const url = `/api/simul-label/delete/${fid}`

    await this.helper.post(url, undefined, { ctx })
  }

  /**
   * 레이블 목록 조회
   */
  list = (
    params: {
      simulId: number
    } & RequestDataBase,
  ): Promise<{ labelList: SimulLabel[] }> => {
    const { simulId, ctx } = params
    const url = '/api/simul-label/list'

    return this.withData.post(url, { simulId }, { ctx })
  }
}
