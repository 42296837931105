export type AiTrainKindKey = 'data_science' | 'supervised' | 'unsupervised' | 'gan' | 'tchm'
export const AiTrainKind: Record<AiTrainKindKey, string> = {
  data_science: '데이터 과학',
  supervised: '지도학습',
  unsupervised: '비지도학습',
  tchm: '티처블 머신',
  gan: 'GAN',
}

export type AiModelTypeKey =
  | 'imageClassification'
  | 'textClassification'
  | 'regression'
  | 'unsupervised'
  | 'ganAnimation'
  | 'ganStyleTranfer'
  | 'tchmVideo'
  | 'tchmAudio'
  | 'tchmPose'

export const AiModelType: Record<AiModelTypeKey, string> = {
  imageClassification: '이미지 분류',
  textClassification: '텍스트 분류',
  regression: '회귀 분석',
  unsupervised: '비지도학습',
  ganAnimation: '애니메이션',
  ganStyleTranfer: 'Style Transfer',
  tchmVideo: '티처블 머신(비디오)',
  tchmAudio: '티처블 머신(음성)',
  tchmPose: '티처블 머신(자세)',
}

export type GanStyleTransferTypeKey =
  | 'wave'
  | 'the_scream'
  | 'shipwreck'
  | 'napoleon'
  | 'parasol'
  | 'fifre'

// wave: '가츠시카 호쿠사이 - The Great Wave off Kanagawa',
// the_scream: '에드바르 뭉크 - The Scream',
// shipwreck: '조지프 말로드 윌리엄 터너 - The Shipwreck of the Minotaur',
// napoleon: '자크 루이 다비드 - napoleon Crossing the Alps',
// parasol: '클로드 모네 - Woman with a parasol',
// fifre: '에두아르 마네 - Le fifre',
export const GanStyleTransferType: Record<GanStyleTransferTypeKey, string> = {
  wave: '가츠시카 호쿠사이',
  the_scream: '에드바르 뭉크',
  shipwreck: '조지프 말로드 윌리엄 터너',
  napoleon: '자크 루이 다비드',
  parasol: '클로드 모네',
  fifre: '에두아르 마네',
}

export type PromptConfigKey = 'directive' | 'perspective' | 'tone' | 'style' | 'reader' | 'format'

export const PromptConfig: Record<PromptConfigKey, string> = {
  directive: '지시어',
  perspective: '관점/역할',
  tone: '말투',
  style: '스타일',
  reader: '독자',
  format: '형식',
}

export const BARD_PROMPT_CONFIG_COLORS: Record<PromptConfigKey, string> = {
  directive: '#e9724d',
  perspective: '#12944d',
  tone: '#ff5b5b',
  style: '#af70ff',
  reader: '#ff39bc',
  format: '#12aeb7',
}

export interface BardQuestion {
  /**
   * 질문 주제
   */
  subject: string

  /**
   * 프롬프트 설정값들
   */
  promptConfigValues: Partial<Record<PromptConfigKey, string>>
}

export interface TimedBardQuestion extends BardQuestion {
  /**
   * 질문을 한 시간
   */
  timestamp: number
}

/**
 * 바드의 질문과 답변
 */
export interface BardQnA {
  /**
   * 질문 주제
   */
  subject: string

  /**
   * 바드 답변
   */
  answer: string
}

export interface BardDetailQnA extends BardQnA {
  /**
   * 프롬프트 설정값들
   */
  promptConfigValues: Partial<Record<PromptConfigKey, string>>
}

export const BARD_SUBJECT_SAMPLE = [
  '코딩을 배우면 좋은 점',
  'AI가 감정을 느낄 수 있나요',
  '유튜브의 장단점',
  '간단한 요리 레시피',
  '친구 사귀는 법',
  '친구와 화해하는 법',
  '사람들 앞에서 떨지 않는 방법',
  '처음 만나는 사람을 대하는 방법',
  '조리 있게 말하는 방법',
  '이성 친구를 만드는 법',
  '방학을 알차게 보내려면',
  '수학을 잘하는 방법',
  '영어을 잘하는 방법',
  '코딩을 잘하는 방법',
  '노래를 잘하는 방법',
  '학교에 가는 이유',
  '학교를 즐겁게 다니는 방법',
  '수업 시간이 재미있으려면',
  '수업 시간에 질문을 잘 하려면',
  '공부의 중요성',
  '시험 공부 계획',
  '나의 미래 가능성',
  '진로 선택',
  '직업 체험',
  '힘들고 우울 할 땐',
  '화를 푸는 방법',
  '두려움을 느낄 땐',
  '잠이 안 올때는 어떻게 하나요',
  '스마트폰 사용하는 시간을 줄이려면',
  '부모님과 대화하는 방법',
]

// - 지시어 : 설명해줘, 요약해줘, 교정해줘, 예를 들어줘
// - 관점/역할 : 개발자, 선생님, 디자이너, 전문가
// - 말투 : 정중한, 상냥한, 단호한, 친근한
// - 스타일 : 자세하게, 간략하게, 정확하게
// - 독자 : 초등학생, 대학생, 전문가, 부모님
// - 형식 : 리스트, 표, 다이어그램, 그래프
export const BARD_PROMPT_SAMPLES: Record<PromptConfigKey, string[]> = {
  directive: ['설명해줘', '요약해줘', '교정해줘', '예를 들어줘', '보여줘', '만들어줘', '작성해줘'],
  perspective: [
    '선생님',
    '초등학생',
    '중학생',
    '고등학생',
    '대학생',
    '전문가',
    '디자이너',
    '개발자',
    '회사원',
    '부모님',
    '상담원',
    '의사',
  ],
  tone: ['정중하게', '상냥하게', '단호하게', '친근하게', '귀엽게', '무례하게'],
  style: ['자세하게', '간략하게', '정확하게'],
  reader: ['선생님', '초등학생', '중학생', '고등학생', '대학생', '전문가', '부모님', '환자'],
  format: ['설명문', '표', '리스트', '다이어그램', '안내문', '공지문', '발표자료', '논문'],
}
